import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@mui/styles";
import { Grid, Button, Tooltip } from "@mui/material";
import SingleSelect from "../common/SingleSelect";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@mui/icons-material/Info';
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

class settleAmount extends Component {
  constructor() {
    super();
    this.state = {
      resultMessage: "",
      value: []
    };
  }

  render() {
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableSettleBtn}
          content={
            <>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                    <SingleSelect
                      array={this.props && this.props.accounts || []}
                      async
                      selectName="paymentAccount"
                      isClearable
                      label={this.props.translate("contractNumber")}
                      value={
                        this.props?.paymentAccount &&
                        this.props.paymentAccount.id
                          ? {
                              id: this.props.paymentAccount,
                              value:
                                this.props.paymentAccount.contractNumber,
                              label: this.props?.paymentAccount.label,
                            }
                          : ""
                      }
                      handleChange={this.props.handleChange1}
                      canAssign={true}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Grid>
                <Grid item md={3} sm={6} xs={12} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                  <Tooltip title="Payment Adjustment from Advance Payments Only.">
                  <InfoIcon/> 
                  </Tooltip> 
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <h3>
                      {this.props.translate("totalInvoiceBalance")} = {this.props && this.props?.overallInvoiceBalance ? this.props?.overallInvoiceBalance?.toFixed(2) : 0}
                    </h3>
                    <h3>
                      {this.props.translate("creditAmount")} = {this.props && this.props.creditAmountForSettle && this.props?.creditAmountForSettle?.toFixed(2) || 0}
                    </h3>
                  </Grid>
              </Grid>
            </>
          }
        />
      </Fragment>
    );
  }
}

const SettleAmount = withStyles(styles);
export default SettleAmount((settleAmount));