import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from './../../wrapper'
import { Serversettings } from '../../Components/ServerSettings/serverSettings'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logInUserInfo } from '../../Actions/Users'

class ServerSettings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
      userFetch: false
    }
  }

  // componentWillMount () {
  //   this.props.dispatch(toast.removeAll())
  // }

  render () {
    if (this.props.logInUser && this.props.logInUser.userType === -1) {
      return (
        <Layout
          {...this.props}
          noSidebar
        >
          <Serversettings {...this.props} />
        </Layout>
      )
    } else {
      fetch('/api/session', {
        headers: { Accept: 'application/json; odata=verbose' }
      }).then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){ 
              let userInfo = res.data
              this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
              this.props.dispatch(logInUserInfo(userInfo))
              if (userInfo.userType !== -1) {
                this.props.navigate('/logout')
              }
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              
                toast.error(
                 this.props.translate(err[1])
              )
              this.props.navigate('/logout')
            }
          })
        } else {
          this.props.navigate('/logout')
            throw response
          }
        })
        .catch(e => {
         toast.error(
           'somethingWentWrong'
          )
        })
      return null
    }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)((ServerSettings))
