import React, { Component } from "react";
import {MapContainer as  Map, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import { connect } from 'react-redux'

import "./DrawMap.scss";

//let MarkerDOM;

class MarkerMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0,
      zoom: 3,
      pointer: null
    }
    this.mapEvent = this.mapEvent.bind(this)
    this.myRef = this.myRef.bind(this)
  }
  mapEvent(e) {
    if (this.props.getPostion) {
      this.props.getPostion(e.latlng, this.map.getZoom())
      this.setState({
        pointer: <Marker position={[e.latlng.lat, e.latlng.lng]} icon={L.icon({ iconUrl: "/assets/images/location-pin.svg", iconSize: [79, 64], iconAnchor: [20, 64] })} />
      })
    }
  }
  myRef(el) {
    if (el) {
      this.map = el.leafletElement;
      L.Control.geocoder({ position: 'topleft', placeholder: 'Search location...', defaultMarkGeocode: false })
        .on('markgeocode', (e) => {

          this.setState({ lat: e.geocode.center.lat, zoom: 15, lng: e.geocode.center.lng, pointer: <Marker position={[e.geocode.center.lat, e.geocode.center.lng]} icon={L.icon({ iconUrl: "/assets/images/location-pin.svg", iconSize: [79, 64], iconAnchor: [20, 64] })} /> });

          if (this.props.getPostion) {
            this.props.getPostion(e.geocode.center, this.map.getZoom());
          }

        })
        .addTo(this.map);
    }
  }

  componentWillMount() {
    const {lat, lng, zoom } = this.props;
    this.setState({
      lat: lat || 0,
      lng: lng || 0,
      zoom: zoom || 3,
    })
    if(lat && lng ){
      this.setState({
        pointer: <Marker position={[lat,lng ]} icon={L.icon({iconUrl: "/assets/images/location-pin.svg", iconSize: [79, 64], iconAnchor: [20, 64]})} />
      })
      
    }
  }



  render() {
    const position = this.state.lat && this.state.lng ? [this.state.lat, this.state.lng] : [0, 0];
    return (
      <div style={{ position: 'relative' }}>
        <Map ref={this.myRef} style={{ height: this.props.height || '100%', width: this.props.width || '100%', minHeight: this.props.minHeight || '100vh' }} key={1}
          center={position}
          onClick={this.mapEvent}
          zoom={this.state.zoom}
        >
          {this.state.pointer}
          <TileLayer
            attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}
            url={"https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"} maxZoom={19} minZoom={this.state.minZoom}
          />
        </Map>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  themeColors: state.themeColors
});

export default connect(mapStateToProps)(MarkerMap);