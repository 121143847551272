import React, { Component } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import styled from 'styled-components'
import { connect } from 'react-redux'
import TextField from '../common/TextField'
import MenuItem from '../common/MenuItem'
import { Grid } from '@mui/material'


// const mapStateToProps = (state, ownProps) => {
//   return {
//     themecolors: state.themecolors
//   }
// }

const style = theme => ({
  root: {
    top: 40
  },
  paper: {
    margin: 0
  }
})
const draggableId = new Date().getTime()

class PaperComponent extends Component {
  render () {
    return (
      <Draggable bounds='parent' handle={'.dragHandle' + draggableId}>
        <Paper {...this.props} />
      </Draggable>
    )
  }
}

function PaperDefault (props) {
  return <Paper {...props} />
}

class VehicleFilerModal extends Component {
  state = {
    tags:[],
    open: true,
    tag_1: '',
    tag_2: '',
    tag_3: '',
    tag_4: '',
    tag_5: ''
  }

  componentDidMount() {
    
    // Calculate tags from this.props.vehiclesData
    const tags = {
      tag_1: [],
      tag_2: [],
      tag_3: [],
      tag_4: [],
      tag_5: [],
    };

    if (this.props.vehiclesData && this.props.vehiclesData.length) {
      this.props.vehiclesData.forEach((t) => {
        if (t.attributes.tag_1) tags.tag_1.push(t.attributes.tag_1);
        if (t.attributes.tag_2) tags.tag_2.push(t.attributes.tag_2);
        if (t.attributes.tag_3) tags.tag_3.push(t.attributes.tag_3);
        if (t.attributes.tag_4) tags.tag_4.push(t.attributes.tag_4);
        if (t.attributes.tag_5) tags.tag_5.push(t.attributes.tag_5);
      });
    }

    // Store the calculated tags in the component's state
    this.setState({ tags });
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.onClose()
  }


  // handleSubmit = () => {
  //   const selectedTags = [
  //     this.state.tag_1,
  //     this.state.tag_2,
  //     this.state.tag_3,
  //     this.state.tag_4,
  //     this.state.tag_5,
  //   ].filter((tag) => tag); // Remove empty tags (tags with falsy values)
  
  //   // Filter vehicles based on selected tags
  //   const filteredVehicles = this.props.vehiclesData.filter((vehicle) => {
  //     const vehicleTags = [
  //       vehicle.attributes.tag_1,
  //       vehicle.attributes.tag_2,
  //       vehicle.attributes.tag_3,
  //       vehicle.attributes.tag_4,
  //       vehicle.attributes.tag_5,
  //     ].filter((tag) => tag); // Remove empty tags in vehicle attributes
  
  //     // Check if there is an intersection between selected tags and vehicle tags
  //     const intersection = vehicleTags.filter((tag) => selectedTags.includes(tag));
  
  //     // Include the vehicle in the filtered result if there is an intersection
  //     return intersection.length > 0;
  //   });
  
  //   // Use filteredVehicles for further processing or updating the state
  //   // console.log('Filtered Vehicles========', filteredVehicles);
    
  //   // Close the modal or perform any other necessary actions
  //   this.setState({ open: false },()=>this.props.getVehiclesFromModel(filteredVehicles));
  // };
  


  handleSubmit = () => {
    this.setState({ open: false },()=>{
      const selectedTags = {
        tag_1: this.state.tag_1,
        tag_2: this.state.tag_2,
        tag_3: this.state.tag_3,
        tag_4: this.state.tag_4,
        tag_5: this.state.tag_5,
      };
      
      this.props.getVehiclesList(selectedTags, false)})
    
  }
  onStart = e => {
    //console.log(e)
  }

  onStop = e => {
    //console.log(e)
  }

  onChange = (name, value) => {
    // console.log("nam====", name, value);
    this.setState({
      [name]: value
    })
  }
  render () {
    const { classes, translate , alltags} = this.props
    // console.log("this.props.alltags====", alltags);
    const { tag_1, tag_2, tag_3, tag_4, tag_5 } = this.props.alltags
    const { vt1, vt2, vt3, vt4, vt5 } = this.props.logInUser.attributes

    const ThemeIconButton = styled(IconButton)`
      position: absolute !important;
      top: 0;
      right: 0;
      z-index: 9;
      border-radius: 0 !important;
      background-color: ${props => this.props.themecolors['500']}!important;
      color: ${props => this.props.themecolors['themeInverse']}!important;
      &:hover {
        background-color: ${props => this.props.themecolors['600']};
      }
    `

    const DialogActionsStyled = styled(DialogActions)`
      background-color: ${props => this.props.themecolors['lightGrey']};
    `

    const DragIcon = styled.span`
      position: absolute;
      width: 44px;
      height: 44px;
      text-align: center;
      font-size: 32px;
      cursor: move;
      z-index: 9;
      left: 0;
      top: 0;
      color: ${props => this.props.themecolors['themeInverse']};
    `

    let BackdropProps = {}
    if (this.props.invisible === true) {
      BackdropProps = { invisible: true }
    } else {
      BackdropProps = {
        style: { backgroundColor: 'rgba(255,255,255,0.7)' },
        // classes: { root: classes.root }
      }
    }
    return (
      <Dialog
        style={{ overflow: 'auto' }}
        classes={classes}
        open={this.state.open}
        onClose={this.handleClose}
        // maxWidth={this.props.maxWidth || 'md'}
        disableBackdropClick={true}
        PaperComponent={this.props.draggable ? PaperComponent : PaperDefault}
        BackdropProps={BackdropProps}
        PaperProps={{ style: { overflow: 'visible' , width:this.props.viewUserDetailWidth} }}
        // fullWidth= {this.props.fullWidth}
        // fullWidth= {true}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <ThemeIconButton
          aria-label='onClose'
          onClick={this.handleClose}
          color='inherit'
        >
          <CloseIcon fontSize='small' />
        </ThemeIconButton>
        {this.props.title && (
          <DialogTitle
            disableTypography={true}
            style={{
              backgroundColor: this.props.themecolors['500'],
              fontSize: 18,
              fontWeight: 500,
              minHeight: 44,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 0,
              paddingBottom: 0,
              // paddingLeft: this.props.draggable ? '44px' : 'def',
              paddingLeft: '44px',
              // marginLeft:10px
              color: this.props.themecolors['white']
            }}
          >
            {this.props.title}{' '}
            {this.props.selectedUser ? this.props.selectedUser : null}
          </DialogTitle>
        )}
        <DialogContent
          style={{
            padding: "5px 10px",
            overflow: 'visible',
            background:this.props.background ? this.props.background : null,
            height:this.props.viewUserDetailWidth? this.props.viewUserDetailWidth: ''
          }}
        >
          {!this.props.hideDragIcon && (
            <DragIcon className={'dragHandle' + draggableId}>
              <DragIndicatorIcon color='inherit' />
            </DragIcon>
          )}
          <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}   >    
                    <TextField
                fullWidth
                label={vt1? vt1 : this.props.translate('tag_1')}
                select
                onChange={e => this.onChange('tag_1', e.target.value)}
                value={this.state.tag_1}
              >
                <MenuItem value={''}></MenuItem>
                {tag_1 &&
                  tag_1.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField></Grid>
              <Grid item md={12} sm={12} xs={12}   >  
              <TextField
                fullWidth
                label={vt2? vt2 : this.props.translate('tag_2')}
                // label={vt2 || translate('tag_2')}
                select
                onChange={e => this.onChange('tag_2', e.target.value)}
                value={this.state.tag_2}
              >
                <MenuItem value={''}></MenuItem>
                {tag_2 &&
                  tag_2.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField></Grid>
              <Grid item md={12} sm={12} xs={12}   >  
              <TextField
                fullWidth
                label={vt3? vt3 : this.props.translate('tag_3')}
                select
                onChange={e => this.onChange('tag_3', e.target.value)}
                value={this.state.tag_3}
              >
                <MenuItem value={''}></MenuItem>
                {tag_3 &&
                  tag_3.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
              </Grid>
              <Grid item md={12} sm={12} xs={12}   >  
              <TextField
                fullWidth
                label={vt4? vt4 : this.props.translate('tag_4')}
                select
                onChange={e => this.onChange('tag_4', e.target.value)}
                value={this.state.tag_4}
              >
                <MenuItem value={''}></MenuItem>
                {tag_4 &&
                  tag_4.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
              </Grid>
              <Grid item md={12} sm={12} xs={12}   >  
              <TextField
                fullWidth
                label={vt5? vt5 : this.props.translate('tag_5')}
                select
                onChange={e => this.onChange('tag_5', e.target.value)}
                value={this.state.tag_5}
              >
                <MenuItem value={''}></MenuItem>
                {tag_5 &&
                  tag_5.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActionsStyled style={{ margin: 0, padding: 10 }}>
         
          <Button onClick={this.handleClose} variant='outlined' size='small'>
            {this.props.translate('sharedCancel')}
          </Button>
          {this.props.showSubmitbtn && (
            <Button  onClick={this.handleSubmit} style={{background:"#00c179", color:"#ffff"}} variant="contained" color="success" size='small'>
                {this.props.translate('submit')}
              </Button>
          )}
          {this.props.headerActions}
        </DialogActionsStyled>
      </Dialog>
    )
  }
}

// VehicleFilerModal.propTypes = {
//   classes: PropTypes.object.isRequired
// }

// export default connect(mapStateToProps)(
//   (withStyles(style)(VehicleFilerModal))
// )

const mapStateToProps = state => ({
  vehicles: state.vehicles,
    themecolors: state.themecolors

})
export default connect(mapStateToProps)(VehicleFilerModal)