import React, { Component } from "react";
import { connect } from "react-redux";
import { checkPrivileges } from "../../Helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

let source;

class ContractSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callPending: true,
      page: 1,
      pageSize: 20,
      itemSearch: "",
      accounts: {},
    };
  }
  componentWillMount() {
    // this.fetchAccount();
  }

  fetchAccount = () => {
    let { page, pageSize, itemSearch } = this.state;
    let apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`;

    source = axios.CancelToken.source();
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        this.setState({
          accounts: response.data.data,
        });
      })
      .catch((e) => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      });
  };

  render() {
    let data = "";
    if (
      this.props.selectedAccServices &&
      this.props.selectedAccServices.length
    ) {
      data = this.props.selectedAccServices.map((service) => (
        <ListRow
          key={service.id}
          data={service}
          {...this.props}
          selecteItem={this.props.selecteItem}
          selectedItemId={this.props.selectedItemId}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          editExpenseForm={this.props.editExpenseForm}
          deleteExpense={this.props.deleteExpense}
          selectedServiceTypeId={this.props?.selectedServiceTypeId || ""}
        />
      ));
    }

    return (
      <div>
        {data && (
          <div className="individual-view">
            <ul className="list-view">{data}</ul>
          </div>
        )}
      </div>
    );
  }
}

export class ListRows extends Component {
  render() {
    if (this.props.children.length) {
      return (
        <ul className="list-view">
          {this.props.children.map((data) => (
            <ListRow key={data.id} data={data} {...this.props} />
          ))}
        </ul>
      );
    } else {
      return "";
    }
  }
}
export class ListRow extends Component {
  render() {
    let { props } = this.props;
    return (
      <li
        style={{
          display: "flex",
          backgroundColor:
            this.props.data.id === this.props?.selectedServiceTypeId
              ? "#eee"
              : "",
          borderRadius: "4px",
        }}
      >
        <label
          className="checkbox"
          onClick={() => this.props.selectService(this.props.data)}
        >
          {this.props.value === "accounts" ? (
            <span
              className="unit-name"
              style={{
                minWidth: 180,
                // maxWidth: {props.value="accounts"},
                marginLeft: 0,
                lineHeight: "24px",
                verticalAlign: "middle",
              }}
            >
              {this.props.data.name}
            </span>
          ) : (
            <span
              className="unit-name"
              style={{
                minWidth: 210,
                // maxWidth: {props.value="accounts"},
                marginLeft: 0,
                lineHeight: "24px",
                verticalAlign: "middle",
              }}
            >
              {this.props.data.name}
            </span>
          )}
        </label>
        {checkPrivileges("expensetypeUpdate") && (
        <span
          onClick={(e) => this.props.editExpenseForm(this.props.data, e)}
          style={{ paddingTop: 5, cursor: "pointer" }}
        >
          <EditIcon fontSize="small" color="action" />
        </span>
        )}
        {checkPrivileges("expensetypeDelete") && (
        <span
          onClick={(e) => this.props.deleteExpense(this.props.data, e)}
          style={{ paddingTop: 5, cursor: "pointer" }}
        >
          <DeleteIcon fontSize="small" color="action" />
        </span>)}
      </li>
    );
  }
}

const mapState = (state) => {
  if (state.roles) {
    var role = state.roles.filter(
      (item) => item.name.toLowerCase().indexOf(state.searchRole) !== -1
    );
  }
  return {
    logInUser: state.logInUsers,
  };
};

const mapStateToProps = connect(mapState);
export const ContractSidebar = mapStateToProps(ContractSide);
