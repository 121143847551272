import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { nFormatter } from "../../Helpers";
import Skeleton from '@mui/material/Skeleton';

import pieChartDefault from "../../Components/common/Charts/pieChartDefault";
// import Highcharts from "highcharts/highmaps";
// import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import HighchartsReact from "highcharts-react-official";
import Button from "../../Components/common/Button";
import { Scrollbar } from "react-scrollbars-custom";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import HighchartsMap from "highcharts/modules/map";
// import mapData from "@highcharts/map-collection/custom/sa/sa-all.geo.json";
// import proj4 from "proj4";
// import mapData from "@highcharts/map-collection/countries/sa/sa-all.geo.json";

import Highcharts, { color } from "highcharts/highmaps";
import drilldownModule from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import { async } from "q";
// import HighchartsReact from "highcharts-react-official";
// import mapData from "@highcharts/map-collection/countries/gb/gb-all.geo.json";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import statsup from "../../assets/statsup.png";
import statstraight from "../../assets/statstraight.png";
import statsdown from "../../assets/statsdown.png";
// import averageTicket from '../../assets/avg-ticket-price-01.svg'
import { ReactComponent as AvgTicket } from "../../assets/avg-ticket-price-01.svg";
import { ReactComponent as ArrowDropUp } from "../../assets/ex_dashboard/arrow-up-graph-01.svg";
import { ReactComponent as ArrowDropDown } from "../../assets/ex_dashboard/arrow-down-graph-01.svg";
import { ReactComponent as ArrowStraight } from "../../assets/ex_dashboard/arrow-straight-graph-01.svg";
import { ReactComponent as AchieveTarget } from "../../assets/ex_dashboard/objective_5738807 (1).svg";
import Select from "react-select";
// import Highcharts from "highcharts/highstock";
import { checkPrivileges } from "../../Helpers";
import moment from "moment";

HighchartsMap(Highcharts);

drilldownModule(Highcharts);
dataModule(Highcharts);

const textStyle = {
  // marginLeft: 5,
  color: "#ffffff",
  textAlign: "left",
  fontSize: "22px",
  fontWeight: "700",
  fontStyle: "italic",
  display: "inline-block",
  whiteSpace: "nowrap",
  overflow: "hidden !important",
  // overflow: "hidden",
  textOverflow: "ellipsis",
  paddding: "0 2px 0 2px",
};
const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    background: "#ff5722 !important",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#548e25",
  },
}))(LinearProgress);

const MaintenanceItemEmpty = React.memo(() => {
  return (
    <div className={"mainten-section mainten"} style={{ padding: "5px" }}>
      <div className="mainten-section-header">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton className="skeleton-pulse" width={150} height={25} />{" "}
          {/* </span>
        <span> */}
          <Skeleton className="skeleton-pulse" width={150} height={25} />{" "}
        </span>
      </div>

      <div className="mainten-section-body">
        <div className="mainten-split-section">
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />{" "}
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />{" "}
          </div>{" "}
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />{" "}
          </div>
        </div>
      </div>
    </div>
  );
});

const FiltersOption = [
  { label: "MTD", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "Last 3 months", value: 3 },
  { label: "YTD", value: 4 },
  { label: "Last Year", value: 5 },
  { label: "ALL", value: 6 },
];

let fleetCountChart = {};
let fleetCountChart2 = {};
let overDueChart = {};
let heatMap = {};
let totalVehicles = 0;
let cogsChart = {};
// let cogsChartDaily ={}
// let sparePartsChart1 ={}
// let sparePartsChart ={}
// let servicesPartsChart ={}
// let verticalWiseChart = {}
// let areaWiseChart = {}
// let stationWiseChart = {}
let salesChartMonthly = {};
let salesChartYearly = {};

const Dashboard = (props) => {
  const [allSaleData, setAllSaleData] = useState({}); //api1
  const [currentYearSale, setcurrentYearSale] = useState({}); // api2
  const [grossProfit, setGrossProfit] = useState({}); // api3
  const [accountsData, setAccountsData] = useState({}); // api5
  const [outstandingSummary, setOutstandingSummary] = useState(); // api5
  const [vehicleCount, setVehicleCount] = useState(); // api5
  const [vehiclesData, setVehiclesData] = useState(); // api9
  const [avgTicketPrice, setAvgTicketPrice] = useState(); // api10
  const [fleetCount, setFleetCount] = useState(); // api8
  const [overDue, setOverDue] = useState(); // api6
  const [cogsMonthly, setCogsMonthly] = useState(true); // api6
  const [businessPotential, setBusinessPotential] = useState(); // api5
  const [garageDataType, setGarageDataType] = useState("name");
  const [topologyData, setTopologyData] = useState();

  const [dataCategory, setDataCategory] = useState("all");
  const [cogsChartData, setCogsChartData] = useState({});
  const [cogsItems, setCogsItems] = useState();
  const [cogsServices, setCogsServices] = useState();
  const [cogsPackages, setCogsPackages] = useState();
  const [allCogsChartData, setAllCogsChartData] = useState();

  const [cogsSubItems, setCogsSubItems] = useState();
  const [cogsSubServices, setCogsSubServices] = useState();
  const [cogsSubPackages, setCogsSubPackages] = useState();
  const [cogsChartDaily, setCogsChartDaily] = useState({});
  const [date, setDate] = useState();

  const [servicesPartsChartData, setservicesPartsChartData] = useState();
  const [servicesPartsChart, setservicesPartsChart] = useState();
  const [servicesPartsChart1, setservicesPartsChart1] = useState();

  const [ownChart, setOwnChart] = useState({});
  const [ownChart1, setOwnChart1] = useState({});
  const [vehiclesChart, setVehiclesChart] = useState({});

  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader4a, setLoader4a] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const [loader7, setLoader7] = useState(false);
  const [loader8, setLoader8] = useState(false);
  const [loader9, setLoader9] = useState(false);
  const [loader10, setLoader10] = useState(false);
  const [loader11, setLoader11] = useState(false);
  const [loader12, setLoader12] = useState(false);
  const [loader13, setLoader13] = useState(false);
  const [loader14, setLoader14] = useState(false);
  const [loader15, setLoader15] = useState(false);
  const [loader16, setLoader16] = useState(false);
  const [loader17, setLoader17] = useState(false);
  const [loader6b, setLoader6b] = useState(false);

  // const [loader16, setLoader16] = useState(false);
  const [showAllInventory, setShowAllInventory] = useState(false);
  const [sparePartsChart, setSparePartsChart] = useState();
  const [sparePartsChartNew, setSparePartsChartNew] = useState();
  const [sparePartsChart1, setSparePartsChart1] = useState();

  const [showAllVehicles, setShowAllVehicles] = useState(false);
  const [showAllServices, setShowAllServices] = useState(false);

  const [tab1, setTab1] = useState(FiltersOption[5]); // API bi/8
  const [tab2, setTab2] = useState(FiltersOption[5]); // API bi/4a
  const [tab3, setTab3] = useState(FiltersOption[5]); // API bi/4b
  const [tab4, setTab4] = useState(FiltersOption[5]); // API bi/10
  const [tab5, setTab5] = useState(FiltersOption[5]); // API bi/14a
  const [tab6, setTab6] = useState(FiltersOption[5]); // API bi/14b (garageType)
  const [tab7, setTab7] = useState(FiltersOption[5]); // API bi/14c (garageArea)
  const [tab8, setTab8] = useState(FiltersOption[5]); // API bi/16
  const [tab9, setTab9] = useState(FiltersOption[5]); // API bi/17

  const [verticalWiseChart, setVerticalWiseChart] = useState({});
  const [areaWiseChart, setAreaWiseChart] = useState({});
  const [stationWiseChart, setStationWiseChart] = useState({});
  const [areaWiseData, setAreaWiseData] = useState();

  const [garageTypeC, setGarageType] = useState("");
  const [garageAreaC, setGarageArea] = useState("");
  const [collectionsAndOutStanding, setCollectionsAndOutStanding] =
    useState("");
  const [collectionsAndOutStandingData, setCollectionsAndOutStandingData] =
    useState("");

  const [accountsDataChart, setAccountsDataChart] = useState({});
  const [showAccountsData, setShowAccountsData] = useState(false);
  const [showAccountsLoader, setShowAccountsLoader] = useState(false);
  const [showAccountsTitle, setShowAccountsTitle] = useState("");
  const [selectedDate14b, setSelectedDate14b] = useState({});

  const [screenSize, setScreenSize] = useState({
    xs: window.innerWidth < 600,
    sm: window.innerWidth > 600 && window.innerWidth < 960,
  });

  // const handleVerticalClick =(pointData,res)=>{
  //   let apiUrl;
  //   // res.to && res.from ? apiUrl= `/api/dashboards/bi/14b?garageType=${pointData}&to=${res.to}&from=${res.from}` : apiUrl =`/api/dashboards/bi/14b?garageType=${pointData}`
  //   // const response = await fetch(api);
  //   if (selectedDate14b && selectedDate14b.to && selectedDate14b.from) {
  //     apiUrl = `/api/dashboards/bi/14b?to=${selectedDate14b.to}&from=${selectedDate14b.from}`;
  //   } else {
  //     apiUrl = `/api/dashboards/bi/14b`;
  //   }
  //   // const apiUrl = `/api/dashboards/bi/14b?garageType=${pointData}`;
  //   // // Use fetch or any other method to make the API request
  //   setLoader14(true);
  //   fetch(apiUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Handle the API response data as needed
  //   setGarageType(pointData)

  //       setAreaWiseChart(processResponse14b(data.data));
  //       setGarageDataType("area")
  //     })
  //     .catch((error) => {
  //       // Handle any errors that occur during the API request
  //       console.error("API Error:", error);
  //     }).finally(() => {
  //       setLoader14(false);
  //     });

  // }

  // useEffect(() => {
  //   if (garageTypeC) {
  //     let apiUrl;
  //     let loaderType;
  //     // Check if garageAreaC is present to determine the API endpoint
  //     if (garageAreaC) {
  //       loaderType = "station";
  //       if (selectedDate14b && selectedDate14b.to && selectedDate14b.from) {
  //         apiUrl = `/api/dashboards/bi/14c?garageArea=${garageAreaC}&garageType=${garageTypeC}&to=${selectedDate14b.to}&from=${selectedDate14b.from}`;
  //       } else {
  //         apiUrl = `/api/dashboards/bi/14c?garageArea=${garageAreaC}&garageType=${garageTypeC}`;
  //       }
  //     } else {
  //       loaderType = "area";
  //       if (selectedDate14b && selectedDate14b.to && selectedDate14b.from) {
  //         apiUrl = `/api/dashboards/bi/14b?garageType=${garageTypeC}&to=${selectedDate14b.to}&from=${selectedDate14b.from}`;
  //       } else {
  //         apiUrl = `/api/dashboards/bi/14b?garageType=${garageTypeC}`;
  //       }
  //     }

  //     setLoader14(true);
  //     fetch(apiUrl)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         // Handle the API response data as needed
  //         if (loaderType === "station") {
  //           setAreaWiseData(data.data);
  //           setStationWiseChart(processResponse14b(data.data || {}, true));
  //           setGarageDataType("station");
  //         } else {
  //           setAreaWiseChart(processResponse14b(data.data));
  //           setGarageDataType("area");
  //         }
  //       })
  //       .catch((error) => {
  //         // Handle any errors that occur during the API request
  //         console.error("API Error:", error);
  //       })
  //       .finally(() => {
  //         setLoader14(false);
  //       });
  //   }
  // }, [garageTypeC, garageAreaC, selectedDate14b]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        xs: window.innerWidth < 600,
        sm: window.innerWidth > 600 && window.innerWidth < 960,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run the effect only once (on mount)

  const { xs, sm } = screenSize;

  useEffect(() => {
    if (garageTypeC) {
      let apiUrl;
      // API request with the updated garageTypeC
      if (selectedDate14b && selectedDate14b.to && selectedDate14b.from) {
        apiUrl = `/api/dashboards/bi/14b?garageType=${garageTypeC}&to=${selectedDate14b.to}&from=${selectedDate14b.from}`;
      } else {
        apiUrl = `/api/dashboards/bi/14b?garageType=${garageTypeC}`;
      }
      setLoader14(true);
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          // Handle the API response data as needed
          // setAreaWiseData(data.data)
          // setStationWiseChart(processResponse14b(data.data || {}, true));
          // setGarageDataType("station");
          setAreaWiseChart(processResponse14b(data.data));

          setGarageDataType("area");
        })
        .catch((error) => {
          // Handle any errors that occur during the API request
          console.error("API Error:", error);
        })
        .finally(() => {
          setLoader14(false);
        });
    }
  }, [garageTypeC]);

  useEffect(() => {
    if (garageAreaC) {
      // API request with the updated garageTypeC
      let apiUrl;

      if (selectedDate14b && selectedDate14b.to && selectedDate14b.from) {
        apiUrl = `/api/dashboards/bi/14c?garageArea=${garageAreaC}&garageType=${garageTypeC}&to=${selectedDate14b.to}&from=${selectedDate14b.from}`;
      } else {
        apiUrl = `/api/dashboards/bi/14c?garageArea=${garageAreaC}&garageType=${garageTypeC}`;
      }
      setLoader14(true);
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          // Handle the API response data as needed
          setAreaWiseData(data.data);
          setStationWiseChart(processResponse14b(data.data || {}, true));
          setGarageDataType("station");
        })
        .catch((error) => {
          // Handle any errors that occur during the API request
          console.error("API Error:", error);
        })
        .finally(() => {
          setLoader14(false);
        });
    }
  }, [garageAreaC]);

  // Rest of your component code

  const handleChange = (name, e) => {
    const res = getToFrom(e.value);
    if (res) {
      if (name === "tab1") {
        setTab1(e);
        getEXD8(res);
      } else if (name === "tab2") {
        setTab2(e);
        getEXD4(res);
      } else if (name === "tab3") {
        // not in use
        setTab3(e);
        getEXD8(res);
      } else if (name === "tab4") {
        setTab4(e);
        getEXD10(res);
      } else if (name === "tab5") {
        setTab5(e);
        getEXD14(res);
        setSelectedDate14b(res);
      } else if (name === "tab6") {
        setTab6(e);
        handleVerticalClick("pointData", res);
      } else if (name === "tab7") {
        setTab7(e);
        handleVerticalClick2("pointData", res);
      } else if (name === "tab8") {
        setTab8(e);
        getEXD16(res);
        setShowAllInventory(false);
      } else if (name === "tab9") {
        setTab9(e);
        getEXD17(res);
        setShowAllServices(false);
      }
    }
  };

  useEffect(() => {
    let timezone = "";
    let date = {};
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone;
    } else {
      timezone = "Asia/Riyadh";
    }
    const now = moment().tz(timezone);

    // ======== this part is for dashboards/cms/16, stations part, start of year YTD=========

    const startOfYear = now.clone().startOf("year");
    const endOfDay = now.clone().endOf("day");

    // ======== this part is for dashboards/cms/16, stations part, start of month MTD=========

    const startOfMonth = now.clone().startOf("month");
    const endOfMonth = now.clone().endOf("month");

    // date = {
    //   //YTD obj
    //   to: endOfDay.toISOString(),
    //   from: startOfYear.toISOString(),
    // };


          // Adjust the date object for the last year
      const lastYearStart = startOfYear.clone().subtract(1, "year");
      const lastYearEnd = endOfDay.clone().subtract(1, "year");

      date = {
        // YTD obj for last year
        to: lastYearEnd.toISOString(),
        from: lastYearStart.toISOString(),
      };

    getEXD1();
    getEXD2();
    getEXD3();
    // getEXD4(date);
    getEXD4();
    getEXD6b();

    getEXD5();
    getEXD6();
    getEXD7();
    getEXD8();
    getEXD9();
    getEXD10();
    getEXD11();
    getEXD12();
    getEXD14();
    getEXD15();
    getEXD16();
    getEXD17();

    // Function to reload the page
    const reloadPage = () => {
      window.location.reload();
    };

    // Set up an interval to reload the page every 10 minutes (600,000 milliseconds)
    const reloadInterval = 300000; // 10 minutes in milliseconds
    const intervalId = setInterval(reloadPage, reloadInterval);

    // Clear the interval when the component is unmounted to prevent memory leaks
    return () => {
      clearInterval(intervalId);
    };

    // getEXDTopology();
    // }
  }, []);

  const getEXD6b = async (res) => {
    try {
      setLoader6b(true);
      let api;
      if (res && res.to && res.from) {
        api = `/api/dashboards/cms/12?to=${res.to}&from=${res.from}`;
      } else {
        api = `/api/dashboards/cms/12`;
      }
      const response = await fetch(api);
      // const response = await fetch('/api/dashboards/bi/8');
      const data = await response.json();
      let res1 = data?.data || {};
      // let balancePercentage =
      // res.totalOutstandingBalance > res.totalPaid
      //   ? (res.totalPaid / res.totalSaleNetAmount) * 100
      //   : (res.totalPaid / res.totalSaleNetAmount) * 100;

      //   const obj = {
      //   balancePercentage: balancePercentage,
      //   ...res1,
      //   }
      // const sum = Object.values(res1).reduce((accumulator, value) => accumulator + value, 0);
      const sum =
        (res1["amountCredited"] || 0) +
        (res1["totalOutstanding"] || 0) +
        (res1["totalPaid"] || 0);
      setCollectionsAndOutStandingData(sum);
      let chart = processResponse16(res1, true);
      setCollectionsAndOutStanding(chart);
      //
      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD8 data:", error);
    } finally {
      setLoader6b(false);
    }
  };

  const getEXD1 = async () => {
    try {
      setLoader1(true);
      const response = await fetch("/api/dashboards/bi/1");
      const data = await response.json();
      if (data?.statusCode === "200") {
        setAllSaleData(data?.data || {});
      } else {
        setLoader1(true);
      }
    } catch (error) {
      console.error("Error fetching EXD1 data:", error);
    } finally {
      setLoader1(false);
    }
  };
  const getEXD2 = async () => {
    try {
      setLoader2(true);
      const response = await fetch("/api/dashboards/bi/2");
      const data = await response.json();
      if (data?.statusCode === "200") {
        setcurrentYearSale(
          processResponse2(data?.data !== "N/A" ? data?.data : {})
        ); //api2
        // setcurrentYearSale(currentYearSale)
      } else {
        setLoader2(true);
      }
      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD2 data:", error);
    } finally {
      setLoader2(false);
    }
  };
  const getEXD3 = async () => {
    try {
      setLoader3(true);
      const response = await fetch("/api/dashboards/bi/3");
      const data = await response.json();
      if (data?.statusCode === "200") {
        // Process and update state as neede  d
        let colors = ["#0288d1", "#bc803a"];
        let heading = "Monthly Sales Comparison";
        let salesPercentage = (
          ((data?.data.currentMonthProfit - data?.data.lastMonthProfit) /
            data?.data.lastMonthProfit) *
          100
        ).toFixed(2);
        // const  grossProfit = processResponse3(data?.data !=="N/A" ? data?.data : {}, colors,"",true);


        if (isNaN(salesPercentage) || salesPercentage === "Infinity") {
          salesPercentage = 100;
        } else if (salesPercentage > 100) {
          salesPercentage = 100;
        }


        const icon = salesPercentage > 0 ? "⬆" : "⬇";

        let innerLabel = `Profit`;

        // Add HTML and CSS styles to the inner label
        let formattedLabel = '<div style=" color: green; ">';
        // formattedLabel += innerLabel;
        // formattedLabel += '<br>';
        // formattedLabel += '<span style="color: green;">' + 'Percentage' + '</span>';
        // formattedLabel += '<br>';
        formattedLabel +=
          '<span style="color: ' +
          (salesPercentage > 0 ? "green" : "red") +
          ';  font-size:15px ;">' +
          icon +
          " " +
          Math.abs(salesPercentage) +
          " % </span>";

        // formattedLabel += '<span style="color:' +salesPercentage >0 ? +'green' +:  'red'">' + icon + ' ' +  Math.abs(salesPercentage) + ' % </span>';
        formattedLabel += "</div>";

        setGrossProfit(
          processResponse3(
            data?.data !== "N/A" ? data?.data : {},
            colors,
            "",
            true,
            formattedLabel
          )
        );

        // setGrossProfit(grossProfit)
      } else {
        setLoader3(true);
      }
      //api3
    } catch (error) {
      console.error("Error fetching EXD3 data:", error);
    } finally {
      setLoader3(false);
    }
  };
  const getEXD4 = async (res) => {
    try {
      setLoader4(true);
      let api;
      if (res && res.to && res.from) {
        api = `/api/dashboards/bi/4a?to=${res.to}&from=${res.from}`;
      } else {
        api = `/api/dashboards/bi/4a`;
      }
      // res.to && res.from ? api= `/api/dashboards/bi/4a?to=${res.to}&from=${res.from}` : api =`/api/dashboards/bi/4a`
      const response = await fetch(api);
      const data = await response.json();
      if (data?.statusCode === "200") {
        // const  arr = Array.isArray(data?.data) ? data?.data.reverse() : []
        const dataArray = Array.isArray(data?.data) ? data?.data : [];
        const arr = dataArray.sort((a, b) =>
          a.yearMonth > b.yearMonth ? 1 : -1
        );
        setAllCogsChartData(arr);

        const itemsArray = Array.isArray(arr)
          ? arr?.filter((item) => item.categoryName === "items")
          : [];
        const packagesArray = Array.isArray(arr)
          ? arr?.filter((item) => item.categoryName === "packages")
          : [];
        const servicesArray = Array.isArray(arr)
          ? arr?.filter((item) => item.categoryName === "services")
          : [];

        setCogsItems(itemsArray);
        setCogsServices(servicesArray);
        setCogsPackages(packagesArray);
        setCogsChartData(processResponse13(arr));
      } else {
        setLoader4(true);
      }
    } catch (error) {
      console.error("Error fetching EXD4 data:", error);
    } finally {
      setLoader4(false);
    }
  };
  const getEXD5 = async () => {
    try {
      setLoader5(true);
      const response = await fetch("/api/dashboards/bi/5");
      const data = await response.json();

      // Process and update state as needed
      setAccountsData(data?.data || {});
    } catch (error) {
      console.error("Error fetching EXD5 data:", error);
    } finally {
      setLoader5(false);
    }
  };
  const getEXD6 = async () => {
    try {
      setLoader6(true);
      const response = await fetch("/api/dashboards/bi/6");
      const data = await response.json();

      overDueChart = processResponse10(data?.data || {}, true);
      setOverDue(data?.data || {});
      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD6 data:", error);
    } finally {
      setLoader6(false);
    }
  };
  const getEXD7 = async () => {
    try {
      setLoader7(true);
      const response = await fetch("/api/dashboards/bi/7");
      const data = await response.json();
      let allData = data?.data ? data?.data.list : {};
      const filteredData = {};

      for (const key in allData) {
        if (allData[key] >= 1) {
          filteredData[key] = allData[key];
        }
      }
      const obj = { ...data?.data, list: filteredData };
      setOutstandingSummary(obj || {});

      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD7 data:", error);
    } finally {
      setLoader7(false);
    }
  };
  const getEXD8 = async (res) => {
    try {
      setLoader8(true);
      let api;
      if (res && res.to && res.from) {
        api = `/api/dashboards/bi/8?to=${res.to}&from=${res.from}`;
      } else {
        api = `/api/dashboards/bi/8`;
      }
      const response = await fetch(api);
      // const response = await fetch('/api/dashboards/bi/8');
      const data = await response.json();

      let heading1 = "Total Vehicles vs Vehicles Served";

      let colors1 = ["#f2b400", "#673ab7"];
      const obj1 = {
        totalServedVehicles: data?.data?.totalServedVehicles || 0,
        totalFleet: data?.data?.totalFleet || 0,
      };

      let heading2 = "Total Invoices vs Vehicles Served";
      let colors2 = ["#548e25", "#0288d1"];
      const obj2 = {
        totalDeliveryNotesCount: data?.data?.totalDeliveryNotesCount || 0,
        totalServedVehicles: data?.data?.totalServedVehicles || 0,
      };
      let avgInvoice = Math.ceil(
        data?.data?.totalDeliveryNotesCount / data?.data?.totalServedVehicles
      );
      let avgInvoice1= isNaN(avgInvoice) ? 0 : avgInvoice
      let innerLabel = `Visits Per Unit`;

      // Add HTML and CSS styles to the inner label
      let formattedLabel = '<div style=" color: #0288d1; ">';
      formattedLabel +=
        '<span style="color: green;font-size:15px ;">' + avgInvoice1 + "</span>";
      formattedLabel += "<br>";
      formattedLabel +=
        '<span style="color: #0288d1; font-size:11px ;">' +
        props.translate(innerLabel) +
        "</span>";
      formattedLabel += "</div>";

      const unitsPercent = (
        (data?.data?.totalServedVehicles / data?.data?.totalFleet) *
        100
      )?.toFixed(2);
      const unitsPercent1 = isNaN(unitsPercent)?0 :unitsPercent
      let formattedLabel1 = '<div style=" color: #f7b400; font-size:15px ;">';
      // formattedLabel += innerLabel;
      // formattedLabel += '<br>';
      formattedLabel1 +=
        '<span style="color: #f7b400; font-size:15px ;">' +
        Math.abs(unitsPercent1) +
        " % </span>";
      formattedLabel1 += "</div>";
      // Rest of your code

      fleetCountChart = processResponse3(
        obj1,
        colors1,
        "",
        false,
        formattedLabel1
      );
      fleetCountChart2 = processResponse3(
        obj2,
        colors2,
        "",
        false,
        formattedLabel
      );

      setFleetCount(data.data);

      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD8 data:", error);
    } finally {
      setLoader8(false);
    }
  };
  const getEXD9 = async () => {
    try {
      setLoader9(true);
      const response = await fetch("/api/dashboards/bi/9");
      const data = await response.json();
      // processResponse3

      let colors = ["#f2b400", "#673ab7"];

      let heading = "New Vehicles Vs Old Vehicles";
      const obj1 = {
        newVehicles: data?.data?.newVehicles || 0,
        oldVehicles: data?.data?.oldVehicles || 0,
      };
      let formattedLabel1 = '<div style=" color: #f7b400; font-size:15px ;">';
      // formattedLabel += innerLabel;
      // formattedLabel += '<br>';
      formattedLabel1 +=
        '<span style="color: #f7b400; font-size:15px ;">' +
          " + " +
          obj1?.newVehicles || 0 + " % </span>";
      formattedLabel1 += "</div>";
      setVehiclesData(
        processResponse3(obj1, colors, "", false, formattedLabel1)
      );
      // setVehiclesData(vehicleGrapgh)

      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD9 data:", error);
    } finally {
      setLoader9(false);
    }
  };
  const getEXD10 = async (res) => {
    try {
      setLoader10(true);
      // let api;
      // res.to && res.from ? api= `/api/dashboards/bi/10?to=${res.to}&from=${res.from}` : api =`/api/dashboards/bi/10`
      // const response = await fetch(api);
      const response = await fetch("/api/dashboards/bi/10");

      const data = await response.json();

      let colors1 = ["#f2b400", "#673ab7"];
      const obj1 = {
        ytdAverageTicket: data?.data?.ytdAverageTicket || 0,
        lyAverageTicket: data?.data?.lyAverageTicket || 0,
      };
      let chart = processResponse3(obj1, colors1);

      // setAvgTicketPrice(chart)
      setAvgTicketPrice(data.data || {});

      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD10 data:", error);
    } finally {
      setLoader10(false);
    }
  };
  const getEXD11 = async () => {
    try {
      setLoader11(true);
      const response = await fetch("/api/dashboards/bi/11");
      const data = await response.json();

      setTopologyData(data?.data || []);
      // heatMap = processResponse11(data.data);

      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD11 data:", error);
    } finally {
      setLoader11(false);
    }
  };
  // const getEXDTopology = async () => {
  //   try {
  //     setTopologyLoader(true);
  //     const topology1 = await fetch(
  //       'https://code.highcharts.com/mapdata/countries/sa/sa-all.topo.json'
  //     ).then((response) => response.json());
  //     setTopology(topology1)
  //   } catch (error) {
  //     console.error('Error fetching EXD11 data:', error);
  //   } finally {
  //     setTopologyLoader(false);
  //   }
  // };
  const getEXD12 = async () => {
    try {
      setLoader12(true);
      const response = await fetch("/api/dashboards/bi/12");
      const data = await response.json();

      if (Object.keys(data?.data).length > 0) {
        const sortedData = Object.entries(data?.data || {}).sort(
          (a, b) => b[1] - a[1]
        );

        // Take the top 7 items and create a new object
        const topItems = {};
        for (let i = 0; i < 7; i++) {
          const [key, value] = sortedData[i];
          topItems[key] = value;
        }

        // Calculate the sum of remaining values
        const sumOfRemaining = sortedData
          .slice(7)
          .reduce((sum, [, value]) => sum + value, 0);

        // Create the final object with top items and "Other"
        const obj = {
          ...topItems,
          Other: {
            Total: sumOfRemaining,
            ...sortedData.slice(7).reduce((other, [key, value]) => {
              other[key] = value;
              return other;
            }, {}),
          },
        };

        setOwnChart(obj);
        setOwnChart1(data.data || {});
        setVehiclesChart(processResponse4(obj));
        setVehicleCount(data.data);
      } else {
        setVehiclesChart(processResponse4({}));
        setVehicleCount({});
      }
      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD12 data:", error);
    } finally {
      setLoader12(false);
    }
  };

  // const getEXD13 = async () => {
  //   try {
  //     setLoader13(true);
  //     const response = await fetch('/api/dashboards/13');
  //     const data = await response.json();
  //     // Process and update state as needed
  //   } catch (error) {
  //     console.error('Error fetching EXD13 data:', error);
  //   } finally {
  //     setLoader13(false);
  //   }
  // };
  const getEXD14 = async (res) => {
    try {
      let api;
      if (res && res.to && res.from) {
        api = `/api/dashboards/bi/14a?to=${res.to}&from=${res.from}`;
      } else {
        api = `/api/dashboards/bi/14a`;
      }
      setLoader14(true);

      // res && res.to && res.from ? api= `/api/dashboards/bi/14a?to=${res.to}&from=${res.from}` : api =`/api/dashboards/bi/14a`
      const response = await fetch(api);

      // const response = await fetch('/api/dashboards/bi/14a');
      const data = await response.json();

      // Process and update state as needed
      setVerticalWiseChart(
        processResponse16(data?.data !== "N/A" ? data?.data : {})
      );
    } catch (error) {
      console.error("Error fetching EXD14 data:", error);
    } finally {
      setLoader14(false);
    }
  };
  const getEXD15 = async () => {
    try {
      setLoader15(true);
      const response = await fetch("/api/dashboards/bi/15");
      const data = await response.json();

      // const totalCount = data?.data !=='N/A' ? data?.data?.list?.reduce((total, item) => total + item.serviceCount, 0) : 0;

      //     // Add the totalCount to the response
      //     const modifiedResponse = {
      //       ...data.data,
      //       totalCount,
      //     };

      setBusinessPotential(data?.data !== "N/A" ? data?.data : {});
    } catch (error) {
      console.error("Error fetching EXD15 data:", error);
    } finally {
      setLoader15(false);
    }
  };
  const getEXD16 = async (res) => {
    try {
      setLoader16(true);
      let api;
      if (res && res.to && res.from) {
        api = `/api/dashboards/bi/16?to=${res.to}&from=${res.from}`;
      } else {
        api = `/api/dashboards/bi/16`;
      }
      // res.to && res.from ? api= `/api/dashboards/bi/16?to=${res.to}&from=${res.from}` : api =`/api/dashboards/bi/16`
      const response = await fetch(api);
      // const response = await fetch('/api/dashboards/bi/16');
      const data = await response.json();

      let items = data?.data || [];
      items.sort((a, b) => b.usedCount - a.usedCount);

      // Pick the top 7 items
      const top7Items = items.slice(0, 7);

      // Calculate the sum of usedCount for the remaining items
      const remainingItems = items.slice(7);
      const sumOfRemainingCounts = remainingItems.reduce(
        (sum, item) => sum + item.usedCount,
        0
      );

      // Construct the arr1 array
      const arr1 = [...top7Items];

      // Add the "others" item to arr1
      arr1.push({
        itemCode: "Other",
        itemName: "Other",
        usedCount: sumOfRemainingCounts,
        list: remainingItems.map((item) => ({
          itemCode: item.itemCode,
          itemName: item.itemName,
          usedCount: item.usedCount,
        })),
      });

      // Output arr1
      let colors = [
        "#f2b400",
        "#0288d1",
        "#62e854",
        "#ff5722",
        "#548e25",
        "#673ab7",
        "#be1be1",
        "#005e20",
      ];
      let heading = "Spare Parts Group Wise";

      setSparePartsChart(arr1);

      setSparePartsChart1(items);

      setSparePartsChartNew(processResponse14(arr1));

      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD16 data:", error);
    } finally {
      setLoader16(false);
    }
  };
  const getEXD17 = async (res) => {
    try {
      setLoader17(true);
      let api;
      if (res && res.to && res.from) {
        api = `/api/dashboards/bi/17?to=${res.to}&from=${res.from}`;
      } else {
        api = `/api/dashboards/bi/17`;
      }
      // res.to && res.from ? api= `/api/dashboards/bi/17?to=${res.to}&from=${res.from}` : api =`/api/dashboards/bi/17`
      const response = await fetch(api);
      // const response = await fetch('/api/dashboards/bi/17');
      const data = await response.json();

      // servicesPartsChart = processResponse15(data.data);
      let items = data.data || [];
      items.sort((a, b) => b.usedCount - a.usedCount);

      // Pick the top 7 items
      const top7Items = items.slice(0, 7);

      // Calculate the sum of usedCount for the remaining items
      const remainingItems = items.slice(7);
      const sumOfRemainingCounts = remainingItems.reduce(
        (sum, item) => sum + item.usedCount,
        0
      );

      // Construct the arr1 array
      const arr1 = [...top7Items];

      // Add the "others" item to arr1
      arr1.push({
        itemCode: "Other",
        itemName: "Other",
        usedCount: sumOfRemainingCounts,
        list: remainingItems.map((item) => ({
          itemCode: item.itemCode,
          itemName: item.itemName,
          usedCount: item.usedCount,
        })),
      });

      // Output arr1
      let colors = [
        "#f2b400",
        "#0288d1",
        "#62e854",
        "#ff5722",
        "#548e25",
        "#673ab7",
        "#be1be1",
        "#005e20",
      ];
      let heading = "Services Group Gise";
      setservicesPartsChart(arr1);
      setservicesPartsChart1(items);
      setservicesPartsChartData(processResponse14(arr1));

      // Process and update state as needed
    } catch (error) {
      console.error("Error fetching EXD17 data:", error);
    } finally {
      setLoader17(false);
    }
  };

  //  function to process response2 data
  const processResponse2 = (res) => {
    // Extract the sales values from the response object
    const { currentYearSale, lastYearSale } = res;

    const saleDifference = currentYearSale - lastYearSale;
    const percentageChange = (saleDifference / Math.abs(lastYearSale)) * 100;

    const changeType = saleDifference > 0 ? "increase" : "decrease";

    const result = {
      // chartOptions:chartOptions,
      currentYearSale: currentYearSale, // Keep currentYearSale as a number
      lastYearSale: lastYearSale, // Keep lastYearSale as a number
      saleDifference: Math.abs(saleDifference), // Store as a number
      percentageChange: percentageChange, // Store as a number
      changeType: changeType,
    };
    return result;
  };

  //  function to process response1 data
  const processResponse3 = (data, colors, label, per, innerLAbel) => {
    const dataPoints = [];

    // Define the label mappings
    const labelMappings = {
      currentYearSale: "Current Month Sales",
      currentMonthProfit: "Current Month Gross Profit",
      lastMonthProfit: "Prior Month Gross Profit",
      lastYearSale: "Prior Month Sales",
      newVehicles: "New VINs Added",
      oldVehicles: "Old VIN Count",
      totalServedVehicles: "Total Units Served",
      totalFleet: "Total Fleet on Platform",
      totalDeliveryNotesCount: "Total Invoices",
      ytdAverageTicket: "Current Month",
      lyAverageTicket: "Last Month",
      // Add more mappings as needed
    };

    for (const key in data) {
      const renamedLabel = labelMappings[key] || key; // Use the mapped label or the original key
      dataPoints.push({
        name: props.translate(renamedLabel),
        y: data[key],
      });
    }

    // Ensure that the number of colors matches the number of data points
    const validColors = colors.slice(0, dataPoints.length);

    for (let i = 0; i < dataPoints.length; i++) {
      dataPoints[i].color = validColors[i] || "#000000"; // Default to black if not enough colors provided
    }

    return {
      chart: {
        type: "pie",
        height: "300px",
      },
      // title: {
      //   text: label ? `<strong>${label}</strong>` : '',
      // },
      title: {
        text: innerLAbel,
        align: "center",
        verticalAlign: "middle",
        y: 0,
      },
      tooltip: {
        formatter: function () {
          const isSARRequired =
            this.key === "Current Month Sales" ||
            this.key === "Current Month Gross Profit" ||
            this.key === "Prior Month Sales" ||
            this.key === "Prior Month Gross Profit";

          let tooltipText =
            "<strong>" +
            this.key +
            "</strong>" +
            " <br>" +
            "<strong>" +
            Number(this.y).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            "</strong>";

          if (isSARRequired) {
            tooltipText += " SAR"; // Add "SAR" to the tooltip
          }

          return tooltipText;
        },
      },

      plotOptions: {
        pie: {
          innerSize: "50%", // Set the inner size to create the donut effect
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            format: "{point.name}: {point.percentage:.1f}%",
          },
          showInLegend: true, // Show legend instead of data labels
        },
      },
      legend: {
        layout: "horizontal", // Display the legend horizontally
        align: "center",
        verticalAlign: "bottom", // Show legend below the chart
        itemStyle: {
          color: "black", // Color of legend text
        },
        maxHeight: 200, // Set a fixed height
        labelFormatter: function () {
          const point = dataPoints[this.index];
          let label;

          if (point.name === "Current Month Gross Profit" && per) {
            label =
              point.name +
              " : " +
              Number(this.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });

            let formattedLabel =
              '<div style="display: flex; justify-content: space-between;">';
            formattedLabel +=
              '<span style="color:' +
              point.color +
              ';">' +
              label +
              " SAR " +
              "</span>";

            // const percentage = (((point.y - data["lastMonthProfit"]) / data["lastMonthProfit"]) * 100).toFixed(2);

            // const icon = percentage > 0 ? '⬆' : '⬇';
            // const textColor = percentage > 0 ? 'green' : 'red';
            // formattedLabel += '<span style="color:' + textColor + '; margin-left: 10px;">' + '    ' + icon + ' ' + Math.abs(percentage) + '% </span>';

            formattedLabel += "</div>";

            return formattedLabel;
          } else if (point.name === "Prior Month Gross Profit") {
            label =
              point.name +
              " : " +
              this.y.toLocaleString({
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            let formattedLabel =
              '<span style="color:' +
              point.color +
              ';">' +
              label +
              " SAR " +
              "</span>";
            return formattedLabel;
          } else {
            label =
              point.name +
              " : " +
              this.y.toLocaleString({
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            let formattedLabel =
              '<span style="color:' + point.color + ';">' + label + "</span>";
            return formattedLabel;
          }
        },

        // labelFormatter: function () {
        //   return '<span style="color:' + dataPoints[this.index].color + '">' + dataPoints[this.index].name + ': ' + Highcharts.numberFormat(this.y, 2) + '</span><br>';
        // },
      },
      series: [
        {
          data: dataPoints,
        },
      ],
      credits: {
        enabled: false, // Set to false to hide the credits label
      },
    };
  };

  //  function to process  data
  const processResponse4 = (res) => {
    let dt = [];
    totalVehicles = 0;
    // let colors = ["#da5e5e", "#f50057", "#7b101b", "#00c179", "#d52f2f", "#ec1c91", "#28a788", "#3f52b5e6", "#594a86", "#864a73"];
    let colors = [
      "rgb(27, 161, 234)",
      "rgb(255, 205, 25)",
      "rgb(123, 255, 109)",
      "rgb(0, 94, 32)",
      "rgb(215, 52, 250)",
      "rgb(153, 158, 255)",
      "rgb(255, 117, 153)",
      "#d52f2f",
    ];
    // let colors = ["#da5e5e", "#f50057", "#7b101b", "#00c179", "#d52f2f", "#ec1c91", "#28a788", "#3f52b5e6", "#594a86", "#864a73"];

    for (const [key, value] of Object.entries(res)) {
      let modifiedKey = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the first letter

      if (modifiedKey === "Car") {
        modifiedKey = "Sedan";
      } else if (modifiedKey === "Suv") {
        modifiedKey = "SUV";
      }

      totalVehicles = totalVehicles + value;
      dt.push({
        name: modifiedKey,
        y: value,
        value: value,
        formattedvalue: value,
      });
    }

    let makeData = [];
    dt &&
      dt.map((item, index) =>
        makeData.push({
          ...item,
          color: colors[index],
        })
      );

    const data = [{ data: makeData }];
    let someData = data;

    return {
      chart: {
        type: "pie", // Set the chart type to 'pie' for a circular chart
        height: 410,
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.percentage:.1f}%",
          },
        },
      },
      series: data,
      legend: {
        layout: "horizontal", // Display the legend horizontally
        align: "center",
        verticalAlign: "bottom", // Show legend below the chart
        itemStyle: {
          color: "gray", // Color of legend text
        },
        maxHeight: 80, // Set a fixed height
      },
      tooltip: {
        formatter: function () {
          let name = this.key;

          if (name === "Car") {
            name = "Sedan";
          } else if (name === "Suv") {
            name = "SUV";
          }

          let tooltipText =
            "<strong>" +
            props.translate(name) +
            "</strong>" +
            " <br>" +
            "<strong>" +
            Number(this.y) +
            "</strong>";

          if (name === "Others") {
            tooltipText += '<div style="max-height: 200px; overflow-y: auto;">';
            // Iterate through each key-value pair in the "Other" object
            if (typeof res.Other === "object") {
              for (const [otherName, otherValue] of Object.entries(res.Other)) {
                if (otherName !== "Total") {
                  // Display each pair on a new line
                  tooltipText +=
                    "<br><div><strong>" +
                    otherName.charAt(0).toUpperCase() +
                    otherName.slice(1) +
                    "</strong> - <strong>" +
                    Number(otherValue) +
                    "</strong></div>";
                }
              }
            }
            tooltipText += "</div>";
          }

          return tooltipText;
        },
      },

      credits: {
        enabled: false, // Set to false to hide the credits label
      },
      show: true,
    };
  };

  const processResponse10 = (res, check) => {
    // Convert the object into an array of key-value pairs
    const data = Object.entries(res);

    // Define a mapping of original keys to desired labels
    const keyLabelMapping = {
      "1_30Days": "1-30\nDays",
      "31_60Days": "31-60\nDays",
      "61_90Days": "61-90\nDays",
      "91_120Days": "91-120\nDays",
      "121_150Days": "121-150\nDays",
      "151_180Days": "151-180\nDays",
      "181_360Days": "181-360\nDays", // Line break for label
      "360_aboveDays": "360+ \nDays",
      "currentTotal ": "Current Total", // Remove the space
      currentTotal: "Current Total",
      pastDueTotal: "Past Due Total",
    };

    // Filter out "grandTotal" from the data
    const filteredData = data.filter(([key]) => key !== "grandTotal");

    const chartData = filteredData.map(([key, value]) => ({
      label: props.translate(keyLabelMapping[key]) || props.translate(key), // Use the mapped label or the original key
      name: props.translate(key), // Use the mapped label or the original key
      y: Number(value).toFixed(2), // Convert to a number, round to 2 decimal places
      formattedValue: Number(value).toLocaleString(), // Format value with commas
    }));

    // Ensure that "currentTotal" is the first column and "pastDueTotal" is the last
    chartData.sort(function (a, b) {
      if (a.label === "Current Total") return -1;
      if (b.label === "Current Total") return 1;
      if (a.label === "Past Due Total") return 1;
      if (b.label === "Past Due Total") return -1;
      return a.label.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });

    let colors = [
      "#ff5722",
      "#548e25",
      "#0288d1",
      "#da5e5e",
      "#f50057",
      "#7b101b",
      "#00c179",
      "#d52f2f",
    ];

    const chartOptions = {
      chart: {
        type: "column", // Set the chart type to 'column' for a vertical bar chart
        height: "400px",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: chartData.map((item) => item.label),
        title: {
          text: "",
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          colorByPoint: true, // Use different colors for each bar
          pointPadding: 0.2, // Adjust spacing between bars
          borderWidth: 0, // Remove the border around bars
          dataLabels: {
            formatter: function () {
              // Format the label text using toLocaleString()
              return nFormatter(this.y, 2);
            },
            enabled: true,
            // format: '{point.formattedValue}', // Display the formatted value on top of the bar
          },
          point: {
            events: {
              click: function () {
                // Call your function and pass the clicked point's name as a parameter
                if (check) {
                  handleBarClick6(this.name);
                }
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          const key = keyLabelMapping[this.key] || this.key;
          return (
            "<strong>" +
            props.translate(key) +
            "</strong>" +
            "<br>" +
            "<b>" +
            this.y.toLocaleString({
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            " SAR " +
            "</b>"
          );
        },
      },
      // tooltip: {
      //   pointFormat: '{point.y:.2f}</b>',
      //   formatter: function () {
      //     return  "<strong>" + this.key + "</strong>" +  "<br>" +"<b>" + this.y.toLocaleString({ minimumFractionDigits: 2, maximumFractionDigits: 2, }) + " SAR " + "</b>";
      //   }
      // },
      series: [
        {
          name: "",
          showInLegend: false,
          data: chartData.map((item, index) => ({
            y: parseFloat(item.y),
            name: item.name,
            formattedValue: item.formattedValue,
            color: colors[index % colors.length], // Assign colors from the colors array
          })),
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartOptions;
  };

  const handleBarClick6 = (pointData) => {
    let title;
    if (pointData === "1_30Days") {
      title = "1-30 Days";
    } else if (pointData === "31_60Days") {
      title = "31-60 Days";
    } else if (pointData === "61_90Days") {
      title = "61-90 Days";
    } else if (pointData === "91_120Days") {
      title = "91-120 Days";
    } else if (pointData === "121_150Days") {
      title = "121-150 Days";
    } else if (pointData === "151_180Days") {
      title = "151-180 Days";
    } else if (pointData === "181_360Days") {
      title = "181-360 Days";
    } else if (pointData === "360_aboveDays") {
      title = "360+  Days";
    } else if (pointData === "currentTotal") {
      title = "Current Total";
    } else if (pointData === "pastDueTotal") {
      title = "Past Due Total";
    }

    setShowAccountsTitle(title);

    const apiUrl = `/api/dashboards/bi/6a?value=${pointData}`;
    setShowAccountsLoader(true);
    setShowAccountsData(true);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response data as needed
        const arr = data?.data || [];
        const filteredData = arr.map((item) => {
          // Use Object.keys and Object.entries to filter out keys with 0 values
          const filteredItem = Object.fromEntries(
            Object.entries(item).filter(([key, value]) => value > 0)
          );
          return filteredItem;
        });

        setAccountsDataChart(processResponse10(filteredData[0]) || {}, false);
      })
      .catch((error) => {
        // Handle any errors that occur during the API request
        console.error("API Error:", error);
        setShowAccountsLoader(false);
      })
      .finally(() => {
        setShowAccountsLoader(false);
      });
  };

  const processResponse13 = (res, check, color) => {
    const categoryColors = {
      items: "#ff5722", // Red
      services: "#548e25", // Green
      packages: "#0288d1", // Blue
    };

    const data = Array.isArray(res) ? res : [];

    // Group the data by "yearMonth"
    const groupedData = data.reduce((grouped, item) => {
      const key = item.yearMonth;
      if (!grouped[key]) {
        grouped[key] = { yearMonth: key };
      }
      if (!grouped[key][item.categoryName]) {
        grouped[key][item.categoryName] = 0;
      }
      grouped[key][item.categoryName] += item.saleNetAmount;
      return grouped;
    }, {});

    const chartData = Object.values(groupedData);
    const categories = chartData.map((item) => item.yearMonth);

    // Determine the unique category names
    const categoryNames = Array.from(
      new Set(data.map((item) => item.categoryName))
    );

    // Rename items to parts
    categoryNames.map((categoryName) => {
      if (categoryName === "items") {
        categoryName = "parts";
      }
      return categoryName;
    });

    // Move the parts category to the beginning
    categoryNames.unshift(categoryNames.pop());

    // Move the parts category to the beginning
    // Check if there's only one unique category name
    if (categoryNames.length === 1) {
      const singleCategoryName = categoryNames[0];

      // Update the chart series and categories for a single category
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 400,
            scrollPositionX: 1,
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: "",
          },
          min: 0,
          max: chartData.length - 1,
          scrollbar: {
            enabled: true,
          },
          categories,
        },
        yAxis: {
          title: {
            text: props.translate("Sale Net Amount (SAR)"),
          },
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              Number(this.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR " +
              "</strong>"
            );
          },
        },
        plotOptions: {
          column: {
            grouping: true,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return nFormatter(this.y, 2);
              },
            },
            pointWidth: 10,
            pointPadding: 0.1,
            groupPadding: 0.1,
            events: {
              click: function (event) {
                const pointData = event.point;
                if (!check) {
                  handleBarClick(pointData);
                }
              },
            },
          },
        },
        series: [
          {
            name:
              singleCategoryName === "items"
                ? "PARTS"
                : singleCategoryName?.toUpperCase(),
            data: chartData.map((item) => item[singleCategoryName] || 0),
            color: categoryColors[singleCategoryName], // Use original category color
          },
        ],
        scrollbar: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: data.length > 0
        }
      };
    } else {
      // Create the chart with multiple categories
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 400,
            scrollPositionX: 1,
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: "",
          },
          min: 0,
          max: chartData.length - 1,
          scrollbar: {
            enabled: true,
          },
          categories,
        },
        yAxis: {
          title: {
            text: "Sale Net Amount (SAR)",
          },
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              Number(this.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR " +
              "</strong>"
            );
          },
        },
        plotOptions: {
          column: {
            grouping: true,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return nFormatter(this.y, 2);
              },
            },
            pointWidth: 10,
            pointPadding: 0.1,
            groupPadding: 0.1,
            events: {
              click: function (event) {
                const pointData = event.point;
                if (!check) {
                  handleBarClick(pointData);
                }
              },
            },
          },
        },
        series: categoryNames.map((categoryName, index) => ({
          name:
            categoryName === "items" ? "PARTS" : categoryName?.toUpperCase(),
          data: chartData.map((item) => item[categoryName] || 0),
          color: categoryColors[categoryName], // Use original category color
          showInLegend: chartData.some((item) => item[categoryName] > 0),
          dataLabels: {
            enabled: true, // Ensure data labels are enabled
          },
        })),
        scrollbar: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: data.length > 0
        }
      };
    }
  };

  const processResponse13b = (res, check, color) => {
    const categoryColors = {
      items: "#ff5722", // Red
      services: "#548e25", // Green
      packages: "#0288d1", // Blue
    };

    const data = Array.isArray(res) ? res : [];

    // Group the data by "dayOffMonth"
    const groupedData = data.reduce((grouped, item) => {
      const key = item.dayOffMonth;
      if (!grouped[key]) {
        grouped[key] = { dayOffMonth: key };
      }
      if (!grouped[key][item.categoryName]) {
        grouped[key][item.categoryName] = 0;
      }
      grouped[key][item.categoryName] += item.saleNetAmount;
      return grouped;
    }, {});

    const chartData = Object.values(groupedData);
    const categories = chartData.map((item) => item.dayOffMonth);

    // Determine the unique category names
    const categoryNames = Array.from(
      new Set(data.map((item) => item.categoryName))
    );

    // Check if there's only one unique category name
    if (categoryNames.length === 1) {
      const singleCategoryName = categoryNames[0];

      // Update the chart series and categories for a single category
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 400,
            scrollPositionX: 1,
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: "",
          },
          min: 0,
          max: chartData.length - 1,
          scrollbar: {
            enabled: true,
          },
          categories,
        },
        yAxis: {
          title: {
            text: "Sale Net Amount (SAR)",
          },
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              Number(this.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR " +
              "</strong>"
            );
          },
        },
        plotOptions: {
          column: {
            grouping: true,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return nFormatter(this.y, 2);
              },
            },
            pointWidth: 10,
            pointPadding: 0.1,
            groupPadding: 0.1,
            events: {
              click: function (event) {
                const pointData = event.point;
                if (!check) {
                  handleBarClick(pointData);
                }
              },
            },
          },
        },
        series: [
          {
            // name: singleCategoryName?.toUpperCase(),
            name:
              singleCategoryName === "items"
                ? "PARTS"
                : singleCategoryName?.toUpperCase(),

            data: chartData.map((item) => item[singleCategoryName] || 0),
            color: categoryColors[singleCategoryName], // Use original category color
          },
        ],
        scrollbar: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
      };
    } else {
      // Create the chart with multiple categories
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 400,
            scrollPositionX: 1,
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: "",
          },
          min: 0,
          max: chartData.length - 1,
          scrollbar: {
            enabled: true,
          },
          categories,
        },
        yAxis: {
          title: {
            text: "Sale Net Amount (SAR)",
          },
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              Number(this.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR " +
              "</strong>"
            );
          },
        },
        plotOptions: {
          column: {
            grouping: true,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return nFormatter(this.y, 2);
              },
            },
            pointWidth: 10,
            pointPadding: 0.1,
            groupPadding: 0.1,
            events: {
              click: function (event) {
                const pointData = event.point;
                if (!check) {
                  handleBarClick(pointData);
                }
              },
            },
          },
        },
        series: categoryNames.map((categoryName, index) => ({
          name:
            categoryName === "items" ? "PARTS" : categoryName?.toUpperCase(),
          // name: categoryName?.toUpperCase(),
          data: chartData.map((item) => item[categoryName] || 0),
          color: categoryColors[categoryName], // Use original category color
          showInLegend: chartData.some((item) => item[categoryName] > 0),
        })),
        scrollbar: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
      };
    }
  };

  const processResponse14 = (data, customColors, label) => {
    // Process the data and create the chart options
    let colors = [
      "#f2b400",
      "#0288d1",
      "#62e854",
      "#ff5722",
      "#548e25",
      "#673ab7",
      "#be1be1",
      "#005e20",
    ];

    const chartData = data?.map((item, index) => ({
      name: item.itemName,
      y: item.usedCount,
      color: colors[index % colors.length], // Use a custom color from the list
    }));

    return {
      chart: {
        type: "pie",
        height: 400, // Increase the chart's height
      },
      title: {
        text: label ? `<strong>${label}</strong>` : "",
      },
      tooltip: {
        useHTML: true, // Allow HTML content in the tooltip
        formatter: function () {
          let tooltipText = "<strong>" + props.translate(this.key) + "</strong>";
          tooltipText +=
            "<br><strong>" +
            Number(this.y).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            "</strong>";

          if (this.key === "Other") {
            // Handle "Other" case with the list
            tooltipText +=
              '<div style="max-height: 300px; overflow-y: auto; ">'; // Add inline CSS styles
            data.forEach((item) => {
              if (item.itemCode === "Other") {
                item.list.forEach((listItem) => {
                  tooltipText += `<div><strong>${
                    listItem.itemName
                  }</strong> (<strong>${Number(
                    listItem.usedCount
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}</strong>)</div>`;
                });
              }
            });
            tooltipText += "</div>"; // Close the container div
          }

          return tooltipText;
        },
      },

      // tooltip: {
      //   formatter: function () {
      //     return (
      //       "<strong>" +
      //       this.key +
      //       "</strong>" +
      //       " <br>" +
      //       "<strong>" +
      //       Number(this.y).toLocaleString(undefined, {
      //         minimumFractionDigits: 2,
      //         maximumFractionDigits: 2,
      //       }) +
      //       "</strong>"
      //     );
      //   },
      // },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true, // Disable data labels on the chart
            format: "{point.percentage:.1f}%",
          },
          // showInLegend: true, // Show legend instead of data labels
        },
      },
      legend: {
        layout: "horizontal", // Display the legend horizontally
        align: "center",
        verticalAlign: "bottom", // Show legend below the chart
        itemStyle: {
          color: "gray", // Color of legend text
        },
        maxHeight: 80, // Set a fixed height
      },
      series: [
        {
          name: "Items",
          data: chartData,
        },
      ],
      credits: {
        enabled: false, // Set to false to hide the credits label
      },
      show: true,
    };
  };

  const handleShowAllInventoryChange = () => {
    // setSparePartsChartNew(sparePartsChart);
    if (!showAllInventory) {
      setSparePartsChartNew(processResponse14(sparePartsChart1));
    } else {
      setSparePartsChartNew(processResponse14(sparePartsChart));
    }
    setShowAllInventory(!showAllInventory);
  };

  const handleNetSalesAnalytics = () => {
    // setSparePartsChartNew(sparePartsChart);
    if (garageDataType === "station") {
      // setStationWiseChart({})
      setGarageArea("");
      setGarageDataType("area");
    } else if (garageDataType === "area") {
      // setAreaWiseChart({})
      setGarageType("");
      setGarageDataType("name");
    }
  };

  const handleShowAllServicesChange = () => {
    // setSparePartsChartNew(sparePartsChart);
    if (showAllServices) {
      setservicesPartsChartData(processResponse14(servicesPartsChart));
    } else {
      setservicesPartsChartData(processResponse14(servicesPartsChart1));
    }
    setShowAllServices(!showAllServices);
  };

  const handleShowAllVehiclesChange = () => {
    // setSparePartsChartNew(sparePartsChart);
    if (!showAllVehicles) {
      setVehiclesChart(processResponse4(ownChart1));
    } else {
      setVehiclesChart(processResponse4(ownChart));
    }
    setShowAllVehicles(!showAllVehicles);
  };

  const handleCogsChartChange = (data) => {
    // setSparePartsChartNew(sparePartsChart);
    setDataCategory(data);

    if (cogsMonthly) {
      if (data === "all") {
        setCogsChartData(processResponse13(allCogsChartData));
      } else if (data === "items") {
        setCogsChartData(processResponse13(cogsItems));
      } else if (data === "servies") {
        setCogsChartData(processResponse13(cogsServices));
      } else if (data === "packages") {
        setCogsChartData(processResponse13(cogsPackages));
      }
      // else{
      //   setCogsChartData(processResponse13(allCogsChartData))
      // }
    } else {
      if (data === "all") {
        setCogsChartData(processResponse13b(cogsChartDaily, true));
      } else if (data === "items") {
        setCogsChartData(processResponse13b(cogsSubItems, true));
      } else if (data === "servies") {
        setCogsChartData(processResponse13b(cogsSubServices, true));
      } else if (data === "packages") {
        setCogsChartData(processResponse13b(cogsSubPackages, true));
      }
      // else{
      //   setCogsChartData(processResponse13(cogsChartDaily))
      // }
    }
  };

  const handleAccountsBack = () => {
    setShowAccountsData(!showAccountsData);
  };

  const handleBarClick = (pointData) => {
    // Clicking Bar to get data of daily basis
    const { category } = pointData;
    // Split the category to separate year and month
    const [year, month] = category && category.split("-");

    const dateObj = new Date(year, month - 1); // Month is 0-based, so subtract 1

    // Format the date in the desired format
    const formattedDate = dateObj.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
    });
    setDate(formattedDate);

    const apiUrl = `/api/dashboards/bi/4b?year=${year}&month=${month}`;
    setLoader4a(true);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response data as needed
        const arr = data?.data || [];

        const itemsArray = arr?.filter((item) => item.categoryName === "items");
        const packagesArray = arr?.filter(
          (item) => item.categoryName === "packages"
        );
        const servicesArray = arr?.filter(
          (item) => item.categoryName === "services"
        );
        setCogsSubItems(itemsArray);
        setCogsSubServices(servicesArray);
        setCogsSubPackages(packagesArray);

        setCogsChartDaily(arr);
        setCogsMonthly(false);
        setDataCategory("all");
        setCogsChartData(processResponse13b(arr, true));
      })
      .catch((error) => {
        // Handle any errors that occur during the API request
        console.error("API Error:", error);
      })
      .finally(() => {
        setLoader4a(false);
      });
  };

  const handleCogsChartBack = () => {
    // setSparePartsChartNew(sparePartsChart);
    if (dataCategory === "all") {
      setCogsChartData(processResponse13(allCogsChartData));
    } else if (dataCategory === "items") {
      setCogsChartData(processResponse13(cogsItems));
    } else if (dataCategory === "servies") {
      setCogsChartData(processResponse13(cogsServices));
    } else if (dataCategory === "packages") {
      setCogsChartData(processResponse13(cogsPackages));
    }
    // else{
    //   setCogsChartData(processResponse13(allCogsChartData))
    // }
    setCogsMonthly(true);
  };

  const processResponse16 = (data, check) => {
    // Create a mapping of data keys to custom labels
    const labelMapping = {
      amountCredited: props.translate("Advance Payment"),
      totalOutstanding: props.translate("totalOutstanding"),
      totalPaid: props.translate("Total Collected"),
    };
    let customColors;
    if (check) {
      customColors = ["#548e25", "#ff5722", "#f7b400"];
    } else {
      customColors = [
        "rgb(149, 206, 255)",
        "rgb(255, 188, 117)",
        "rgb(169, 255, 150)",
        "rgb(92, 92, 97)",
        "rgb(128, 133, 233)",
        "rgb(255, 117, 153)",
      ];
    }

    // Merge totalOutstanding and totalOverDue into a single entry
    const mergedTotalOutstanding = {
      name: "Total Collected",
      y: data.totalOutstanding,
      color: customColors[1], // Use the color for Total Outstanding
    };

    const modifiedData = Object.entries(data).map(([key, value], index) => ({
      // name: props.translate(labelMapping[key]) || key, // Use custom label if available, otherwise use the key
      name: labelMapping[key] || key, // Use custom label if available, otherwise use the key
      y: value,
      color:
        key === "amountCredited"
          ? "#f7b400"
          : customColors[index % customColors.length], // Set custom colors
    }));

    // Remove the totalOverDue entry
    const filteredData = modifiedData.filter(
      (entry) => entry.name !== "totalOverDue"
    );

    // Calculate the percentage of Total Outstanding
    const totalOutstandingValue = mergedTotalOutstanding.y;
    const percentageOfTotalOutstanding =
      (totalOutstandingValue / data.totalOutstanding) * 100;

    const due = totalOutstandingValue - data.totalOverDue;

    return {
      chart: {
        type: "pie",
        height: "400px",
      },
      title: {
        text: "",
      },
      tooltip: {
        formatter: function () {
          if (this.key === "Total Outstanding") {
            return (
              "<strong>"+ props.translate("totalOutstanding") + " : " +
              Number(totalOutstandingValue).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR</strong><br>" +
              "<strong>" +  props.translate("totalOverdue") + " : " +
              Number(data.totalOverDue).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR</strong><br>" +
              "<strong>" + props.translate("totalDue")  + " : " +
              Number(due).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR</strong>"
            );
          } else {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              Number(this.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " SAR" +
              "</strong>"
            );
          }
        },
      },

      plotOptions: {
        pie: {
          innerSize: "50%", // Set the inner size to create the donut effect
          dataLabels: {
            enabled: true,
            format: "{point.name}: {point.percentage:.1f}%",
          },
        },
        series: {
          point: {
            events: {
              click: function () {
                if (!check) {
                  handleVerticalClick(this.name);
                }
              },
            },
          },
        },
      },
      series: [
        {
          name: "Yearly Data",
          data: filteredData, // Use the modified data with custom labels (totalOverDue removed)
        },
      ],
      credits: {
        enabled: false, // Set to false to hide the credits label
      },
    };
  };

  const handleVerticalClick = (pointData, res) => {
    // const apiUrl = `/api/dashboards/bi/14b?garageType=${pointData}`;
    // setLoader14(true);
    // fetch(apiUrl)
    //   .then((response) => response.json())
    //   .then((data) => {
    // Handle the API response data as needed
    setGarageType(pointData);

    //   setAreaWiseChart(processResponse14b(data.data));
    //   setGarageDataType("area")
    // })
    // .catch((error) => {
    //   // Handle any errors that occur during the API request
    //   console.error("API Error:", error);
    // }).finally(() => {
    //   setLoader14(false);
    // });
  };

  const handleVerticalClick2 = (pointData) => {
    setGarageArea(pointData);
  };

  const processResponse14b = (data, check) => {
    let customColors = [
      "#da5e5e",
      "#f50057",
      "#7b101b",
      "#00c179",
      "#d52f2f",
      "#ec1c91",
      "#28a788",
      "#3f52b5e6",
      "#594a86",
      "#864a73",
    ];

    const options = {
      chart: {
        type: "column",
        height: "400px",
      },
      title: {
        text: "",
      },
      tooltip: {
        formatter: function () {
          return (
            "<strong>" +
            this.key +
            "</strong>" +
            " <br>" +
            "<strong>" +
            Number(this.y).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            " SAR " +
            "</strong>"
          );
        },
      },
      xAxis: {
        categories: Object.keys(data),
        title: {
          text: "",
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          colorByPoint: true, // Use different colors for each bar
          pointPadding: 0.2, // Adjust spacing between bars
          borderWidth: 0, // Remove border around bars
          dataLabels: {
            formatter: function () {
              // Format the label text using toLocaleString()
              return nFormatter(this.y, 2);
            },
            enabled: true,
            // format: '{point.formattedValue}', // Display the formatted value on top of the bar
          },
        },
        series: {
          point: {
            events: {
              click: function () {
                if (!check) {
                  handleVerticalClick2(this.category, garageTypeC);
                }
              },
            },
          },
        },
      },

      colors: customColors, // Set the custom colors for the graph
      series: [
        {
          showInLegend: false,
          name: "Regions",
          data: Object.values(data),
        },
      ],
      credits: {
        enabled: false, // Set to false to hide the credits label
      },
    };
    return options;
  };

  const getToFrom = (value) => {
    let timezone = "";
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone;
    } else {
      timezone = "Asia/Riyadh";
    }

    const now = moment().tz(timezone);

    if (value === 1) {
      const startOfMonth = now.clone().startOf("month");
      const endOfMonth = now.clone().endOf("month");

      return {
        from: startOfMonth.toISOString(),
        to: endOfMonth.toISOString(),
      };
    } else if (value === 2) {
      const lastMonthStart = now.clone().subtract(1, "month").startOf("month");
      const lastMonthEnd = now.clone().subtract(1, "month").endOf("month");

      return {
        from: lastMonthStart.toISOString(),
        to: lastMonthEnd.toISOString(),
      };
    } else if (value === 3) {
      const threeMonthsAgo = now.clone().subtract(3, "months");
      const startOfThreeMonthsAgo = threeMonthsAgo.clone().startOf("month");
      const endOfLastMonth = now.clone().subtract(1, "month").endOf("month");

      return {
        from: startOfThreeMonthsAgo.toISOString(),
        to: endOfLastMonth.toISOString(),
      };
    } else if (value === 4) {
      const startOfYear = now.clone().startOf("year");

      return {
        from: startOfYear.toISOString(),
        to: now.toISOString(),
      };
    } else if (value === 6) {
      return {
        from: false,
        to: false,
      };
    } else {
      const lastYearStart = now.clone().subtract(1, "year").startOf("year");
      const lastYearEnd = now.clone().subtract(1, "year").endOf("year");

      return {
        from: lastYearStart.toISOString(),
        to: lastYearEnd.toISOString(),
      };
    }
  };

  return (
    <div>
      <Grid container>
        {/* --------------------Row 1  ------------------ */}
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            // paddingBottom: 15,
            // marginBottom: 15,
            borderBottomWidth: 2,
            borderBottomColor: "#707070",
            borderBottomStyle: "solid",
          }}
        >
          {/* --------------------Row 1 box 1 ------------------ */}
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            style={{
              padding: "0px 5px",
              borderLeftWidth: "0",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
            }}
          >
            {loader1 && loader1 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "16px", fontWeight: 700 }}
                >
                    {props.translate("Monthly Sales")}
                  
                </Typography>
                {/* <div style={{
                              marginTop: 5,
                            }}  >
                  <HighchartsReact highcharts={Highcharts} options={salesChartMonthly} /> 

                          </div> */}
                <div
                  style={{
                    margin:
                      allSaleData?.monthlySale > allSaleData?.monthlyTarget
                        ? "14px 0px 16px"
                        : "20px 0px 16px",
                  }}
                  // margin: "40px 0px 16px 0px" }}
                >
                  <p
                    style={{
                      display: "flex",
                      fontWeight: "600px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginRight: "10px",
                      color: "goldenrod",
                    }}
                  >
                  {/* {  console.log("allSaleData======", allSaleData)} */}
                    {allSaleData?.monthlySale > allSaleData?.monthlyTarget ? (
                      <p>
                        {props.translate("Target Achieved")}
                        <span>
                          <AchieveTarget
                            style={{
                              marginLeft: "5px",
                              width: "20px",
                              height: "20px",
                              color: "goldenrod",
                            }}
                          />
                        </span>
                      </p>
                    ) : (
                      <p>
                       {isNaN(
                          (allSaleData?.monthlySale / allSaleData?.monthlyTarget) * 100
                        )
                          ? "0 %"
                          : (
                              (allSaleData?.monthlySale / allSaleData?.monthlyTarget) *
                              100
                            ).toFixed(2) + " %"}{" "}
                         {props.translate("Achieved")}
                      </p>
                    )}
                  </p>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      allSaleData?.monthlySale > allSaleData?.monthlyTarget
                        ? 100
                        : (allSaleData?.monthlySale /
                            allSaleData?.monthlyTarget) *
                          100
                    }

                    // value={allSaleData?.monthlyTarget > allSaleData?.monthlySale ?
                    //   100:
                    //  (allSaleData?.monthlyTarget / allSaleData?.monthlySale) * 100}
                  />
                </div>
                <Grid container alignContent="center">
                  <Grid item xs={12} md={6}>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {allSaleData?.monthlySale?.toLocaleString() || 0}
                        </Typography>
                      }
                      placement={"bottom-start"}
                      enterDelay={300}
                    >
                      <span
                        style={{
                          fontSize: 21,
                          fontWeight: "700",
                          color: "#548e25",
                        }}
                      >
                        {allSaleData?.monthlySale
                          ? nFormatter(allSaleData?.monthlySale, 2)
                          : 0}
                      </span>
                    </Tooltip>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#548e25",
                      }}
                    >
                      {" "}
                      {props.translate("SAR")}
                    </span>
                    <p style={{ fontSize: 13, fontWeight: 500 }}>
                      
                      {props.translate("Monthly Sales")}
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ textAlign: "end" }}>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {allSaleData?.monthlyTarget?.toLocaleString() || " "}
                        </Typography>
                      }
                      placement={"bottom-start"}
                      enterDelay={300}
                    >
                      <span
                        style={{
                          fontSize: 21,
                          fontWeight: "700",
                          color: "#ff5722",
                        }}
                      >
                        {allSaleData?.monthlyTarget
                          ? nFormatter(allSaleData?.monthlyTarget, 2)
                          : 0}
                      </span>
                    </Tooltip>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#ff5722",
                      }}
                    >
                      {" "}
                      {props.translate("SAR")}
                    </span>
                    <p style={{ fontSize: 13, fontWeight: 500 }}>
                      {props.translate("Monthly Target")}
                    </p>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {/* --------------------Row 1 box 2 ------------------ */}
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            style={{
              padding: "0px 5px",
              borderLeftWidth: "1px",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderTopWidth: xs ? "1px" : "none",
              borderTopColor: xs ? "#707070" : "none",
              borderTopStyle: xs ? "solid" : "none",
            }}
          >
            {loader1 && loader1 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "16px", fontWeight: 700 }}
                >
                  
                  {props.translate("Yearly Sales")}
                </Typography>
                {/* <div style={{
                              marginTop: 5,
                            }}  >
                  <HighchartsReact highcharts={Highcharts} options={salesChartMonthly} /> 

                          </div> */}

                <div
                  style={{
                    margin:
                      allSaleData?.yearlySale > allSaleData?.yearlyTarget
                        ? "14px 0px 16px"
                        : "20px 0px 16px",
                    // margin:"40px 0px 16px 0px"
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      fontWeight: "600px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginRight: "10px",
                      color: "goldenrod",
                    }}
                  >
                    {allSaleData?.yearlySale > allSaleData?.yearlyTarget ? (
                      <p>
                        {/* Target Achieved */}
                  {props.translate("Target Achieved")}

                        <span>
                          <AchieveTarget
                            style={{
                              marginLeft: "5px",
                              width: "20px",
                              height: "20px",
                              color: "goldenrod",
                            }}
                          />
                        </span>
                      </p>
                    ) : (
                      <p>
                            {isNaN(
                          (allSaleData?.yearlySale / allSaleData?.yearlyTarget) * 100
                        )
                          ? "0 %"
                          : (
                              (allSaleData?.yearlySale / allSaleData?.yearlyTarget) *
                              100
                            ).toFixed(2) + " %"}{" "}
                         {props.translate("Achieved")}
                        {/* {(
                          (allSaleData?.yearlySale /
                            allSaleData?.yearlyTarget) *
                          100
                        )?.toFixed(2)}{" "}
                        % {props.translate("Achieved")} */}

                      </p>
                    )}
                  </p>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      allSaleData?.yearlySale > allSaleData?.yearlyTarget
                        ? 100
                        : (allSaleData?.yearlySale /
                            allSaleData?.yearlyTarget) *
                          100
                    }

                    // value={allSaleData?.yearlyTarget > allSaleData?.yearlySale ?
                    //   100:
                    //  (allSaleData?.yearlyTarget / allSaleData?.monthlySale) * 100}
                  />
                </div>
                <Grid container alignContent="center">
                  <Grid item xs={12} md={6}>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {allSaleData?.yearlySale?.toLocaleString() || 0}
                        </Typography>
                      }
                      placement={"bottom-start"}
                      enterDelay={300}
                    >
                      <span
                        style={{
                          fontSize: 21,
                          fontWeight: "700",
                          color: "#548e25",
                        }}
                      >
                        {allSaleData?.yearlySale
                          ? nFormatter(allSaleData?.yearlySale, 2)
                          : 0}
                      </span>
                    </Tooltip>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#548e25",
                        position: 'relative',
                        left: 3
                      }}
                    >
                      {props.translate("SAR")}
                    </span>
                    <p style={{ fontSize: 13, fontWeight: 500 }}>{props.translate("CYTD Sales")}</p>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ textAlign: "end" }}>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {allSaleData?.yearlyTarget?.toLocaleString() || " "}
                        </Typography>
                      }
                      placement={"bottom-start"}
                      enterDelay={300}
                    >
                      <span
                        style={{
                          fontSize: 21,
                          fontWeight: "700",
                          color: "#ff5722",
                        }}
                      >
                        {allSaleData?.yearlyTarget
                          ? nFormatter(allSaleData?.yearlyTarget, 2)
                          : 0}
                      </span>
                    </Tooltip>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#ff5722",
                      }}
                    >
                      {" "}
                      {props.translate("SAR")}

                    </span>
                    <p style={{ fontSize: 13, fontWeight: 500 }}>
                      {props.translate("Annual Target")}
                    </p>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>

          {/* --------------------Row 1 box 3 ------------------ */}
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            style={{
              padding: "0px 5px",
              borderLeftWidth: "1px",
              borderRightWidth: xs || sm ? "none" : "1px",
              borderRightColor: xs || sm ? "none" : "#707070",
              borderRightStyle: xs || sm ? "none" : "solid",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderTopWidth: xs ? "1px" : "none",
              borderTopColor: xs ? "#707070" : "none",
              borderTopStyle: xs ? "solid" : "none",
            }}
          >
            {/* <HighchartsReact highcharts={Highcharts} options={currentYearSale.chartOptions} /> */}
            {/* --------------------Current Month Sales---------------- */}

            {loader2 && loader2 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    marginBottom: "0px",
                  }}
                >
                  {props.translate("Sale Performance Trend")}
                </Typography>

                {/* <p className="row-label" style={{ textAlign: "left", fontWeight: 700, fontSize: 15, }}>
                            {props.translate("Account Receivable vs Previous Month")}
                          </p> */}
                <div
                  style={{
                    display: "flex",
                    maxHeight: "55px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "5px 0 0 0",
                  }}
                >
                  {currentYearSale.currentYearSale <
                  currentYearSale.lastYearSale ? (
                    <ArrowDropDown
                      style={{ height: "84px", maxWidth: "170px" }}
                    />
                  ) : currentYearSale.currentYearSale >
                    currentYearSale.lastYearSale ? (
                    <ArrowDropUp
                      style={{ height: "84px", maxWidth: "170px" }}
                    />
                  ) : (
                    <ArrowStraight
                      style={{ height: "84px", maxWidth: "170px" }}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                    }}
                  >
                    {currentYearSale.currentYearSale >
                    currentYearSale.lastYearSale ? (
                      <ArrowDropUpIcon
                        style={{ color: "#548e25", fontSize: "22px" }}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{ color: "#ff5722", fontSize: "22px" }}
                      />
                    )}
                    <Typography style={{ fontSize: 22, fontWeight: "700" }}>
                      {Math.abs(
                        (
                          ((currentYearSale.currentYearSale -
                            currentYearSale.lastYearSale) /
                            currentYearSale.lastYearSale) *
                          100
                        )?.toFixed(1)
                      ) || 0}
                      %
                      {/* {(((currentYearSale.currentYearSale - currentYearSale.lastYearSale)/ currentYearSale.lastYearSale)*100)?.toFixed(2)} % */}
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "17px 0px 0px 0px",
                  }}
                >
                  <p
                    className="row-label"
                    style={{ fontSize: "13px", fontWeight: "400" }}
                  >
                    <span
                      style={{
                        width: "7px",
                        height: "7px",
                        background:
                          currentYearSale.currentYearSale <
                          currentYearSale.lastYearSale
                            ? "#ff5722"
                            : "#548e25",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    ></span>
                    <span style={{ margin: "0px 0px 0px 4px" }}>
                      
                      {props.translate("Current Year")}

                    </span>
                    <div style={{ display: "flex", marginLeft: "8px" }}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "14px" }}>
                            {currentYearSale.currentYearSale?.toLocaleString() ||
                              0}
                          </Typography>
                        }
                        placement={"bottom-center"}
                        enterDelay={300}
                      >
                        <span style={{ marginLeft: "8px", fontWeight: 600 }}>
                          {currentYearSale.currentYearSale
                            ? nFormatter(currentYearSale?.currentYearSale, 2)
                            : 0}{" "}
                          
                      {props.translate("SAR")}

                        </span>
                      </Tooltip>
                    </div>
                  </p>

                  <p
                    className="row-label"
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      //  margin:"0px 6px"
                    }}
                  >
                    <span
                      style={{
                        width: "7px",
                        height: "7px",
                        background:
                          currentYearSale.currentYearSale >=
                          currentYearSale.lastYearSale
                            ? "#ff5722"
                            : "#548e25",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    ></span>
                    <span style={{ margin: "0px 0px 0px 4px" }}>
                      
                      {props.translate("Prior Year")}

                    </span>
                    <div style={{ display: "flex", marginLeft: "8px" }}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "14px" }}>
                            {currentYearSale.lastYearSale?.toLocaleString() ||
                              0}
                          </Typography>
                        }
                        placement={"bottom-center"}
                        enterDelay={300}
                      >
                        <span style={{ marginLeft: "8px", fontWeight: 600 }}>
                          {currentYearSale.lastYearSale
                            ? nFormatter(currentYearSale?.lastYearSale, 2)
                            : 0}{" "}
                          
                      {props.translate("SAR")}

                        </span>
                      </Tooltip>
                    </div>
                  </p>
                </div>
              </>
            )}
          </Grid>
          {/* --------------------Row 1 box 4 ------------------ */}

          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            style={{
              marginLeft: sm ? "0.5px" : "none",
              padding: "0px 5px",
              borderRightWidth: xs ? "none" : "1px",
              borderRightColor: xs ? "none" : "#707070",
              borderRightStyle: xs ? "none" : "solid",
              borderTopWidth: xs || sm ? "1px" : "none",
              borderTopColor: xs || sm ? "#707070" : "none",
              borderTopStyle: xs || sm ? "solid" : "none",
            }}
          >
            {loader10 && loader10 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    marginBottom: "0px",
                    letterSpacing: 0
                  }}
                >
                  {props.translate("Average Ticket Price Trend")}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    maxHeight: "55px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "5px 0 0 0",
                  }}
                >
                  {avgTicketPrice?.ytdAverageTicket <
                  avgTicketPrice?.lyAverageTicket ? (
                    <ArrowDropDown style={{ height: "84px", width: "170px" }} />
                  ) : avgTicketPrice?.ytdAverageTicket >
                    avgTicketPrice?.lyAverageTicket ? (
                    <ArrowDropUp style={{ height: "84px", width: "170px" }} />
                  ) : (
                    <ArrowStraight style={{ height: "84px", width: "170px" }} />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                    }}
                  >
                    {avgTicketPrice?.ytdAverageTicket >
                    avgTicketPrice?.lyAverageTicket ? (
                      <ArrowDropUpIcon
                        style={{ color: "#548e25", fontSize: "22px" }}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{ color: "#ff5722", fontSize: "22px" }}
                      />
                    )}
                    <Typography style={{ fontSize: 22, fontWeight: "700" }}>
                      {Math.abs(
                        (
                          ((avgTicketPrice?.ytdAverageTicket -
                            avgTicketPrice?.lyAverageTicket) /
                            avgTicketPrice?.lyAverageTicket) *
                          100
                        )?.toFixed(1)
                      ) || 0}
                      %
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "17px 0px 0px 0px",
                  }}
                >
                  <p
                    className="row-label"
                    style={{ fontSize: "13px", fontWeight: "400" }}
                  >
                    <span
                      style={{
                        width: "7px",
                        height: "7px",
                        background:
                          avgTicketPrice?.ytdAverageTicket <
                          avgTicketPrice?.lyAverageTicket
                            ? "#ff5722"
                            : "#548e25",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    ></span>
                    <span style={{ margin: "0px 0px 0px  2px" }}>
                      
                      {props.translate("Current Year")}

                    </span>
                    <div style={{ display: "flex", marginLeft: "8px" }}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "14px" }}>
                            {avgTicketPrice?.ytdAverageTicket?.toLocaleString() ||
                              0}
                          </Typography>
                        }
                        placement={"bottom-center"}
                        enterDelay={300}
                      >
                        <span style={{ marginLeft: "8px", fontWeight: 600 }}>
                          {avgTicketPrice?.ytdAverageTicket
                            ? nFormatter(avgTicketPrice?.ytdAverageTicket, 2)
                            : 0}{" "}
                          
                          {props.translate("SAR")}
                        </span>
                      </Tooltip>
                    </div>
                  </p>

                  <p
                    className="row-label"
                    style={{ fontSize: "13px", fontWeight: "400" }}
                  >
                    <span
                      style={{
                        width: "7px",
                        height: "7px",
                        background:
                          avgTicketPrice?.ytdAverageTicket >=
                          avgTicketPrice?.lyAverageTicket
                            ? "#ff5722"
                            : "#548e25",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    ></span>
                    <span style={{ margin: "0px 0px 0px  2px" }}>
                     
                      {props.translate("Prior Year")}

                    </span>
                    <div style={{ display: "flex", marginLeft: "8px" }}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "14px" }}>
                            {avgTicketPrice?.lyAverageTicket?.toLocaleString() ||
                              0}
                          </Typography>
                        }
                        placement={"bottom-center"}
                        enterDelay={300}
                      >
                        <span style={{ marginLeft: "8px", fontWeight: 600 }}>
                          {avgTicketPrice?.lyAverageTicket
                            ? nFormatter(avgTicketPrice?.lyAverageTicket, 2)
                            : 0}{" "}
                          
                      {props.translate("SAR")}

                        </span>
                      </Tooltip>
                    </div>
                  </p>
                </div>
              </>
            )}
          </Grid>
          {/* --------------------Row 1 box 5 ------------------ */}
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            style={{
              marginLeft: sm ? "0.5px" : "none",
              padding: "0px 5px",
              borderRightWidth: xs ? "none" : "1px",
              borderRightColor: xs ? "none" : "#707070",
              borderRightStyle: xs ? "none" : "solid",
              borderTopWidth: xs || sm ? "1px" : "none",
              borderTopColor: xs || sm ? "#707070" : "none",
              borderTopStyle: xs || sm ? "solid" : "none",
            }}
          >
            {loader15 && loader15 ? (
              <MaintenanceItemEmpty />
            ) : (
              <div>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "16px", fontWeight: 700 }}
                >
                  {props.translate("Predictive Services")}
                </Typography>

                {/* <p className="row-label" style={{ textAlign: "left", fontWeight: 700, fontSize: 15, marginBottom:"6px" }}>
                        
                      </p> */}
                <Grid container alignContent="center">
                  <Grid item xs={12} md={6}>
                    {/* <p style={{ color: "#548e25", fontSize: 22, fontWeight: 700, }}>
                                {businessPotential?.totalCount || 0}
                              </p> */}
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {businessPotential?.totalBusinessPotentialCount?.toLocaleString() ||
                            0}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <p
                        style={{
                          color: "#548e25",
                          fontSize: 22,
                          fontWeight: 700,
                        }}
                      >
                        {(
                          Math.round(
                            businessPotential?.totalBusinessPotentialCount
                          ) || 0
                        ).toLocaleString()}
                        {/* <span style={{ fontSize:12, fontWeight: "700", color: "#548e25" }}> SAR</span> */}
                      </p>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    margin: "15px 0 5px 0",
                  }}
                >
                  {props.translate("Business Potential")}
                </Typography>

                {/* <p className="row-label" style={{ textAlign: "left", fontWeight: 700, fontSize: 15, margin:"20px 0 6px 0" }}>
                            {props.translate("Upcoming Business Potential")}
                          </p> */}
                <Grid container alignContent="center">
                  <Grid item xs={12} md={6}>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {businessPotential?.totalBusinessPotential?.toLocaleString() ||
                            0}
                        </Typography>
                      }
                      placement={"bottom-start"}
                      enterDelay={300}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          fontSize: 22,
                          fontWeight: "700",
                          color: "#548e25",
                        }}
                      >
                        {/* {(Math.round(businessPotential?.totalBusinessPotential) || 0).toLocaleString()}  */}

                        {businessPotential?.totalBusinessPotential
                          ? nFormatter(
                              businessPotential?.totalBusinessPotential,
                              2
                            )
                          : 0}
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "700",
                            color: "#548e25",
                            marginLeft: "5px",
                          }}
                        >
                          {" "}
                      {props.translate("SAR")}

                        </span>
                      </span>
                    </Tooltip>
                    {/* <Tooltip title={<Typography style={{fontSize:"14px",}}>
                                {businessPotential?.totalBusinessPotential?.toLocaleString() || 0}</Typography>}
                                placement="bottom-start" enterDelay={300} classes={{ tooltip: 'customTooltip' }}>
                                <p style={{ color: "#548e25", fontSize: 22, fontWeight: 700, }}>
                                  {(Math.round(businessPotential?.totalBusinessPotential) || 0).toLocaleString()} 
                                </p>
                              </Tooltip> */}
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>

          {/* --------------------Row 1 box 6 ------------------ */}
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            style={{
              marginLeft: sm ? "-1px" : "none",
              padding: "0px 5px",
              borderTopWidth: xs || sm ? "1px" : "none",
              borderTopColor: xs || sm ? "#707070" : "none",
              borderTopStyle: xs || sm ? "solid" : "none",
            }}
          >
            {loader5 && loader5 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "16px", fontWeight: 700 }}
                >
                  {props.translate("Active Accounts")}
                </Typography>

                {/* <p className="row-label" style={{ textAlign: "left", fontWeight: 700, fontSize: 15, marginBottom:"6px" }}>
                       
                        </p> */}
                <Grid container alignContent="center">
                  <Grid item xs={6} sm={6} md={6}>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {accountsData?.oldActiveAccounts?.toLocaleString() ||
                            ""}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <p
                        style={{
                          color: "#548e25",
                          fontSize: 22,
                          fontWeight: 700,
                        }}
                      >
                        {" "}
                        {parseInt(accountsData?.oldActiveAccounts) || 0}
                      </p>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {accountsData?.oldActiveAccounts +
                      accountsData?.newActiveAccounts >
                    accountsData?.inactiveAccounts ? (
                      <ArrowDropUpIcon
                        style={{ color: "#548e25", fontSize: "22px" }}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{ color: "#ff5722", fontSize: "22px" }}
                      />
                    )}
                    <span ml={5}>
                      {Math.abs(
                        (
                          ((accountsData?.oldActiveAccounts +
                            accountsData?.newActiveAccounts) /
                            accountsData?.totalAccounts) *
                          100
                        )?.toFixed(2)
                      ) || 0}
                      % {props.translate("Active")}
                      
                    </span>
                  </Grid>
                </Grid>

                {/* <p className="row-label" style={{ textAlign: "left", fontWeight: 700, fontSize: 15, margin:"20px 0 6px 0" }}>
                          {props.translate("New Accounts")}
                        </p> */}
                {/* <Typography variant="h6" gutterBottom 
                      style={{ fontSize: "16px", fontWeight: 700 , margin:"15px 0 5px 0"}}>
                        {props.translate("New Accounts")}</Typography> */}

                <Grid container alignContent="center">
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        fontSize: "15px",
                        fontWeight: 700,
                        margin: "15px 0 5px 0",
                        letterSpacing: 0
                      }}
                    >
                      {props.translate("New Accounts")}
                    </Typography>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {accountsData?.newActiveAccounts?.toLocaleString() ||
                            ""}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <p
                        style={{
                          color: "#548e25",
                          fontSize: 22,
                          fontWeight: 700,
                          // display:"flex", justifyContent:"center"
                        }}
                      >
                        {parseInt(accountsData?.newActiveAccounts) || 0}
                      </p>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    // style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        fontSize: "15px",
                        fontWeight: 700,
                        margin: "15px 0 5px 0",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {props.translate("All Accounts")}
                    </Typography>

                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {accountsData?.totalAccounts?.toLocaleString() || ""}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <p
                        style={{
                          color: "#548e25",
                          fontSize: 22,
                          fontWeight: 700,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {parseInt(accountsData?.totalAccounts) || 0}
                      </p>
                    </Tooltip>

                    {/* {
                              (accountsData?.oldActiveAccounts + accountsData?.newActiveAccounts) < accountsData?.inactiveAccounts
                            ? <ArrowDropUpIcon style={{ color: "#548e25", fontSize:'22px' }} />
                            : <ArrowDropDownIcon style={{ color: "#ff5722", fontSize:'22px' }} />
                            }
                            <span ml={5}>{((accountsData?.inactiveAccounts / accountsData?.totalAccounts) * 100)?.toFixed(2) || 0}% Inactive</span> */}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* --------------------Row 2   LAtest ------------------ */}

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            // paddingBottom: 15,
            // marginBottom: 15,
            borderBottomWidth: 2,
            borderBottomColor: "#707070",
            borderBottomStyle: "solid",
          }}
        >
          {/* --------------------Row 2 box 1 ------------------ */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{
              padding: "5px 5px 0px 5px",
              borderLeftWidth: "0",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
            }}
          >
            {loader3 && loader3 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "16px", fontWeight: 700 }}
                >
                  {" "}
                  {props.translate("Monthly Gross Profit")}
                </Typography>

                <HighchartsReact
                  highcharts={Highcharts}
                  options={grossProfit}
                />
              </>
            )}
          </Grid>

          {/* --------------------Row 2 box 2 ------------------ */}

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{
              padding: "5px 5px 0px 5px",
              borderLeftWidth: "1px",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderRightWidth: xs || sm ? "none" : "1px",
              borderRightColor: xs || sm ? "none" : "#707070",
              borderRightStyle: xs || sm ? "none" : "solid",
              borderTopWidth: xs ? "1px" : "none",
              borderTopColor: xs ? "#707070" : "none",
              borderTopStyle: xs ? "solid" : "none",
            }}
          >
            {loader9 && loader9 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "16px", fontWeight: 700 }}
                >
                 
                  {props.translate("Vehicle Analytics")}
                </Typography>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={vehiclesData}
                />
              </>
            )}
          </Grid>

          {/* --------------------Row 2 box 3 ------------------ */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{
              padding: "5px 5px 0px 5px",
              borderTopWidth: xs || sm ? "1px" : "none",
              borderTopColor: xs || sm ? "#707070" : "none",
              borderTopStyle: xs || sm ? "solid" : "none",
            }}
          >
            {loader8 && loader8 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h6"
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                  {props.translate("Fleet Service Throughput")}

                    
                  </Typography>
                  <div style={{ width: "110px", maxWidth: "120px" }}>
                    <Select
                      options={FiltersOption}
                      value={tab1} // Set the selected value to
                      onChange={(e) => handleChange("tab1", e)}
                    />
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={fleetCountChart2}
                />
              </>
            )}
          </Grid>

          {/* --------------------Row 2 box 4 ------------------ */}

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{
              padding: "5px 5px 0px 5px",
              borderLeftWidth: "1px",
              // borderRightWidth: "1px",
              // borderRightColor: "#707070",
              // borderRightStyle: "solid",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderTopWidth: xs || sm ? "1px" : "none",
              borderTopColor: xs || sm ? "#707070" : "none",
              borderTopStyle: xs || sm ? "solid" : "none",
            }}
          >
            {loader8 && loader8 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                    {/* Fleet Service Throughput  */}
                  {props.translate("Fleet Actuation")}

                   
                  </Typography>

                  <div style={{ width: "110px", maxWidth: "120px" }}>
                    <Select
                      options={FiltersOption}
                      value={tab1 ? tab1 : "All"} // Set the selected value to tab1
                      onChange={(e) => handleChange("tab1", e)}
                    />
                  </div>
                </div>

                <HighchartsReact
                  highcharts={Highcharts}
                  options={fleetCountChart}
                />
              </>
            )}
          </Grid>

          {/* <Grid item xs={4} md={2} style={{padding:"0px 0 0 10px", borderLeftWidth: "1px", borderLeftColor: "#707070", borderLeftStyle: "solid", }}>
                  {loader10 && loader10 ? (
                    <MaintenanceItemEmpty />
                    ) : ( 
                      <>
                      
                      <Typography variant="h6" gutterBottom style={{ fontSize: "16px", fontWeight: 700, marginBottom:"0px" }}> {props.translate("Avg. Ticket Price")}</Typography>

                          <div style={{
                            display:"flex", 
                          maxHeight:"55px", 
                          // margin:"15px 0 0 0"
                          }}>
                            {
                              avgTicketPrice?.ytdAverageTicket < avgTicketPrice?.lyAverageTicket
                            ? <ArrowDropDown style={{height:"84px", width:"170px"}}/>
                            : avgTicketPrice?.ytdAverageTicket > avgTicketPrice?.lyAverageTicket
                            ? <ArrowDropUp  style={{height:"84px", width:"170px"}} />
                            : <ArrowStraight  style={{height:"84px", width:"170px"}} />} 
                          </div>

                          <div style={{  margin:"17px 0 0 0"}}>

                          
                            <p className="row-label" style={{ fontSize: "13px", fontWeight: "400" }}>
                              <span style={{ width: '7px', height: '7px', 
                              background: avgTicketPrice?.ytdAverageTicket < avgTicketPrice?.lyAverageTicket ? "#ff5722" : "#548e25", borderRadius: "50%", display: "inline-block" }}></span>
                              <span style={{ margin: '0 8px' }}>Current Month Price</span> | 
                              <Tooltip title={<Typography style={{fontSize:"14px",}}>
                                    { avgTicketPrice?.ytdAverageTicket?.toLocaleString()  || 0}
                                </Typography> } placement={"bottom-center"} enterDelay={300}> 
                              <span style={{ marginLeft: '8px' }}>{avgTicketPrice?.ytdAverageTicket ? nFormatter(avgTicketPrice?.ytdAverageTicket,2) :0} SAR</span>
                            </Tooltip>
                            </p>
                      
                          <p className="row-label" style={{ fontSize: "13px", fontWeight: "400" }}>
                            <span style={{ width: '7px', height: '7px', background: avgTicketPrice?.ytdAverageTicket >= avgTicketPrice?.lyAverageTicket ? "#ff5722" : "#548e25", borderRadius: "50%", display: "inline-block" }}></span>
                            <span style={{ margin: '0 0 0 8px' }}>Previous Month Price</span> |
                            <Tooltip title={<Typography style={{fontSize:"14px",}}>
                                    { avgTicketPrice?.lyAverageTicket?.toLocaleString()  || 0}
                                </Typography> } placement={"bottom-center"} enterDelay={300}> 
                            <span style={{ marginLeft: '8px' }}>
                              {avgTicketPrice?.lyAverageTicket ? nFormatter(avgTicketPrice?.lyAverageTicket,2) :0} SAR
                            </span>
                          </Tooltip>
                          </p>
                          </div>   
                        </>
                    )
                  }
                </Grid> */}
        </Grid>

        {/* --------------------Row 3   LAtest ------------------ */}
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            // marginBottom: 10,
            borderBottomWidth: 2,
            borderBottomColor: "#707070",
            borderBottomStyle: "solid",
          }}
        >
          {/* --------------------Row 3 box 1 ------------------ */}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              padding: "5px 5px 0px 5px",
              // borderRightWidth: "1px",
              // borderRightColor: "#707070",
              // borderRightStyle: "solid",
            }}
          >
            {loader4 && loader4 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                {loader4a && loader4a ? (
                  <MaintenanceItemEmpty />
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap:xs?"wrap":"nowrap"
                      }}
                    >
                      {/* <Typography>Sales Data</Typography> */}
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ fontSize: "16px", fontWeight: 700 }}
                      >
                  {props.translate("Historical Sales Performance")}

                        
                      </Typography>
                      {/* {cogsMonthly ?  */}
                      {!cogsMonthly && (
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{ fontSize: "15px", fontWeight: 700 }}
                        >
                          {props.translate("Month")}  : {(date && date) || ""}
                        </Typography>
                      )}

                      <div style={{ display: "flex"}}>
                        {/* {dataCategory !=="all" ? */}
                        <Button
                          disabled={dataCategory === "all"}
                          style={{
                            // border:"1px",
                            margin: "0px 2px",
                            color: dataCategory === "all" ? "#fff" : "#00B06E",
                            background:
                              dataCategory === "all" ? "#00B06E" : "inherit",
                          }}
                          onClick={() => handleCogsChartChange("all")}
                        >
                           {props.translate("All")}
                          
                        </Button>
                        {/* :null} */}

                        {/* {dataCategory !=="items" ? */}
                        <Button
                          disabled={dataCategory === "items"}
                          style={{
                            margin: "0px 2px",
                            color:
                              dataCategory === "items" ? "#fff" : "#00B06E",
                            background:
                              dataCategory === "items" ? "#00B06E" : "inherit",
                          }}
                          onClick={() => handleCogsChartChange("items")}
                        >
                          
                          {props.translate("Parts")}

                        </Button>
                        {/* :null} */}

                        {/* {dataCategory !=="servies" ? */}
                        <Button
                          disabled={dataCategory === "servies"}
                          style={{
                            margin: "0px 2px",
                            borderColor: "#00B06E",
                            color:
                              dataCategory === "servies" ? "#fff" : "#00B06E",
                            background:
                              dataCategory === "servies"
                                ? "#00B06E"
                                : "inherit",
                          }}
                          onClick={() => handleCogsChartChange("servies")}
                        >
                          {props.translate("Services")}

                          
                        </Button>
                        {/* :  null} */}

                        {/* {dataCategory !=="packages" ? */}
                        <Button
                          disabled={dataCategory === "packages"}
                          style={{
                            margin: "0px 2px",
                            color:
                              dataCategory === "packages" ? "#fff" : "#00B06E",
                            background:
                              dataCategory === "packages"
                                ? "#00B06E"
                                : "inherit",
                          }}
                          onClick={() => handleCogsChartChange("packages")}
                        >
                          {/* Packages */}
                          {props.translate("Packages")}

                        </Button>
                        {/* :null} */}
                      </div>
                      {/* :""} */}

                      {cogsMonthly && (
                        <div style={{ width: "120px"}}>
                          <Select
                            options={FiltersOption}
                            value={tab2 ? tab2 : "Last Year"} // Set the selected value to tab1
                            onChange={(e) => handleChange("tab2", e)}
                          />
                        </div>
                      )}
                      {!cogsMonthly && (
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={handleCogsChartBack}
                        >
                          {props.translate("back")}

                          
                        </Button>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        overflow: "auto",
                      }}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={cogsChartData}
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {/* --------------------Row 3 box 2 ------------------ */}
          </Grid>

          {/* --------------------Row 3 box 2 ------------------ */}

          {/* <Grid item xs={4} md={3} style={{padding: "10px 20px",
              //  borderLeftWidth: "1px", borderLeftColor: "#707070", borderLeftStyle: "solid",
                }}>
                          {loader9 && loader9 ? (
                      <MaintenanceItemEmpty />
                      ) : ( 
                        <>
                        <Typography variant="h6" gutterBottom style={{ fontSize: "16px", fontWeight: 700 }}> New Vehicles Vs Old Vehicles</Typography>
                        <HighchartsReact highcharts={Highcharts} options={vehiclesData} />
                        </>
                        )}

                  </Grid> */}
        </Grid>

        {/* --------------------Row 4 Latest------------------ */}
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            // paddingBottom: 15,
            // marginBottom: 15,
            borderBottomWidth: 2,
            borderBottomColor: "#707070",
            borderBottomStyle: "solid",
          }}
        >
          {/* --------------------Row 4 box 1 ------------------ */}

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={{
              padding: "5px 5px 0px 5px",
              // borderLeftWidth: "1px",
              // borderRightWidth: "1px",
              // borderRightColor: "#707070",
              // borderLeftColor: "#707070",
              // borderLeftStyle: "solid",
              // borderRightStyle: "solid",
            }}
          >
            {loader16 && loader16 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                          {props.translate("Product Sales By Category")}

                    
                  </Typography>
                  <Button onClick={handleShowAllInventoryChange}>
                    {showAllInventory ?  props.translate("Default")  :  props.translate("All")  }
                  </Button>
                  <div style={{ width: "120px" }}>
                    <Select
                      options={FiltersOption}
                      value={tab8 ? tab8 : "All"} // Set the selected value to tab1
                      onChange={(e) => handleChange("tab8", e)}
                    />
                  </div>
                </div>
                {/* {showAllInventory ?  */}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={sparePartsChartNew}
                />
                {/* :  */}
                {/* <HighchartsReact highcharts={Highcharts} options={sparePartsChart} />} */}
              </>
            )}
          </Grid>

          {/* --------------------Row 4 box 2 ------------------ */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={{
              padding: "5px 5px 0px 5px",
              borderLeftWidth: "1px",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderTopWidth: xs ? "1px" : "none",
              borderTopColor: xs ? "#707070" : "none",
              borderTopStyle: xs ? "solid" : "none",
              borderBottomWidth:sm?"1px":"none",
              borderBottomColor:sm?"#707070":"none",
              borderBottomStyle:sm?"solid":"none",

            }}
          >
            {loader17 && loader17 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                          {props.translate("Service By Type")}

                    
                  </Typography>
                  <Button onClick={handleShowAllServicesChange}>
                    {showAllServices ? props.translate("Default")  :  props.translate("All")  }
                  </Button>
                  <div style={{ width: "120px" }}>
                    <Select
                      options={FiltersOption}
                      value={tab9 ? tab9 : "All"}
                      onChange={(e) => handleChange("tab9", e)}
                    />
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={servicesPartsChartData}
                />
              </>
            )}
            {/* <HighchartsReact highcharts={Highcharts} options={configTotalOverdue} /> */}
          </Grid>

          {/* --------------------Row 4 box 3 ------------------ */}

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={{
              padding: "5px 5px 0px 5px",
              borderLeftWidth: "1px",
              borderRightWidth:sm? "1px":"none",
              borderRightColor:sm? "#707070":"none",
              borderRightStyle: sm?"solid":"none",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderTopWidth: xs || sm ? "1px" : "none",
              borderTopColor: xs || sm ? "#707070" : "none",
              borderTopStyle: xs || sm ? "solid" : "none",
            }}
          >
            {loader12 && loader12 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                    {props.translate("Vehicle Category By Type")}
                    
                  </Typography>
                  <Button onClick={handleShowAllVehiclesChange}>
                    {showAllVehicles ? props.translate("Default")  :  props.translate("All")  }
                  </Button>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={vehiclesChart}
                />
              </>
            )}
          </Grid>
        </Grid>

        {/* --------------------Row 5 Latest------------------ */}

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            // marginBottom: 10,
            borderBottomWidth: 2,
            borderBottomColor: "#707070",
            borderBottomStyle: "solid",
          }}
        >
          {/* --------------------Row 5 box 1------------------ */}

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            style={{
              padding: "5px 5px 0px 5px",
            }}
          >
            {loader14 && loader14 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                    { props.translate("Net Sales Analytics") }
                    
                    <span
                      style={{
                        textAlign: "left",
                        fontWeight: 500,
                        fontSize: 11,
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      
                    { props.translate("SAR") }

                    </span>
                  </Typography>

                  {garageDataType === "name" ? (
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "16px", fontWeight: 700 }}
                    >
                    { props.translate("Vertical Wise") }

                      
                    </Typography>
                  ) : garageDataType === "area" ? (
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "16px", fontWeight: 700 }}
                    >
                    { props.translate("Area Wise") }

                      
                    </Typography>
                  ) : garageDataType === "station" ? (
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "16px", fontWeight: 700 }}
                    >
                    { props.translate("Station Wise") }

                      
                    </Typography>
                  ) : null}

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {garageDataType === "name" && (
                      <div style={{ width: "120px" }}>
                        <Select
                          options={FiltersOption}
                          value={tab5 ? tab5 : "All"} // Set the selected value to
                          onChange={(e) => handleChange("tab5", e)}
                        />
                      </div>
                    )}
                    {(garageDataType === "area" ||
                      garageDataType === "station") && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        onClick={handleNetSalesAnalytics}
                      >
                    { props.translate("Back") }

                        
                      </Button>
                    )}
                  </div>
                </div>
                {garageDataType === "name" ? (
                  <>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={verticalWiseChart}
                    />
                  </>
                ) : garageDataType === "area" ? (
                  <>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={areaWiseChart}
                    />
                  </>
                ) : garageDataType === "station" ? (
                  <>
                    {Object.keys(areaWiseData).length === 0 ? (
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          color: "red",
                        }}
                      >
                    { props.translate("No Data") }

                        
                      </Typography>
                    ) : (
                      <>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={stationWiseChart}
                        />
                      </>
                    )}
                    {/* {areaWiseData && areaWiseData ? 
                   <HighchartsReact highcharts={Highcharts} options={stationWiseChart} /> :
                   <Typography variant="h6" gutterBottom 
                      style={{ fontSize: "16px", fontWeight: 700 , color:"red"}}>
                        No Data
                     </Typography>} */}
                  </>
                ) : null}
              </>
            )}

            {/* {loader11 && loader11 ? (
                <MaintenanceItemEmpty />
                ) : ( <>
                
                  <HighchartsReact highcharts={Highcharts} options={heatMap} constructorType="mapChart" />
                    </>
                )}   */}
          </Grid>

          {/* --------------------Row 5 box 2----------------- */}

          {/* -------------------------------------new --------------------------------------------------------- */}

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            style={{
              padding: "5px 5px 0px 5px",
              borderLeftWidth: "1px",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderTopWidth: xs ? "1px" : "none",
              borderTopColor: xs ? "#707070" : "none",
              borderTopStyle: xs ? "solid" : "none",
            }}
          >
            {loader6b ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                    {props.translate("Collections & Outstanding")}
                    {/* <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 11,
                          alignItems: "center",
                        }}> SAR</span> */}

                    {/* <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}(YTD)</span> */}
                  </Typography>
                  {/* collectionsAndOutStandingData */}
                  <div style={{ display: "flex" }}>
                  

                    <strong> { props.translate("Total") }  : </strong>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {collectionsAndOutStandingData?.toLocaleString() || 0}
                        </Typography>
                      }
                      placement={"bottom-start"}
                      enterDelay={300}
                    >
                      <span>
                        {collectionsAndOutStandingData
                          ? nFormatter(collectionsAndOutStandingData, 2)
                          : 0}
                        <span
                          style={{
                            textAlign: "left",
                            fontWeight: 500,
                            fontSize: 11,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          
                          { props.translate("SAR") }
                        </span>
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={collectionsAndOutStanding}
                />

                {/* <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        // display: "flex",
                        // padding: "10",
                        // justifyContent: "space-between",
                        marginTop: 30,
                      }}
                    >
                        <div style={{ 
                        display: 'flex', justifyContent: 'space-between',
                        fontSize:14, fontWeight:"bold",
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div 
                      style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{props.translate("totalOverdue")}</strong>
                        </div>
                        <div 
                        style={{display:"flex" ,justifyContent:'end'}}>
                       
                            <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = collectionsAndOutStanding?.totalOverDue;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                         
                            </strong>
                        </div>
                      </div>

                      <div style={{ 
                             fontSize:14, fontWeight:"bold",
                        display: 'flex', justifyContent: 'space-between',
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{props.translate("totalCollections")}</strong>
                        </div>
                        <div 
                        style={{display:"flex" ,justifyContent:'end'}}
                        >
                             <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = collectionsAndOutStanding?.totalPaid;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                      // return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                            </strong>
                           
                        </div>
                      </div>
                      
                      <div style={{ 
                             fontSize:14, fontWeight:"bold",
                        display: 'flex', justifyContent: 'space-between',
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{props.translate("paymentBalance")}</strong>
                        </div>
                        <div style={{display:"flex" ,justifyContent:'end'}}>
                            <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = collectionsAndOutStanding?.amountCredited;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      // return formattedAmount;
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                            </strong>
                        </div>
                      </div>
                      <div style={{ 
                             fontSize:14, fontWeight:"bold",
                        display: 'flex', justifyContent: 'space-between',
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{props.translate("totalOutstanding")}</strong>
                        </div>
                        <div style={{display:"flex" ,justifyContent:'end'}}>
                            <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = collectionsAndOutStanding?.totalOutstanding;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                      // return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                            </strong>
                        </div>
                      </div>

                    </Grid> */}
              </>
            )}
          </Grid>

          {/* -------------------------------------new --------------------------------------------------------- */}

          {/* -------------------------------------old --------------------------------------------------------- */}

          {/* <Grid item xs={6} md={6}   
                style={{
                  padding: "5px 5px 0px 5px",
                      borderLeftWidth: "1px",
                      borderLeftColor: "#707070",
                      borderLeftStyle: "solid",
                    }}>
                  {loader6 && loader6 ? (
                        <MaintenanceItemEmpty />
                        ) : ( 
                        <>
                      <div style={{ display: "flex", justifyContent:"space-between" }}>
                      <Typography variant="h6" gutterBottom 
                      style={{ fontSize: "16px", fontWeight: 700 }}
                      >Account Receivable Bucket Analysis</Typography>

                      <div style={{marginBottom:"15px"}}>
                        <strong>Total Account Receivable :</strong>
                        <Tooltip title={
                          <Typography style={{fontSize:"14px",}}>
                            { overDue?.totalOverDue?.toLocaleString()  || 0}
                          </Typography>} placement={"bottom-start"} enterDelay={300}>
                          <span>{overDue?.totalOverDue  ? nFormatter(overDue?.totalOverDue,2): 0}</span>
                        </Tooltip>
                        </div>
                     </div>

                        <HighchartsReact highcharts={Highcharts} options={overDueChart} />
                    </>)}  

                      </Grid> */}

          {/* -------------------------------------old --------------------------------------------------------- */}
        </Grid>

        {/* --------------------Row 6 Latest  ------------------ */}
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            marginBottom: 10,
            borderBottomWidth: 2,
            borderBottomColor: "#707070",
            borderBottomStyle: "solid",
          }}
        >
          {/* --------------------Row 6 box 1 ------------------ */}

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{
              padding: "5px 5px 0px 5px",
              borderRightWidth: xs || sm ? "none" : "1px",
              borderRightColor: xs|| sm ? "none" : "#707070",
              borderRightStyle: xs|| sm ? "none" : "solid",
            }}
          >
            {loader6 && loader6 ? (
              <MaintenanceItemEmpty />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "4px 0px 0px 0px",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontSize: "16px", fontWeight: 700 }}
                  >
                          { props.translate("Receivable Aging Summary") }

                    
                  </Typography>
                  {showAccountsData && (
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "16px", fontWeight: 700 }}
                    >
                      {props.translate(showAccountsTitle)}
                    </Typography>
                  )}
                  <div style={{ margin: "0px 10px 15px 0px" }}>
                    {showAccountsData && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        onClick={handleAccountsBack}
                      >
                        
                        { props.translate("Back") }

                      </Button>
                    )}
                  </div>
                </div>
                {!showAccountsData && (
                  <div style={{ display: "flex" }}>
                    <strong>  { props.translate("Grand Total") }: </strong>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {overDue?.grandTotal?.toLocaleString() || 0}
                        </Typography>
                      }
                      placement={"bottom-start"}
                      enterDelay={300}
                    >
                      <span>
                        {overDue?.grandTotal
                          ? nFormatter(overDue?.grandTotal, 2)
                          : 0}
                        <span
                          style={{
                            textAlign: "left",
                            fontWeight: 500,
                            fontSize: 11,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          
                          { props.translate("SAR") }
                        </span>
                      </span>
                    </Tooltip>
                  </div>
                )}
                {showAccountsData ? (
                  <>
                    {showAccountsLoader && showAccountsLoader ? (
                      <MaintenanceItemEmpty />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={accountsDataChart}
                      />
                    )}
                  </>
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={overDueChart}
                  />
                )}
              </>
            )}
          </Grid>
          {/* --------------------Row 6 box 2 ------------------ */}

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{
              padding: "5px 5px 0px 5px",
              borderTopWidth: xs|| sm ? "1px" : "none",
              borderTopColor: xs|| sm ? "#707070" : "none",
              borderTopStyle: xs|| sm ? "solid" : "none",
            }}
          >
            {loader7 && loader7 ? (
              <MaintenanceItemEmpty />
            ) : (
              <ul className="md-bar-graphlist">
                {/* {!showClientList ? ( */}

                {/* <div   style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems:"center"
            }}>
              <div>
              <strong>Total Outstanding : </strong>
              <Tooltip title={<Typography style={{fontSize:"14px",}}>
                        { outstandingSummary?.totalOutstanding?.toLocaleString()  || 0}
                      </Typography>} placement={"bottom-start"} enterDelay={300}>
                <span>{outstandingSummary?.totalOutstanding  ? nFormatter(outstandingSummary?.totalOutstanding,2): 0}</span>
              </Tooltip>  
              </div>
              <Button onClick={() => setShowClientList(true)}>Client List</Button>
            </div> */}
                {/* ) : ( */}
                <div>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "16px", fontWeight: 700 }}
                    >
                      { props.translate("Client Outstanding Amounts") }
                      
                    </Typography>

                    {/* <Button onClick={() => setShowClientList(false)}>Show Outstanding</Button> */}
                    <div style={{ margin: "0px 14px 0px 0px" }}>
                      <strong> { props.translate("Total Outstanding") }  : </strong>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "14px" }}>
                            {outstandingSummary?.totalOutstanding?.toLocaleString() ||
                              0}
                          </Typography>
                        }
                        placement={"bottom-start"}
                        enterDelay={300}
                      >
                        <span>
                          {outstandingSummary?.totalOutstanding
                            ? nFormatter(
                                outstandingSummary?.totalOutstanding,
                                2
                              )
                            : 0}
                          <span
                            style={{
                              textAlign: "left",
                              fontWeight: 500,
                              fontSize: 11,
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            
                            { props.translate("SAR") }
                          </span>
                        </span>
                      </Tooltip>
                    </div>
                  </div>

                  <div style={{ margin: "10px 0px" }}>
                    <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height: "360px",
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div
                              {...restProps}
                              ref={elementRef}
                              id="scrollableDiv"
                            />
                          );
                        },
                      }}
                    >
                      {outstandingSummary &&
                        Object.entries(outstandingSummary.list)
                          .sort(([, a], [, b]) => b - a)
                          .map(([name, balance], index) => (
                            <Tooltip
                              title={
                                <Typography key={index}>
                                 { props.translate("sharedName")} : {name} <br />
                                 { props.translate("totalBalance")}:{" "}
                                  {Number(balance).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  &nbsp;&nbsp; { props.translate("SAR")}   <br />
                                </Typography>
                              }
                              placement={"bottom-start"}
                              enterDelay={300}
                            >
                              <li>
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      // background:"#E0E0E0",
                                      // padding:"2px 10px",
                                      background: "#00c179",
                                      color: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        whiteSpace: "nowrap",
                                        // overflow: "hidden !important",
                                        overflow: "hidden",
                                        padding: "3px 10px",
                                        color: "#fff",
                                        width: "65%",
                                        background: "#00c179",
                                        maxWidth: "65%",
                                        borderRadius: "5px 0 0 5px",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {name}
                                    </span>
                                    <span
                                      style={{
                                        padding: "3px 10px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {(() => {
                                        try {
                                          const integerAmount =
                                            Math.floor(balance);
                                          const formattedAmount =
                                            integerAmount.toLocaleString();
                                          return formattedAmount;
                                        } catch (error) {
                                          console.error("Error:", error);
                                          return "Error";
                                        }
                                      })()}
                                    </span>
                                  </div>
                                  {/* <LinearProgress
                  style={{ height: 11 }}
                  className="success"
                  variant="determinate"
                  value={0} 
                /> */}
                                </>
                              </li>
                            </Tooltip>
                          ))}
                    </Scrollbar>
                  </div>
                </div>
                {/* )} */}
              </ul>
            )}
          </Grid>

          {/* --------------------Row 6 box 3 ------------------ */}

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{
              padding: "5px 5px 0px 5px",
              borderLeftWidth: "1px",
              borderLeftColor: "#707070",
              borderLeftStyle: "solid",
              borderTopWidth: xs|| sm ? "1px" : "none",
              borderTopColor: xs|| sm ? "#707070" : "none",
              borderTopStyle: xs|| sm? "solid" : "none",
            }}
          >
            {loader11 && loader11 ? (
              <MaintenanceItemEmpty />
            ) : (
              <ul className="md-bar-graphlist">
                <div>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "16px", fontWeight: 700 }}
                    >
                      { props.translate("Vehicle Service Utilization by Station")} 
                     
                    </Typography>

                    <div style={{ margin: "0px 14px 0px 0px" }}>
                      <strong>   { props.translate("Total Locations")} : </strong>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "14px" }}>
                            {(topologyData && topologyData.length) || 0}
                          </Typography>
                        }
                        placement={"bottom-start"}
                        enterDelay={300}
                      >
                        {/* <span>{outstandingSummary?.totalOutstanding  ? nFormatter(outstandingSummary?.totalOutstanding,2): 0}</span> */}
                        <span>
                          {(topologyData && topologyData.length) || 0}
                        </span>
                      </Tooltip>
                    </div>
                  </div>

                  <div style={{ margin: "10px 0px" }}>
                    <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height: "360px",
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div
                              {...restProps}
                              ref={elementRef}
                              id="scrollableDiv"
                            />
                          );
                        },
                      }}
                    >
                      {/* {outstandingSummary && */}
                      {/* Object.entries(outstandingSummary.list).sort(([, a], [, b]) => b - a).map(([name, balance], index) => ( */}
                      {topologyData?.map((item) => (
                        <Tooltip
                          title={
                            <Typography key={item.garageId}>
                             { props.translate("stationName")} {""}: {item.stationName} <br />
                             { props.translate("area")} {""}: {item.area} <br />
                             { props.translate("city")} {""}: {item.city} <br />
                             { props.translate("type")} {""}: {item.type} <br />
                             { props.translate("Total Vehicles")} {""}: {item.servedCount} <br />
                              {/* {"Total Sales"}: {item.totalSale}  {" "} SAR<br /> */}
                             { props.translate("Total Revenue")} {""}:{" "}
                              {item.totalSale
                                ? Number(item.totalSale).toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : 0}{" "}
                              &nbsp;&nbsp; { props.translate("SAR")}  <br />
                            </Typography>
                          }
                          placement={"bottom-start"}
                          enterDelay={300}
                        >
                          <li>
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  // background:"#E0E0E0",
                                  background: "#00c179",
                                  color: "#fff",

                                  // padding:"2px 10px",
                                  borderRadius: "5px",
                                }}
                              >
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    // overflow: "hidden !important",
                                    overflow: "hidden",
                                    padding: "3px 10px",
                                    color: "#fff",
                                    background: "#00c179",
                                    maxWidth: "65%",
                                    width: "65%",
                                    borderRadius: "5px 0 0 5px",

                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {/* {item.area} {" "} ({item.city})  */}
                                  {item.stationName} &nbsp;&nbsp;&nbsp;&nbsp; (
                                  {item.city})
                                </span>
                                <span
                                  style={{
                                    padding: "3px 10px",
                                    fontWeight: "600",
                                  }}
                                >
                                  <span>
                                    {(() => {
                                      try {
                                        const integerAmount = Math.floor(
                                          item.servedCount
                                        );
                                        const formattedAmount =
                                          integerAmount.toLocaleString();
                                        return formattedAmount;
                                      } catch (error) {
                                        console.error("Error:", error);
                                        return "Error";
                                      }
                                    })()}
                                  </span>
                                </span>
                              </div>
                              {/* <LinearProgress
                  style={{ height: 11 }}
                  className="success"
                  variant="determinate"
                  value={0} 
                /> */}
                            </>
                          </li>
                        </Tooltip>
                      ))}
                    </Scrollbar>
                  </div>
                </div>
                {/* )} */}
              </ul>
            )}
          </Grid>

        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)((Dashboard));
