import React, { Component } from "react";
import { connect } from "react-redux";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import { ReportsNotificationList } from "../../Components/ScheduleRepotsNotifications/Index";
import instance from "../../axios";
import moment from "moment";
import { errorHandler } from "../../Helpers";
let serverTimeZoneName = "Asia/Dubai";


const newForm = {
    id: 0,
    attributes: {
        rpTmplId: 1,
        reportDuration: 0,
        deviceIds: [
            1
        ],
        itemGroupIds: [],
        vehicleIds: [],
        driverIds: [],
        geofenceIds: [],
        itemGroupType: "",
        notificationIds: [],
        sensors: [],
        sensorValues: [
            {
                input: "",
                index: "",
                value: ""
            }
        ],
        types: [],
        geoTimeThreshold: 60000,
        mailTo: "user",
        selectedJobDate: "custom",
           controlDays: [],
        controlTimeStart: "00:00",
        controlTimeEnd: "23:59"
    },
    description: "",
    start: null,
    end: null,
    triggerTime: moment('03:30', 'HH:mm').toISOString(),
    period: "once",
    inProgress: false,
    days: "",
    type: " ",
    status: true,
    lastExecutedLog: "",
    lastExecuted: ""
}
const jobDataFormat = (data, attributes) => ({
  id: data.id ? data.id : 0,
  attributes: { ...attributes },
  description: data.description,
  start: data.start,
  end: data.end,
  triggerTime: data.triggerTime,
  period: data.period,
  inProgress: data.inProgress,
  days: data.period && data.period !== 'once' ? data.days.toString() : '',
  type: data.type,
  status: data.status,
  lastExecutedLog: data.lastExecutedLog,
  lastExecuted: data.lastExecuted
})
class ScheduleReportsAndNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      notificationsList:[],
      openForm:false,
      editForm:false,
      enableDelete:false,
      selectedItem:'', 
      form: { ...newForm },
      controlTime: [0, 1439],
      enableSubmit:false,
      reportDuration: '',
      selectedDate:''
    };
  }

  
  checkRequiredFields =()=>{
    const {type} = this.state.form;
    const {controlDays, controlTimeStart, controlTimeEnd } = this.state.form.attributes;
    if(type ){
      // if(type && controlDays.length>0  && controlTimeStart && controlTimeEnd){
          this.setState({enableSubmit:true})
    }else{
         this.setState({enableSubmit:false})  
    }
  }
  onDayChange3 = (checked, obj) => {
    let dayArry = []
    if (checked) {
      dayArry = (this.state.form.attributes.controlDays || []).concat(
        parseInt(obj.id)
      )
    } else {
      dayArry = (this.state.form.attributes.controlDays || []).filter(
        day => day !== parseInt(obj.id)
      )
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          attributes: { ...this.state.form.attributes, controlDays: dayArry }
        }
      },
      () => this.checkRequiredFields()
    )
  }
  openCreateForm=()=>{
    this.setState({openForm:true,  editForm:false, form: { ...newForm }})
  }
  closeForm=()=>{
    this.setState({openForm:false, editForm:false, form: { ...newForm }})
  } 
  openEditForm=(e)=>{

    this.setState({editForm:true, openForm:false, form:e})
  }
  enableDeleteForm=(e)=>{

    this.setState({enableDelete:true  ,openForm:false, editForm:false, form:e , enableSubmit:false})
  }
  cancleDelete=(e)=>{
    this.setState({enableDelete:false  ,openForm:false, editForm:false})
  }

  getNotifications = () => {
    let apiUrl  = `/api/jobs`
    instance({
      method: "GET",
      url:apiUrl ,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
          this.setState({notificationsList:res&& res || []})
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };
 
  handleTimeControl = (event, controlTime) => {
    this.setState({ controlTime,
        form:{
          ...this.state.form,
          attributes:{
            ...this.state.form.attributes,
            controlTimeStart: moment()
            .startOf('day')
            .add(controlTime[0], 'minutes')
            .format('HH:mm'),
          controlTimeEnd: moment()
            .startOf('day')
            .add(controlTime[1], 'minutes')
            .format('HH:mm')
          }
        }
    },()=>{
      this.checkRequiredFields()
    })
  }
  componentDidMount() {
    // const { controlTimeStart, controlTimeEnd, } = this.state.form.attributes

    this.getNotifications();
    // this.setState(
    //   {
    //     controlTime: [
    //       moment
    //         .duration(
    //           controlTimeStart || '00:00'
    //         )
    //         .asMinutes(),
    //       moment
    //         .duration(
    //           controlTimeEnd || '23:59'
    //         )
    //         .asMinutes()
    //     ]
    //   })
  }
  deleteNotification= async ()=>{
    let { form } = this.state
    let url  = `/api/jobs/${form.id}`
    await instance({
      url: url,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...form
      }
    })
        .then(res => {

        this.setState({enableDelete:false},()=>this.getNotifications())
       
          toast.success(
           this.props.translate('alertDeleted')
        )
      }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  handleSubmit =async()=>{
    let { form } = this.state
    let obj  = jobDataFormat(form, form.attributes)
    let url  = this.state.editForm ? `/api/jobs/${form.id}` :  `/api/jobs`
    await instance({
      url: url,
      method:this.state.editForm ? 'PUT':'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
        .then(res => {
        this.setState({openForm:false},()=>this.getNotifications())
       
          toast.success(
            this.state.editForm ? this.props.translate('alertUpdated') :  this.props.translate('alertCreated')
        )
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }

    handleChange =(name, event)=>{
      if (name === 'start' || name === 'end' || name === 'triggerTime') {
          let userTimezone = 'Asia/Riyadh'
         userTimezone = this.props.logInUser?.attributes?.timezone || this.props.ServerSetting?.attributes?.timezone;
        moment.tz.setDefault(userTimezone);
      
        const selectedTime = moment(event.target.value, 'HH:mm');
      
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: selectedTime.toISOString(),
            },
          },
          () => this.checkRequiredFields()
        );
    
      }else{
      let value  = event.target.value

      this.setState({
          form : {
            ...this.state.form,
            [name]:value
          }
      },()=>  { 
        this.checkRequiredFields()
      })
    }
    }

    handleSelectedJobDate = selectedJobDate => {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes:{
              ...this.state.form.attributes,
                 selectedJobDate:selectedJobDate
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
    showJobDates (from, to) {
      let timezone = "Asia/Riyadh"
      if (
        this.props?.logInUser &&
        this.props?.logInUser?.attributes &&
        this.props?.logInUser?.attributes?.timezone
      ) {
        timezone = this.props.logInUser.attributes.timezone
      }
      if (
        this.props?.ServerSetting &&
        this.props?.ServerSetting?.attributes &&
        this.props?.ServerSetting?.attributes?.timezone
      ) {
        timezone = this.props?.ServerSetting?.attributes?.timezone
      }
      moment.tz.setDefault(timezone)
      this.setState(
        {
          form: {
            ...this.state.form,
            start: from
              ? moment(from.format('YYYY-MM-DD HH:mm')).toISOString()
              : from,
            end: to ? moment(to.format('YYYY-MM-DD HH:mm')).toISOString() : to
          }
        },
        () => {
          this.checkRequiredFields()
        }
      )
    }
    handleChangePeriod = value => {
      let days = '1'
      if (value === 'daily') days = ['1', '2', '3', '4', '5', '6', '7']
      this.setState(
        {
          form: {
            ...this.state.form,
            period: value,
            days
          }
        },
        () => this.checkRequiredFields()
      )
    }

    onDayChange = (obj) => {
      let dayArry = []
      if ((this.state.form.days || []).includes(obj.id)) {
        dayArry = (this.state.form.days || []).filter(day => parseInt(day) !== parseInt(obj.id))
      } else {
        dayArry = (this.state.form.days || []).concat(obj.id)
      }
      // const daysString = dayArry.join(',');
      this.setState(
        {
          form: {
            ...this.state.form,
            days: dayArry
          }
        },
        () => this.checkRequiredFields()
      )
    }
    onDayChange2 = (name, value) => {
      if (name === 'reportDuration') {
        let val = parseInt(value) || ''
        if (val && val < 1) {
          val = 1
        }
        if (val && val > 90) {
          val = 90
        }
        this.setState(
          {
            reportDuration: val
          },
          () => this.checkReportRequiredFields()
        )
      } else {
        let val = parseInt(value) || ''
        if (val && val < 1) {
          val = 1
        }
        if (val && val > 31) {
          val = 31
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              days: val
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }

    
  showDates = (e, date) => {
  
    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
  
    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    // Determine time format
    
  
    // Update state with selected date range and formatted dates
    this.setState({
      form: {
        ...this.state.form,
            attributes: { 
                ...this.state.form.attributes, 
                    start:formatedDate.from,
                    end:formatedDate.to
                  }
       },
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    },()=>this.checkRequiredFields());
  };
  render() {

    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    if (this.props.logInUser) {
      return (
        <Layout   
        {...this.state}
        {...this.props}
        openCreateForm={this.openCreateForm}
        openEditForm={this.openEditForm}
        closeForm={this.closeForm}
        enableDeleteForm={this.enableDeleteForm}
        // noSidebar
       >
          <ReportsNotificationList
               {...this.state}
               {...this.props}
               openCreateForm={this.openCreateForm}
               openEditForm={this.openEditForm}
               closeForm={this.closeForm}
               cancleDelete={this.cancleDelete}
               enableDeleteForm={this.enableDeleteForm}
               deleteNotification={this.deleteNotification}
               handleTimeControl={this.handleTimeControl}
               handleSubmit={this.handleSubmit}
               onDayChange2={this.onDayChange2}
               onDayChange3={this.onDayChange3}
               handleChange={this.handleChange}
               handleSelectedJobDate={this.handleSelectedJobDate}
               showJobDates={this.showJobDates}
               handleChangePeriod={this.handleChangePeriod}
               onDayChange={this.onDayChange}
               showDates={this.showDates}
          />
         
        </Layout>
      );
    } else {
      fetch("/api/session", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                
                  toast.error(
                     this.props.translate(err[1])
                )
                this.props.navigate('/login')
              }
            })
          } else {
            this.props.navigate('/login')
            throw response
          }
        })
        .catch((e) => {

            toast.error(
              "somethingWentWrong"
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)((ScheduleReportsAndNotifications));
