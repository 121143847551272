import React, { Component } from "react";
import axios from "axios";
import Moment from "moment";
import { getCoupon } from "../../Actions/Coupon";
import Table from "../common/tableWithBackEndPagination";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkPrivileges, PaginationConfig } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import { connect } from "react-redux";
import Loader from "../../Layout/Loader";
import AddCoupon from "./addCoupon";
import isEqual from "react-fast-compare";
import Button from "./../common/Button";
import { Grid } from "@mui/material";
import TextField from "../common/TextField";

let source;
const formDefaultState = {
  couponCode: "",
  usageLimit: "",
  usageCount: "",
  expirationTime: null,
};
const dataFormat = (data, id) => ({
  id: id && id ? id : 0,
  couponCode: data && data.couponCode ? data.couponCode + "".trim() : 0,
  usageLimit: parseInt(data && data.usageLimit ? data.usageLimit : 0),
  usageCount: parseInt(data && data.usageCount ? data.usageCount : 0),
  expirationTime:
    data && data.expirationTime ? Moment(data.expirationTime).toISOString() : 0,
});
class couponModal extends Component {
  constructor() {
    super();
    this.state = {
      ...PaginationConfig,
      selectedCoupon: "",
      addOption: false,
      defaultCouponList: false,
      onDeleteConfirmation: false,
      tableData: [],
      pagData: "",
      form: {
        ...formDefaultState,
      },
      isVisableCreateBtn: false,
      isVisableCouponBtnforEdit: false,
      editOption: false,
    };
    this.removeCoupon = this.removeCoupon.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.ag_submit = this.ag_submit.bind(this);
    this.couponSelection = this.couponSelection.bind(this);
    this.ug_submit = this.ug_submit.bind(this);
    this.modalControle = this.modalControle.bind(this);
  }
  componentWillMount() {
    this.getCouponData();
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
        // this.props.dispatch(toast.removeAll());

  }
  getCouponData = () => {
    let { page, pageSize, itemSearch } = this.state;
    if (this.props.logInUser.id) {
      let apiCall = `/api/coupons/get?page=${page}&limit=${pageSize}&search=${itemSearch}`;
      source = axios.CancelToken.source();
      axios
        .get(apiCall, {
          cancelToken: source.token,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === "success") {
            if (res.data.data) {
              let udata = res.data.data;
              this.props.dispatch(getCoupon(udata));
              let coupons = udata.map((u) => {
                if (u.expirationTime) {
                  return {
                    ...u,
                    expirationTime: this.props.logInUser.twelveHourFormat
                      ? Moment(u.expirationTime).format("YYYY-MM-DD ")
                      : Moment(u.expirationTime).format("YYYY-MM-DD "),
                  };
                } else {
                  return { ...u };
                }
              });
              this.setState({
                tableData: coupons,
                defaultCouponList: true,
                pagData: response.data.data,
              });
            } else {
              this.setState({
                // tableData: [],
                defaultCouponList: true,
                pagData: "",
              });
            }
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
              toast.error( this.props.translate(err[1])
            );
          }
          // }
        })
        .catch((e) => {
          console.log("Request canceled = ", e);
        });
    }
  };

  removeCoupon(selected) {
    if (selected[0]) {
      this.setState({
        onDeleteConfirmation: true,
        selectedCoupon: selected[0],
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        directDelete: false,
        selectedCoupon: selected,
      });
    }
  }
  deleteItem = () => {
    let { selectedCoupon } = this.state;
    if (selectedCoupon && selectedCoupon.id) {
      fetch(`/api/coupons/${selectedCoupon.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response.json().then((res) => {
            if (res.status === "success") {
              this.getCouponData();
                toast.success( this.props.translate("deletedSuccessfully")
              );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
    
                toast.error( this.props.translate(err[1])
              );
            } else {
              throw response;
            }
          });
        })
        .catch((e) => {
 
            toast.error(this.props.translate("somthingWentWrongMessage")
         
          );
        });
      this.setState({
        form: "",
        onDeleteConfirmation: false,
        addOption: false,
        defaultCouponList: true,
      });
    }
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        pageSize: value,
      },
      () => this.getCouponData()
    );
  };
  handleChangePage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: value,
        //defaultCouponList: false
      },
      () => this.getCouponData()
    );
  };
  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        page: 1,
        itemSearch: searchValue,
      },
      () => this.getCouponData()
    );
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  setPagination = (user) => {
    if (this.state.pagData) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = this.state.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  addCoupon() {
    this.setState({
      selectedCoupon: "",
      editOption: false,
      addOption: true,
      form: "",
      defaultCouponList: false,
    });
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    if (name === "expirationTime") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        (preState) => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: target.value,
          },
        }),
        () => this.checkRequiredFields()
      );
    }
  };
  setExpirationTime = (e) => {
    e.stopPropagation();
    if (this.state.form.expirationTime) {
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  checkRequiredFields() {
    let { couponCode, usageCount, usageLimit, expirationTime } =
      this.state.form;
    let changed = !isEqual(this.state.form, this.state.selectedCoupon);
    if (couponCode?.trim() && expirationTime) {
      this.setState({
        isVisableCouponBtn: true,
      });
      if (changed) {
        this.setState({
          isVisableCouponBtnforEdit: true,
        });
      }
    } else {
      this.setState({
        isVisableCouponBtn: false,
        isVisableCouponBtnforEdit: false,
      });
    }
  }

  ag_submit() {
    // let date = Moment(this.state.form.expirationTime).format('YYYY-MM-DDTHH:mm.000+00:00')
    const obj = dataFormat(this.state.form);
    fetch("/api/coupons", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...obj,
      }),
    })
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success") {
     
              toast.success( this.props.translate("createdSuccessfully")
              
            );
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
 
              toast.error( this.props.translate(err[1])
                
            );
          } else {
            throw response;
          }
        });
      })
      .catch((e) => {
        toast.error( this.props.translate("somethingwentWrong"));
      });
    this.setState(
      {
        addOption: false,
        form: "",
        defaultCouponList: true,
      },
      () => this.getCouponData()
    );
  }

  modalControle() {
    this.setState({
      addOption: false,
      editOption: false,
      selectedCoupon: "",
      defaultCouponList: true,
      isVisableCouponBtn: false,
      isVisableCouponBtnforEdit: false,
      form: "",
    });
    // if (this.props.onCloseModal) {
    //     this.props.onCloseModal()
    // }
  }
  couponSelection(item) {
    if (item) {
      this.setState({
        editOption: true,
        selectedCoupon: item,
        defaultCouponList: false,
        selectedOption: true,
        directOption: false,
        form: { ...item },
      });
    }
  }

  ug_submit() {
    let itemId = this.state.selectedCoupon.id;
    const obj = dataFormat(this.state.form, itemId);
    fetch(`/api/coupons/${itemId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...obj,
      }),
    })
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success") {
    
              toast.success( this.props.translate("updatedSuccessfully")
            );
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
      
              toast.error( this.props.translate(err[1])
            );
          } else {
            throw response;
          }
        });
      })
      .catch((e) => {
        toast.error( this.props.translate("somethingwentWrong"));
      });
    this.setState(
      {
        addOption: false,
        editOption: false,
        form: "",
        defaultCouponList: true,
      },
      () => this.getCouponData()
    );
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        {this.state.addOption && (
          <AddCoupon
            group={this.state.tableData}
            selectedCoupon={this.state.selectedCoupon}
            formSubmit={this.ag_submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            modalControle={this.modalControle}
            buttonText={this.props.translate("sharedCreate")}
            isVisableCreateBtn={this.state.isVisableCouponBtn}
            itemAttributes={this.state.itemAttributes}
            translate={this.props.translate}
          />
        )}

        {this.state.editOption && (
          <AddCoupon
            selectedCoupon={this.state.selectedCoupon}
            formSubmit={this.ug_submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            modalControle={this.modalControle}
            buttonText={this.props.translate("update")}
            isVisableCreateBtn={this.state.isVisableCouponBtnforEdit}
            itemAttributes={this.state.itemAttributes}
            translate={this.props.translate}
          />
        )}
        {this.state.defaultCouponList ? (
          <>
            <Grid container className="breadcrumb-row">
              <Grid item xs={12} sm={4}>
                <h3 style={{ margin: 0 }}>{this.props.translate("coupon")}</h3>
              </Grid>
              <Grid item xs={12} sm={8} style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    onChange={(e) => this.SearchItem(e.target.value)}
                  />
                </div>
                <Button
                  disabled={false}
                  aria-label="Create"
                  size="small"
                  color="inherit"
                  onClick={() => this.addCoupon()}
                  id="createButton"
                >
                  {this.props.translate("create")}
                </Button>
              </Grid>
            </Grid>
            <Table
              rows={this.state.tableData || []}
              pagination={this.setPagination(this.state.pagData)}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              SearchItem={this.SearchItem}
              onDelete={this.removeCoupon}
              themecolors={this.props.themecolors}
              isRowIcons={false}
              isCursoPointer
              filterDropdown
              setSerialNo
              btnName={this.props.translate("create")}
              rowDefinition={rowDefinitionR(this.props.translate)}
              hasAccessOfDelete={checkPrivileges("accountDelete")}
              isEditable={true}
              onClick={this.couponSelection}
              coupon
            />
          </>
        ) : (
          !this.state.addOption && !this.state.editOption && <Loader />
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteItem}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.selectedCoupon.name}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    trash: state.trash,
  };
};
const mapStateToProps = connect(mapState);
export const ShowCoupon = mapStateToProps((couponModal));

const rowDefinitionR = (translate) => {
  if (localStorage.getItem("adminToken")) {
    return [
      {
        id: "couponCode",
        numeric: false,
        disablePadding: false,
        label: translate("description"),
      },
      {
        id: "usageLimit",
        numeric: false,
        disablePadding: false,
        label: translate("usageLimit"),
      },
      {
        id: "usageCount",
        numeric: false,
        disablePadding: false,
        label: translate("usageCount"),
      },
      {
        id: "expirationTime",
        numeric: false,
        disablePadding: false,
        label: translate("expiration"),
      },
    ];
  } else {
    return [
      {
        id: "couponCode",
        numeric: false,
        disablePadding: false,
        label: translate("description"),
      },
      {
        id: "usageLimit",
        numeric: false,
        disablePadding: false,
        label: translate("usageLimit"),
      },
      {
        id: "usageCount",
        numeric: false,
        disablePadding: false,
        label: translate("usageCount"),
      },
      {
        id: "expirationTime",
        numeric: false,
        disablePadding: false,
        label: translate("expiration"),
      },
    ];
  }
};
