import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { checkPrivileges, errorHandler } from "../../Helpers";
import { FormHelperText, Grid } from "@mui/material";
// import Table from "../common/TableServices";
import Button from "../common/Button";
import TextField from "../common/TextField";
import ConfirmDialoag from "../common/ConfirmDialoag";
import ExportImportMenu from "../common/ExportImportMenu";
import MenuItem from "../common/MenuItem";
import instance from "../../axios";
import TableWithBackEndPagination from "../common/tableWithBackEndPagination";


const statusTypes = [
  {
    key: "packages",
    name: "packages",
  },
  {
    key: "services",
    name: "services",
  },
  {
    key: "items",
    name: "items",
  },
];
class ContractModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisable: false,
      loader: false,
      createSubType: false,
      serviceForm: {
        expenseType: this.props.selectedExpenseType?.name === "packages" || 
                     this.props.selectedExpenseType?.name === "services" || 
                     this.props.selectedExpenseType?.name === "items" ? 
                     this.props.selectedExpenseType?.name : "",
        expensetypeId: this.props?.selectedServiceTypeId,
        attributes:{
          quantityPriceScale: [{quantity:"",price:""}],
        }
      },
      // serviceForm: {
      //   expensetypeId: this.props?.selectedServiceTypeId,
      //   attributes: {
      //     quantityPriceScale: [],
      //   },
      // },
      mode: "create",
      isVisableSubmitBtn: true,
      onDeleteConfirmation: false,
      rowsPerPage: 5,
      page: 1,
      pageSize: 5,
      enteredQuantities: [0],
      duplicateQuantityError: false,
      quantityError: "", // Initialize to empty string
      quantityHelperText: "", 
      deleteRow:false
    };
  }



  handleChange = (name, value) => {
    if (name === 'vatPercent') {
      if (value < 0) {
        value = 0;
      }
      else if (value > 100) {
        value = 100;
      }
    }
    // || name === "subTypeNum"
    if(name === "unitPrice"|| name === "purchasePrice" ){
      if(value >=0){
        value = parseFloat(value);
        this.setState(
          {
            serviceForm: {
              ...this.state.serviceForm,
              [name]: value,
              expensetypeId: this.props?.selectedServiceTypeId,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }else {
      this.setState(
        {
          serviceForm: {
            ...this.state.serviceForm,
            [name]: value,
            expensetypeId: this.props?.selectedServiceTypeId,
          },
        },
        () => this.checkRequiredFields()
      );
      }
  };

  checkRequiredFields = () => {
    let { name, label, purchasePrice, contractNumber ,subTypeNum, expenseType} = this.state.serviceForm
    let { typeSelection,quantityError } = this.state
    if(typeSelection === "createType" || typeSelection === "updateType"){
      if(name && contractNumber &&subTypeNum && expenseType){
        this.setState({
          isVisableSubmitBtn: false
        })
      }
      else{
        this.setState({
          isVisableSubmitBtn: true
        })
      }
    }
    else{
      if (label && subTypeNum &&  !quantityError && expenseType) {
        this.setState({
          isVisableSubmitBtn: false
        })
      } else {
        this.setState({
          isVisableSubmitBtn: true
        })
      }
    }
  }

  submitForm = () => {
    let url =
      this.state.mode === "create"
        ? `/api/expensetypes/subtype`
        : `/api/expensetypes/subtype/${this.state.serviceForm.id}`;
  
        let updatedPayload
        if (Object.keys(this.state.serviceForm.attributes.quantityPriceScale).length === 0) {
          updatedPayload = {
            ...this.state.serviceForm,
            attributes: {
              // quantityPriceScale: { NaN: null },
              quantityPriceScale: { 0: 0 },
            },
          };
        }else{
          updatedPayload = {
            ...this.state.serviceForm,
            attributes: {
              quantityPriceScale: this.state.serviceForm?.attributes?.quantityPriceScale?.reduce((acc, item) => {
                acc[item.quantity] = item.price;
                return acc;
              }, {}),
            },
          };
        }
        // Convert empty strings to 0 and ensure values are numbers
          Object.keys(updatedPayload.attributes.quantityPriceScale).forEach((key) => {
            if (updatedPayload.attributes.quantityPriceScale[key] === '') {
              updatedPayload.attributes.quantityPriceScale[key] = 0;
            } else {
              updatedPayload.attributes.quantityPriceScale[key] = parseFloat(updatedPayload.attributes.quantityPriceScale[key]);
            }
          });
  
          // Convert the keys to numbers (if they are not already) and handle NaN keys
          updatedPayload.attributes.quantityPriceScale = Object.keys(updatedPayload.attributes.quantityPriceScale).reduce((acc, key) => {
            const numericKey = parseFloat(key);
            acc[Number.isNaN(numericKey) ? 0 : numericKey] = updatedPayload.attributes.quantityPriceScale[key];
            return acc;
          }, {});

        fetch(url, {
          method: this.state.mode === "create" ? "POST" : "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedPayload),
        })
          .then((response) => {
            // console.log("Res====",response);
            if (response.ok) {
              response.json().then((res) => {
                // console.log("Res=====",res);
              this.setState({  serviceForm: {
                expensetypeId: this.props?.selectedServiceTypeId,
                attributes:{
                  quantityPriceScale: [{quantity:"",price:""}],
                }
              }, createSubType: false }, () => {
                // console.log("this.props.selectedServiceTypeId=====", this.props.selectedServiceTypeId);
                this.props.selectService(this.props.selectedExpenseType);
              });

              if (res.status === "success") {

                toast.success(
                    this.state.mode === "create"
                      ? this.props.translate("subTypeCreated")
                      : this.props.translate("subTypeUpdated")
              )
            }
            else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
           
                  toast.error( this.props.translate(err[1])
                )
              } else {
                throw response;
              }


              })
            }
          })
          .catch((error) => {
            console.log("error ==", error);
          });
  };
  

  closeEditForm = () => {
    this.setState({ createSubType: false,
       serviceForm: {
      expensetypeId: this.props?.selectedServiceTypeId,
      attributes:{
        quantityPriceScale: [{quantity:"",price:""}],
      } }});
  };

  openCreateSubTypeFrom = () => {
    this.setState({
      mode: "create",
      createSubType: true,
      serviceForm: {
        expenseType: this.props.selectedExpenseType?.name === "packages" || 
                     this.props.selectedExpenseType?.name === "services" || 
                     this.props.selectedExpenseType?.name === "items" ? 
                     this.props.selectedExpenseType?.name : "",
        expensetypeId: this.props?.selectedServiceTypeId,
        attributes:{
          quantityPriceScale: [{quantity:"",price:""}],
        }
      },
      // serviceForm: {
      //   ...this.state.serviceForm,
      //   id: "",
      //   label: "",
      //   parentId: "",
      //   purchasePrice: "",
      //   subTypeGroup: "",
      //   subTypeNum: "",
      //   expenseType:this.state.serviceForm.expenseType,
      //   unitPrice: "",
      // },
    });
  };

 

  onEdit = (items) => {
    const item = items[0]
    
    const quantityPriceScaleArray = item?.attributes?.quantityPriceScale  ? Object.entries(item.attributes.quantityPriceScale).map(([quantity, price]) => ({
      quantity: parseFloat(quantity),
      price: parseFloat(price),
    })) : [{quantity:"",price:""}];
  
    const updatedItem = {
      ...item,
      attributes: {
        quantityPriceScale: quantityPriceScaleArray,
      },
    };
  
  
    this.setState({
      serviceForm: updatedItem,
      createSubType: true,
      mode: "update",
    });
  };
  

  onDelete = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      serviceForm: item[0],
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
  onCancel1 = () => {
    this.setState({
      deleteRow: false,
    });
  };

  removeSubType = (item) => {
    if (this.state.serviceForm && this.state.serviceForm.id) {
    instance({
      url: `/api/expensetypes/subtype/${this.state.serviceForm.id}`,
      method: 'DELETE',
    })
    .then((response) => {

    this.setState({ 
      serviceForm: {
      expensetypeId: this.props?.selectedServiceTypeId || "",
      attributes:{
        quantityPriceScale: [{quantity:"",price:""}],
      } } ,
      onDeleteConfirmation: false }, () => {
      this.props.selectService(this.props.selectedExpenseType);
    });
  
      toast.success( this.props.translate("subTypeDeleted")
    );
    }).catch(error => {errorHandler(error,this.props.dispatch)})
  }
}

  // removeSubType = () => {
  //   if (this.state.serviceForm && this.state.serviceForm.id) {
  //     fetch(`/api/expensetypes/subtype/${this.state.serviceForm.id}`, {
  //       method: "DELETE",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           this.setState({ 
  //             serviceForm: {
  //             expensetypeId: this.props?.selectedServiceTypeId || "",
  //             attributes:{
  //               quantityPriceScale: [{quantity:"",price:""}],
  //             } } ,
  //             onDeleteConfirmation: false }, () => {
  //             this.props.selectService(this.props.selectedServiceTypeId, true);
  //           });
  //           this.props.dispatch(
  //             toast.success({
  //               message: this.props.translate("subTypeDeleted"),
  //               autoDismiss: 5,
  //               location: this.props.location,
  //             })
  //           );
  //         }
  //       })
  //       .catch((er) => {
  //         console.log("err ===", er);
  //       });
  //   }
  // };

  handleChangeRowsPerPage = (value) => {
    this.setState({
      rowsPerPage: value,
    });
  };

  handleChangePage = (value) => {
    this.setState({
      page: value,
    });
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  setPagination = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pageSize = this.state.pageSize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };



  handleAddMore = () => {
    const { serviceForm } = this.state;
    const { quantityPriceScale } = serviceForm.attributes;
  
    const updatedQuantityPriceScale = [...quantityPriceScale, { quantity: "", price: "" }];
  
    const updatedForm = {
      ...serviceForm,
      attributes: {
        ...serviceForm.attributes,
        quantityPriceScale: updatedQuantityPriceScale,
      },
    };
  
    this.setState({ serviceForm: updatedForm });
  };
  

  handleDelete = index => {
    const { serviceForm } = this.state;
    const updatedForm = { ...serviceForm };
    if (updatedForm?.attributes?.quantityPriceScale?.length > 1) {
    updatedForm.attributes.quantityPriceScale.splice(index, 1);
    this.setState({ serviceForm: updatedForm });
  } else {
    // Display a message or perform any other action when attempting to delete the last row
    this.setState({
      deleteRow: true,
    });
  }
  };

  handleChange1 = (index, field, value) => {
    // quantity
// price
    const { serviceForm } = this.state;
    const updatedForm = { ...serviceForm };
  
    // Update the quantity or price value with parseFloat
    if((field === "quantity" && value > 0) || (field === "price" && value >= 0)  ){

      updatedForm.attributes.quantityPriceScale[index][field] = parseFloat(value);
    }
  
    // Check for duplicate entries
    const quantities = updatedForm.attributes.quantityPriceScale.map(item => item.quantity);
    const hasDuplicates = (new Set(quantities)).size !== quantities.length;
  
    if (hasDuplicates) {
      this.setState({
        quantityError: "Duplicate entry",
        quantityHelperText: "Please enter unique quantities",
        
      } , ()=> this.checkRequiredFields());
      return;
    }
    else{
          this.setState({
            quantityError: "",
            quantityHelperText: "",
          } , ()=> this.checkRequiredFields());
        }
  
    this.setState({ serviceForm: updatedForm } , ()=> this.checkRequiredFields());
  };
  
 
  render() {
    return (
      <Grid>
        {this.state.createSubType ? (
          <>
            <Grid>
              <EditSubForm
                selectedExpenseType={this.props.selectedExpenseType}

               {...this.state}
              handleChange1={this.handleChange1}
                form={this.state.serviceForm}
                handleChange={this.handleChange}
                handleQuantityChange = {this.handleQuantityChange}
                handlePriceChange={this.handlePriceChange}
                handleDelete={this.handleDelete}
                handleAddMore={this.handleAddMore}
                translate={this.props.translate}
                submitForm={this.submitForm}
                closeEditForm={this.closeEditForm}
                mode={this.state?.mode}
                isVisableSubmitBtn={this.state?.isVisableSubmitBtn}
                checkPrivileges={checkPrivileges}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              className="breadcrumb-row"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: 0 }}>
                  {this.props.translate("services")}
                </h3>
              </Grid>
              <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
              <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    // disabled={this.props.selectedServiceTypeId ? false : true}
                    fullWidth
                    label={this.props.translate("search")}
                    // value={this.props.search}
                    value={this.props.searchName}
                    onChange={(e) => this.props.searchSubTypeItem(e)}
                  />
                </div>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    // disabled={this.props.selectedServiceTypeId ? false : true}
                    fullWidth
                    label={this.props.translate("searchItemSubType")}
                    value={this.props.searchSubType}
                    onChange={(e) => this.props.searchSubTypeItem(e,"itemSubType")}
                  />
                </div>
                <Button
                  aria-label="sharedSearch"
                  size="small"
                  color="inherit"
                  onClick={this.props.getServices}
                  id="createButton"
                  style={{ marginRight: 20 }}
                  // disabled={this.props.selectedServiceTypeId ? false : true}
                >
                  {this.props.translate("sharedSearch")}
                </Button>
                { checkPrivileges("expensetypeCreate") && (
                <Button
                  aria-label="Create"
                  size="small"
                  color="inherit"
                  onClick={this.openCreateSubTypeFrom}
                  id="createButton"
                  disabled={this.props.selectedServiceTypeId ? false : true}
                >
                  {this.props.translate("create")}
                </Button>)}
                <div style={{ width: "10%", marginRight: 35 }}>
                  <ExportImportMenu
                    fileName="expensetypes/subtypes"
                    checkPrivilege="serviceCreate"
                    isServices
                    translate={this.props.translate}
                    data={this.state?.expense || []}
                    themecolors={this.props.themecolors}
                    expensetypeId={this.props?.selectedServiceTypeId || ""}
                    fetchAccountServices={this.props.selectService}
                    selectedAccount={this.props.selectedServiceTypeId}
                    {...this.props}
                    {...this.state}
                    disabled={this.props.selectedServiceTypeId ? false : true}
                  />
                </div>
                <Button
                  aria-label="Back"
                  size="small"
                  color="inherit"
                  onClick={this.props.goBack}
                  id="back"
                  // disabled={this.props.selectedServiceTypeId ? false : true}
                >
                  {this.props.translate("sharedBack")}
                </Button>
              </Grid>
            </Grid>
            <Grid>
              {this.props.selectedServiceTypeId ? (
                <TableWithBackEndPagination
                  rows={this.props.selectedSubTypesServices.data || []}
                  pagination={this.props.setPagination(
                    this.props.selectedSubTypesServices || []
                  )}
                  handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                  handleChangePage={this.props.handleChangePage}
                  // rowsPerPage={this.state?.rowsPerPage}
                  isEditable
                  translate={this.props.translate}
                  // isEditable
                  hasAccessOfUpdate={checkPrivileges("expensetypeUpdate")}
                  hasAccessOfDelete={checkPrivileges("expensetypeDelete")}
                  
                  canUpdate
                  canDelete
                  onEdit={this.onEdit}
                  onDelete={this.onDelete}
                  themecolors={this.props.themecolors}
                  rowDefinition={[
                    {
                      id: "label",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("sharedName"),
                    },
                    {
                      id: "subTypeNum",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("code"),
                    },
                    {
                      id: "subTypeGroup",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("Group"),
                    },
                   
                    {
                      id: "unitPrice",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("sellingPriceWithoutVAT"),
                    },
                    {
                      id: "purchasePrice",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("purchasePrice"),
                      hide: checkPrivileges("invoicePurchasePriceView")
                        ? false
                        : true,
                    },
                  ]}
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: 350,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4> {this.props.translate("selectType")}</h4>
                </div>
              )}
            </Grid>
          </>
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeSubType}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.serviceForm?.label || ""}
          />
        )}
         {this.state.deleteRow && (
          <ConfirmDialoag
            onCancel={this.onCancel1}
            onOk={this.onCancel1}
            title={this.props.translate("cannottDeleteLastRow")}
            // children={this.state.selectedUser.name}
          />
        )}
      </Grid>
    );
  }
}

const EditSubForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  field,
  checkPrivileges,
  handleAddMore,
    handleDelete ,
handleChange1,
quantityError,
quantityHelperText,
selectedExpenseType
  
}) => {
  return (
    <div style={{ padding: 10 }}>
      <h4 className="page-title">{translate("subType")}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            required
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form?.label || ""}
            onChange={(e) => handleChange("label", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="subTypeNum"
            margin="dense"
            label={translate("code")}
            variant="outlined"
            fullWidth
            required
            value={form?.subTypeNum || ""}
            onChange={(e) => handleChange("subTypeNum", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>

                      <TextField
                          id='expenseType'
                          select
                          label={translate('expenseType')}
                          value={form?.expenseType  || ''}
                          onChange={e=>handleChange("expenseType", e.target.value)}
                          margin='dense'
                          fullWidth
                          required
                          disabled={selectedExpenseType?.name === "packages" ||
                                    selectedExpenseType?.name === "services" || 
                                    selectedExpenseType?.name === "items"}
                        >
                          {
                              statusTypes.map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                  {translate(option.name)}
                                </MenuItem>
                              ))}
                        </TextField>
          {/* <TextField
            id="expenseType"
            margin="dense"
            label={translate("expenseType")}
            variant="outlined"
            fullWidth
            
            value={form?.expenseType || ""}
            onChange={(e) => handleChange("expenseType", e.target.value)}
          /> */}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="subTypeGroup"
            margin="dense"
            label={translate("Group")}
            variant="outlined"
            fullWidth
            
            value={form?.subTypeGroup || ""}
            onChange={(e) => handleChange("subTypeGroup", e.target.value)}
          />
        </Grid>
   
      
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="vatPercent"
            margin="dense"
            label={translate("vatPercent")}
            variant="outlined"
            fullWidth
            type="number"
            value={form?.vatPercent || ""}
            helperText="Value should be between 0 and 100"
            onChange={(e) => handleChange("vatPercent", e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="unitPrice"
            margin="dense"
            label={translate("sellingPriceWithoutVAT")}
            variant="outlined"
            fullWidth
            type="number"
            value={form?.unitPrice || ""}
            onChange={(e) => handleChange("unitPrice", e.target.value)}
          />
            <FormHelperText error={form?.unitPrice < 0 ? true : false}>
              {translate('valueMustbeGreaterthen0')}
            </FormHelperText>
        </Grid>
        {checkPrivileges("invoicePurchasePriceView") && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="purchasePrice"
              margin="dense"
              label={translate("purchasePrice")}
              variant="outlined"
              fullWidth
              type="number"
              value={form?.purchasePrice || ""}
              onChange={(e) => handleChange("purchasePrice", e.target.value)}
            />
              <FormHelperText error={form?.purchasePrice < 0 ? true : false}>
                               {translate('valueMustbeGreaterthen0')}
                              </FormHelperText>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} style={{margin:"5px"}}>
          {form?.attributes?.quantityPriceScale?.map((item, index) => (
          <Grid container spacing={1} 
          key={index}
          >
          <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
          id={`quantity-${index}`}
          required
          margin="dense"
          label={translate("quantity")}
          variant="outlined"
          fullWidth
          type="number"
          value={item.quantity || ''}
          onChange={(e) => handleChange1(index, 'quantity', e.target.value)}
          error={index === (form.attributes.quantityPriceScale.length - 1) && quantityError !== ''}
          helperText={index === (form.attributes.quantityPriceScale.length - 1) ? quantityHelperText : ''}
        />
          <FormHelperText error={item.quantity <= 0 ? true : false}>
                               {translate('minimum1Quantity')}
                              </FormHelperText>

     {/* <TextField
       required
       margin="dense"
       label="Quantity"
       variant="outlined"
       fullWidth
       value={item.quantity || 0}
       onChange={(e) => handleChange1(index, 'quantity', e.target.value)}
     /> */}
   </Grid>
   <Grid item xs={12} sm={6} md={4} lg={3}>
     <TextField
       margin="dense"
       label="Price"
       type="number"
       variant="outlined"
       fullWidth
       value={item.price || 0}
       onChange={(e) => handleChange1(index, 'price', e.target.value)}
     /> 
      <FormHelperText error={item.price < 0 ? true : false}>
                               {translate('valueMustbeGreaterthen0')}
                              </FormHelperText>
   </Grid>
   <Grid item xs={12} sm={6} md={4} lg={3}>
     <div style={{ display: 'flex', marginTop: 10 }}>
       <Button
         size="small"
         style={{ marginRight: 15 }}
         className="button-white"
         onClick={handleAddMore}
         disabled={quantityError ? "disabled" : ""}
       >
         Add More
       </Button>
       <Button size="small"
        onClick={() => handleDelete(index)}
        >
         Delete
       </Button>
     </div>
   </Grid>
 </Grid>
 ))}
</Grid>

      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeEditForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>
        {/* {checkPrivileges("expensetypeCreate") && ( */}
          <Button
            size="small"
            onClick={submitForm}
            disabled={(!checkPrivileges(mode === "update" ? "expensetypeUpdate" : "expensetypeCreate")) || isVisableSubmitBtn}
            // disabled={isVisableSubmitBtn}
          >
            {translate(mode === "update" ? "update" : "sharedCreate")}
          </Button>
        {/* )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
});

export default connect(mapStateToProps)((ContractModal));
