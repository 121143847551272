import React, { Component, Fragment } from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkPrivileges, errorHandler } from "../../../../Helpers";
import { updateUser, parentUsersLimit } from "../../../../Actions/Users";
import { updateAccount } from "../../../../Actions/Accounts";
import { validEmailPattern } from "../../../../Helpers";
import { parentUnitsLimit } from "../../../../Actions/Devices";
import AddUserModal from "../../addUserModal";
import isEqual from "react-fast-compare";
import MarkerMap from "../../../common/Maps";
import CustomDialog from "../../../common/Dialog";
import { checkUnitsWithStandardType } from "../../userDataTypes";
import { ServerInfo } from "../../../../Actions/serverSetting";
import instance from "../../../../axios";

const userDataFormat = (data, extAttriubtes, itemId) => {
  let formattedData = {
    city:data.city? data.city : "",
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : "",
    deviceLimit: data.deviceLimit ? data.deviceLimit : "",
    deviceReadonly:
      data.deviceReadonly === "on" || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === "on" || data.disabled === true ? true : false,
    email: ("" + data.email).trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: itemId ? itemId : 0,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands:
      data.limitCommands === "on" || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: ("" + data.name).trim(),
    phone: data.phone,
    poiLayer: data.poiLayer,
    readonly: data.readonly === "on" || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === "on" || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit ? data.userLimit : "",
    vehicleLimit: data.vehicleLimit ? data.vehicleLimit : "",
    //creditLimit: data.creditLimit ? data.creditLimit : '',
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId || 0,
    userType: parseInt(data.userType),
    parentId: data.parentId || 0,
    // active:data.active || true

  };
  if (data.customerType) {
    formattedData.customerType = parseInt(data.customerType);
  }
  return formattedData;
};

const accountDataFormat = (data, form, attributes) => {
  let newAttributes = {
    c_buildingNoArabic: "",
    c_addressArabic: "",
    c_streetNameArabic: "",
    c_cityArabic: "",
    ...attributes,
  };
  return {
    attributes: newAttributes ? newAttributes : {},
    contact: form.accountContact || data.contact,
    created: form.created,
    contractName: form?.contractName,
    contractNumber: form?.contractNumber,
    pacContractId:form?.pacContractId,
    // garageNumber: form?.garageNumber,
    garageNumber: form?.garage ? form?.garage.garageNumber : form?.garageNumber ? form?.garageNumber :  "",
    
    vatPercentage: form?.vatPercentage || 0,
    contractStartDate: form?.contractStartDate || "",
    contractEndDate: form?.contractEndDate || "",
    settleDate: form?.settleDate || "",
    defaultSatisfactionDate: form?.defaultSatisfactionDate || "",
    customerNumber: form?.customerNumber || "",
    creditLimit: form?.creditLimit || "",
    creditPeriod: form?.creditPeriod || "",
    // buildingNoArabic: form?.buildingNoArabic || '',
    description: form.accountDescription || data.description,
    email: form.accountEmail,
    id: data.id || 0,
    modified: form.modified,
    name: form.accountName,
    parentId: data.parentId || 0,
    contractType: form?.contractType || 1,
    nameArabic: form?.nameArabic || 1,
    monthlyRevenueTarget :form?.monthlyRevenueTarget,
    yearlyRevenueTarget : form?.yearlyRevenueTarget, 
    status: form?.status || '',
    settingId:data?.settingId || '',
    active: form.active ? true :false,

  };
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    // margin: theme.spacing(1),
  },
});
let uploadFilesArray = []
class updateUserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      units: "",
      form: {
        ...this.props.selectedUser,
        accountName:
          this.props.selectedAccount?.name ||
          this.props.selectedUser?.extraInfo?.accountName,
        accountEmail:
          this.props.selectedAccount?.email ||
          this.props.selectedUser?.extraInfo?.extraInfo?.accountEmail,
        accountDescription: this.props.selectedAccount?.description || "",
        accountContact: this.props.selectedAccount?.contact || "",
        contractName: this.props.selectedAccount?.contractName || "",
        customerNumber: this.props.selectedAccount?.customerNumber || "",
        contractNumber: this.props.selectedAccount?.contractNumber || "",
        pacContractId: this.props.selectedAccount?.pacContractId || "",
        
        garageNumber: this.props.selectedAccount?.garageNumber || "",
        
        vatPercentage: this.props.selectedAccount?.vatPercentage || 0,
        contractEndDate: this.props.selectedAccount?.contractEndDate || "",
        contractStartDate: this.props.selectedAccount?.contractStartDate || "",
        settleDate: this.props.selectedAccount?.settleDate || "",
        defaultSatisfactionDate: this.props.selectedAccount?.defaultSatisfactionDate || "",
        creditLimit: this.props.selectedAccount?.creditLimit || "",
        creditPeriod: this.props.selectedAccount?.creditPeriod || "",
        status: this.props.selectedAccount?.status || "",
        contractType: this.props.selectedAccount?.contractType || 1,
        nameArabic: this.props.selectedAccount?.nameArabic || 1,
        monthlyRevenueTarget :this.props.selectedAccount?.monthlyRevenueTarget,
        yearlyRevenueTarget : this.props.selectedAccount?.yearlyRevenueTarget, 
        active:this.props.selectedAccount?.active ?  true : false

        
      },
      accountsform: this.props.selectedAccount?.attributes || {},
      selectedUser: this.props.selectedUser,
      isVisableUserBtn: false,
      duplicateEntry: false,
      userLimit: true,
      vehicleLimit: true,
      deviceLimitError: false,
      userLimitError: false,
      vehicleLimitError: false,
      unitsLimitsMeaasge: "",
      vehicleLimitsMeaasge: "",
      usersLimitsMeaasge: "",
      vehicleLimitsMeaasge: "",
      currentUserRole: [],
      localPostionObj: "",
      isVisableAddlocBtn: false,
      editOption: true,
      notVisableBackbtn: true,
      uploadDoc: [],
      filesData: [],
      emailInvalidError: "",
      accountEmailInvalidError: "",
      newField:""

    };
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.uu_submit = this.uu_submit.bind(this);
    this.generateToken = this.generateToken.bind(this);
    this.timeDateOnchange = this.timeDateOnchange.bind(this);
    this.changeAttribute2 = this.changeAttribute2.bind(this);
  }

  componentWillMount() {
    const c_schemeIdList = [
      { key: 1, name: "TIN" },
      { key: 2, name: "CRN"},
      { key: 3, name: "MOM"},
      { key: 4, name: "MLS"},
      { key: 5, name: "700"},
      { key: 6, name: "SAG"},
      { key: 7, name: "NAT" },
      { key: 8, name: "GCC"},
      { key: 9, name: "IQA"},
      { key: 10, name: "OTH"},
    ];
if(this.props.selectedAccount?.attributes.c_schemeId){
    const selectedScheme = c_schemeIdList.find(scheme => scheme.name === this.props.selectedAccount?.attributes.c_schemeId);
    if (selectedScheme) {
      // Dynamically set the value of the second field based on the selected scheme ID
      const newFieldName = `c_${selectedScheme.name.toLowerCase()}Number`;

      this.setState({
        newField:this.props.selectedAccount?.attributes[newFieldName] || '',
        accountsform: {
          ...this.state.accountsform,
          // [name]: value,
          [newFieldName]: this.props.selectedAccount?.attributes[newFieldName] || '', 
          // Initialize the value of the new field
        },
      });
    }
}


    this.setState({
      uploadDoc: [],
      filesData: []
    })
    uploadFilesArray=[]
  }


  handleChange2 = (name) => (event) => {
    const { target } = event;
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (
      name === "expirationTime" ||
      name === "contractStartDate" ||
      name === "defaultSatisfactionDate" ||
      name === "settleDate" ||
      name === "contractEndDate"
    ) {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      let value = target?.value || "";
      if (value) {
        if (target && target.type === "number") {
          value = parseFloat(value);
        }
        if (name === "deviceLimit") {
          if (
            parseInt(value) === -1 &&
            parseInt(this.props.pUnitLimits.remaningLimit) === -1
          ) {
            this.setState(
              {
                deviceLimitError: false,
                unitsLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(value) <
                parseInt(
                  this.props.selectedUnitsLimits.count +
                    this.props.selectedUnitsLimits.reserveLimit
                )
              ) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitError",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                if (parseInt(this.props.pUnitLimits.remaningLimit) === -1) {
                  this.setState(
                    {
                      deviceLimitError: false,
                      unitsLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                } else if (
                  parseInt(value) >
                  parseInt(
                    this.props.pUnitLimits.limit -
                      this.props.pUnitLimits.totalCount
                  )
                ) {
                  this.setState(
                    {
                      deviceLimitError: true,
                      unitsLimitsMeaasge: "deviceLimitErrorParent",
                    },
                    () => this.checkRequiredFields()
                  );
                } else {
                  this.setState(
                    {
                      deviceLimitError: false,
                      unitsLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                }
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "userLimit") {
          if (
            parseInt(value) === -1 &&
            parseInt(this.props.pUserLimits.remaningLimit) === -1
          ) {
            this.setState(
              {
                userLimitError: false,
                usersLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(value) <
                parseInt(
                  this.props.selectedUserLimits.count +
                    this.props.selectedUserLimits.reserveLimit
                )
              ) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitError",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                if (parseInt(this.props.pUserLimits.remaningLimit) === -1) {
                  this.setState(
                    {
                      userLimitError: false,
                      usersLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                } else if (
                  parseInt(value) >
                  parseInt(
                    this.props.pUserLimits.limit -
                      this.props.pUserLimits.totalCount
                  )
                ) {
                  this.setState(
                    {
                      userLimitError: true,
                      usersLimitsMeaasge: "userLimitErrorParent",
                    },
                    () => this.checkRequiredFields()
                  );
                } else {
                  this.setState(
                    {
                      userLimitError: false,
                      usersLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                }
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "vehicleLimit") {
          if (
            parseInt(value) === -1 &&
            parseInt(this.props.pUserLimits.remaningLimit) === -1
          ) {
            this.setState(
              {
                vehicleLimitError: false,
                vehicleLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(value) <
                parseInt(
                  this.props.selectedUserLimits.count +
                    this.props.selectedUserLimits.reserveLimit
                )
              ) {
                this.setState(
                  {
                    vehicleLimitError: true,
                    vehicleLimitsMeaasge: "userLimitError",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                if (parseInt(this.props.pUserLimits.remaningLimit) === -1) {
                  this.setState(
                    {
                      vehicleLimitError: false,
                      vehicleLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                } else if (
                  parseInt(value) >
                  parseInt(
                    this.props.pUserLimits.limit -
                      this.props.pUserLimits.totalCount
                  )
                ) {
                  this.setState(
                    {
                      vehicleLimitError: true,
                      vehicleLimitsMeaasge: "vehicleLimitErrorParent",
                    },
                    () => this.checkRequiredFields()
                  );
                } else {
                  this.setState(
                    {
                      vehicleLimitError: false,
                      vehicleLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                }
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    vehicleLimitError: true,
                    vehicleLimitsMeaasge: "userLimitErrorParent",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    vehicleLimitError: false,
                    vehicleLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      } else if (this.state.form[name]) {
        if (target && target.type === "number") {
          value = parseFloat(value);
        }
        if (name === "deviceLimit") {
          if (this.props.pUnitLimits.count !== 0) {
            this.setState(
              {
                deviceLimitError: true,
                unitsLimitsMeaasge: "deviceLimitError",
              },
              () => this.checkRequiredFields()
            );
          } else {
            this.setState(
              {
                deviceLimitError: false,
              },
              () => this.checkRequiredFields()
            );
          }
        }
        if (name === "userLimit") {
          if (this.props.selectedUserLimits.count !== 0) {
            this.setState(
              {
                userLimitError: true,
                usersLimitsMeaasge: "userLimitError",
              },
              () => this.checkRequiredFields()
            );
          } else {
            this.setState(
              {
                userLimitError: false,
              },
              () => this.checkRequiredFields()
            );
          }
        }
        if (name === "vehicleLimit") {
          if (this.props.selectedUserLimits.count !== 0) {
            this.setState(
              {
                vehicleLimitError: true,
                vehicleLimitsMeaasge: "userLimitError",
              },
              () => this.checkRequiredFields()
            );
          } else {
            this.setState(
              {
                vehicleLimitError: false,
              },
              () => this.checkRequiredFields()
            );
          }
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };

  handleChangeAttributes = (name) => (event) => {
    const c_schemeIdList = [
      { key: 1, name: "TIN" },
      { key: 2, name: "CRN"},
      { key: 3, name: "MOM"},
      { key: 4, name: "MLS"},
      { key: 5, name: "700"},
      { key: 6, name: "SAG"},
      { key: 7, name: "NAT" },
      { key: 8, name: "GCC"},
      { key: 9, name: "IQA"},
      { key: 10, name: "OTH"},
    ];
    
    const value = name === "agreedAdvancePayment" ?
                      parseFloat(event.target.value) :
                      event.target.value;
    
    if (name === 'c_schemeId') {

      const selectedScheme = c_schemeIdList.find(scheme => scheme.name === value);
      if (selectedScheme) {

        const newFieldName = `c_${selectedScheme.name.toLowerCase()}Number`;

        this.setState({
          accountsform: {
            ...this.state.accountsform,
            [name]: value,
            [newFieldName]: '', // Initialize the value of the new field
          },
        });
      }
    } 
    else if(name==='number'){
      this.setState({
        newField:value,
        accountsform: {
          ...this.state.accountsform,
          [`c_${this.state?.accountsform?.c_schemeId?.toLowerCase()}Number`]: value,
        },
      });
    }
    else {
      // For other fields, update the state as usual
      this.setState(
        {
          accountsform: {
            ...this.state.accountsform,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields();
        }
      );
    }
  };
  checkRequiredFields() {
    let valid = true;
    let { name, email, roleId, userType, zoom, accountEmail, accountName , contractStartDate , contractEndDate , vatPercentage} =
      this.state.form;
    let{c_taxNumber , c_streetName, c_buildingNo, c_city,c_schemeId, c_postalCode, c_country, c_district}=this.state.accountsform


    if (email && !validEmailPattern.test(email)) {
      this.setState({
        emailInvalidError: "Please enter a valid email address."
      });
    } else {
      this.setState({
        emailInvalidError: ""
      });
    }

    if (accountEmail && !validEmailPattern.test(accountEmail)) {
      this.setState({
        accountEmailInvalidError: "Please enter a valid email address."
      });
    } else {
      this.setState({
        accountEmailInvalidError: ""
      });
    }
    let value = !isEqual(this.state.form, this.props.selectedUser);
    if(this.state.form.userType === 2 || this.state.form.userType === 1){
      if (
        name &&
        email &&
        userType &&
        roleId &&
        value &&
        zoom &&
        accountEmail &&
        accountName
      ) {
        if (!validEmailPattern.test(email)) {
          valid = false;
        }
        // check both user and device limit error
        let limitError = true;
        if (!this.state.deviceLimitError && !this.state.userLimitError) {
          limitError = false;
        }
        if (zoom > 2 && zoom < 19) {
          this.setState({
            isVisableUserBtn: limitError ? false : valid,
            duplicateEntry: !valid,
          });
        } else {
          this.setState({
            isVisableUserBtn: false,
            duplicateEntry: false,
          });
        }
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
    }else if (this.state.form.userType === 4){
      if (
        name &&
        email &&
        accountEmail &&
        accountName
      ) {
        this.setState({
          isVisableUserBtn: true,
          duplicateEntry: false,
        });
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
    }
    else if(userType === 3){
      if (
        name &&
        email &&
        accountEmail &&
        c_schemeId && c_postalCode
      ) {
        this.setState({
          isVisableUserBtn: true,
          duplicateEntry: false,
        });
   
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
    }
    else{
      if (
        name &&
        email &&
        userType &&
        roleId &&
        value &&
        zoom &&
        accountEmail &&
        c_taxNumber&&
        c_streetName&& c_buildingNo&& c_city&& c_postalCode&& c_country&& c_district&&
        accountName
      ) {
        if (!validEmailPattern.test(email)) {
          valid = false;
        }
        // check both user and device limit error
        let limitError = true;
        if (!this.state.deviceLimitError && !this.state.userLimitError) {
          limitError = false;
        }
        if (zoom > 2 && zoom < 19) {
          this.setState({
            isVisableUserBtn: limitError ? false : valid,
            duplicateEntry: !valid,
          });
        } else {
          this.setState({
            isVisableUserBtn: false,
            duplicateEntry: false,
          });
        }
        // this.setState(
        //   {
        //     isVisableUserBtn: limitError ? false : valid,
        //     duplicateEntry: !valid
        //   })
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
    }
    
    if(vatPercentage){
      if(vatPercentage >= 0 && vatPercentage <= 100){
        this.setState({
          isVisableUserBtn: true,
            vatPercentageError: "",
        });
        
      } else {
        this.setState({
          isVisableUserBtn: false,
          vatPercentageError:"Enter Value between 0 and 100"
        });
      }
    }




    if (contractStartDate && contractEndDate) {
      const startDate = new Date(contractStartDate);
      const endDate = new Date(contractEndDate);
    
      if (startDate > endDate) {
        this.setState({
          isVisableUserBtn: false,
            contractEndDateError: "Contract end date must be after contract start date",
        });
      } else {
        this.setState({
          isVisableUserBtn: true,
          contractEndDateError:""
        });
      }
    }
  }
  changeAttribute2(name, event) {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (!value) {
      let a = { ...this.state.form.attributes };
      delete a[name];
      if (!Object.keys(a).length) {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes:
                this.props.selectedUser.attributes === null ? null : {},
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...a,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      if (name === "parking.minIdleTime") {
        value = parseFloat(value);
      }
      if (name === "parking.maxIdleSpeed") {
        value = parseFloat(value);
      }
      if (name === "measurementStandard") {
        let data = checkUnitsWithStandardType(value);
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]:
                  event.target.type === "number" ? parseInt(value) : value,
                distanceUnit: data[0].distanceUnit.key,
                speedUnit: data[0].speedUnit.key,
                volumeUnit: data[0].volumeUnit.key,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  }
  timezone_handleChange = (name, value) => {
    if (!value || value.value === "None") {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name];
      }
      let a = { ...this.state.form.attributes };
      delete a[name];
      if (!Object.keys(a).length) {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes:
                this.props.selectedUser.attributes === null ? null : {},
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value.value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  uu_submit() {
    const { selectedUser } = this.state;
    let { name, email, attributes } = this.state.form;
    if (("" + name).trim().length > 0 && ("" + email).trim().length > 0) {
      let itemId = selectedUser.id;
      
      const obj = userDataFormat(this.state.form, attributes, itemId);
      const AccountObj = accountDataFormat(
        this.props.selectedAccount,
        this.state.form,
        this.state.accountsform
      );


      if (this.state.form.userType > 2) {
        fetch(`/api/accounts/${this.props.selectedAccount.id}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...AccountObj,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                let account = res.data;
                if (res.status === "success") {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        accountName: AccountObj?.name || "",
                        accountEmail: AccountObj?.email || "",
                        accountDescription: AccountObj?.description || "",
                        accountContact: AccountObj?.contact || "",
                        contractName: AccountObj?.contractName || "",
                        customerNumber: AccountObj?.customerNumber || "",
                        contractNumber: AccountObj?.contractNumber || "",
                        pacContractId: AccountObj?.pacContractId || "",
                        
                        garageNumber: AccountObj?.garageNumber || "",
                        
                        vatPercentage: AccountObj?.vatPercentage || 0,
                        contractEndDate: account?.contractEndDate || "",
                        contractStartDate: account?.contractStartDate || "",
                        settleDate: account?.settleDate || "",
                        defaultSatisfactionDate: account?.defaultSatisfactionDate || "",
                        creditLimit: AccountObj?.creditLimit || "",
                        creditPeriod: AccountObj?.creditPeriod || "",
                        nameArabic: AccountObj?.nameArabic || 1,
                        monthlyRevenueTarget :AccountObj?.monthlyRevenueTarget,
                        yearlyRevenueTarget : AccountObj?.yearlyRevenueTarget, 
                          status:AccountObj.status || '',

                        
                      },
                    },
                    () => {
                      this.props.updateSelectedAccount(account);
                    }
                  );
                  this.props.dispatch(updateAccount(account));
                  // form: { ...this.props.selectedUser, accountEmail:this.props.selectedUser&&this.props.selectedUser.extraInfo&&this.props.selectedUser.extraInfo.accountEmail },
                  if (this.props.selectedAccount.id === 1) {
                    this.props.dispatch(ServerInfo(account));
                  }
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  // this.props.dispatch(
                    toast.error(this.props.translate(err[1]));
                }
              });
            }
          })
          .catch((e) => {
              toast.error( this.props.translate("somethingWentWrong"))
          });
      }
      fetch(`/api/users/${itemId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...obj,
        }),
      })
        .then((response) => {
          response.json().then((res1) => {
            if (res1.status === "success") {
              let user = res1.data;
              let parent = {
                parentId: this.props.logInUser.id,
                parentName: this.props.logInUser.name,
              };
              user = { ...user, ...parent };
              this.props.dispatch(updateUser(user));
              this.props.UpdateSelectedItem(user);
              if (
                parseInt(this.props.selectedUser.parentId) ===
                parseInt(this.props.logInUser.id)
              ) {
                this.getUsersLimits();
              }
                toast.success(this.props.translate("userIsUpdated"))
              this.setState({
                // form: { ...user},
                form: {
                  ...user,
                  ...this.state.form,
                  // accountName: AccountObj?.name || "",
                  // accountEmail: AccountObj?.email || "",
                  // accountDescription: AccountObj?.description || "",
                  // accountContact: AccountObj?.contact || "",
                  // nameArabic: AccountObj?.nameArabic || "",
                  // contractType: AccountObj?.contractType || "",
                },
                selectedUser: user,
                isVisableUserBtn: false,
              });
            } else if (res1?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res1.statusCode) {
              var err = res1?.message.split(":");
              err[1] = err[1].replace(")", "");
                toast.error( this.props.translate(err[1]))
            }
          });
        })
        .catch((e) => {
            toast.error("somethingWentWrong")
        });
    } else {
      this.setState({
        isVisableUserBtn: false,
      });
        toast.error(this.props.translate("emptyField"))
    }
  }

  getUsersLimits = () => {
    if (this.props.logInUser.id) {
      //units limits
      if (checkPrivileges("device")) {
        fetch(`/api/devices/count?userId=${this.props.logInUser.id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  this.props.dispatch(parentUnitsLimit(res.data));
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                    toast.error(this.props.translate(err[1]))
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {});
      }
      //users limits
      if (checkPrivileges("user")) {
        fetch(`/api/users/count?userId=${this.props.logInUser.id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  this.props.dispatch(parentUsersLimit(res.data));
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                    toast.error( this.props.translate(err[1]))
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {});
      }
    }
  };
  generateToken() {
    let symbols =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i,
      newToken = "";
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken,
        },
      },
      () => this.checkRequiredFields()
    );
  }
  timeDateOnchange(date) {
    this.setState((preState) => ({
      ...preState,
      form: {
        ...preState.form,
        timeDate: date,
      },
    }));
  }
  setExpirationTime = (e) => {
    e.stopPropagation();
    if (this.state.form.expirationTime) {
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false,
    });
  };
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true,
      });
    }
  };
  setNoToken = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          token: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  setSelectedLocation = () => {
    const { localPostionObj } = this.state;
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom,
          },
          isMapModal: false,
        },
        () => this.checkRequiredFields()
      );
    }
  };
  onCloseModal = () => {
    this.setState({
      isMapModal: false,
    });
  };

  filesDataPrepare = async () => {
    let { filesData } = this.state

    if (filesData && filesData.length > 0) {
      for(let i=0; i<filesData.length; i++) {
        await instance({
          url: `/api/uploads`,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data: {
            ...filesData[i]
          }
        })
            .then(res => {
          }).catch(error => {errorHandler(error, this.props.dispatch)})
      }
      this.uu_submit()
    } else {
      this.uu_submit()
    }
  }
  removeFiles = (key) => {
    let updatedFilesData = [...this.state.filesData];
    updatedFilesData.splice(key, 1);
    uploadFilesArray.splice(key, 1);
      this.setState({
        filesData: updatedFilesData,
        uploadDoc: uploadFilesArray
      }, () => this.checkRequiredFields())
  };

  uploadFiles = (e) => {
    const files = e.target.files;
    let prevSavedImages = this.props.userFilesData ? this.props.userFilesData : [];
    let prevUploadedImages = this.state.uploadDoc ? this.state.uploadDoc : [];

    if (prevSavedImages.length + prevUploadedImages.length + files.length <= 5) {
        Object.values(files).forEach((item) => {
            if (item.size <= 5 * 1024 * 1024) {
                let reader = new FileReader();

                reader.onload = (e) => {
                    let fileData = e.target.result;

                    if (fileData.split(':')[1].split('/')[0] === 'image' || 
                        fileData.split(':')[1].split('/')[1].toLowerCase().substring(0, 3) === 'pdf' ||
                        fileData.split(':')[1].split('/')[1].toLowerCase().substring(0, 3) === 'vnd') 
                    {
                      uploadFilesArray.push(item.name)
                        let obj = {
                            name: `${item.name}`,
                            contentType: `${item.type}`,
                            oldUrl: fileData,
                            entity: "User",
                            entityId: this.state.selectedUser.id,
                            data: `${fileData.split('base64')[1].substring(1)}`,
                        };

                        this.setState((prevState) => ({
                            filesData: [...prevState.filesData, obj],
                            uploadDoc: [...uploadFilesArray],
                        }), () => this.checkRequiredFields());
                    } else {
                            toast.error(this.props.translate('onlyAcceptImagesDocs'))
                    }
                };

                reader.readAsDataURL(item);
            } else {
                    toast.error(this.props.translate('fileSize5MB'))
            }
        });
    } else {
            toast.error(this.props.translate('fileLimit'))
    }
  };
  changeLimit =(name, event)=>{
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    // this.setState({
    //   [name]: value,
    // });
    this.setState(
      {
        [name]: value,
        form: {
          ...this.state.form,
          deviceLimit:value ?  -1 : 0,
          vehicleLimit:value ?  -1 : 0,
          userLimit: value ? -1 : 0,
        },
      },
      () => this.checkRequiredFields()
    );
  }

  handleChangeExpense = (name, value) => {
    if (name === "garage") {
      this.setState(
        {
          form: {
            ...this.state.form,
            garage: value,
          },
        },
        () => {
          this.checkRequiredFields();
        }
      );
    }
  }
  render() {
    return (
      <Fragment>
        <AddUserModal
        
          {...this.state}
          {...this.props}
          newField={this.state.newField}
          changeLimit={this.changeLimit}
          isVisableUserBtn={this.state.isVisableUserBtn}
          logInUser={this.props.logInUser}
          padding={"0px"}
          uploadImage={this.uploadImage}
          removeImage={this.removeImage}
          elevation={0}
          // formSubmit={this.uu_submit}
          formSubmit={this.filesDataPrepare}
          handleChangeExpense={this.handleChangeExpense}
          timeDateOnchange={this.timeDateOnchange}
          generateToken={this.generateToken}
          changeAttribute2={this.changeAttribute2}
          handleChange={this.handleChange2}
          buttonText={this.props.translate("update")}
          handleClickShowPostion={this.handleClickShowPostion}
          userType={this.props.logInUser.userType}
          setExpirationTime={this.setExpirationTime}
          setNoToken={this.setNoToken}
          roleTypes={this.props.roles}
          timezone_handleChange={this.timezone_handleChange}
          hasAccessOfUpdate={checkPrivileges("userUpdate")}
          hasAccessOfDelete={checkPrivileges("userDelete")}
          hasAccessOfCreate={checkPrivileges("userCreate")}
          usersLimit={
            this.props.pUserLimits.limit - this.props.pUserLimits.totalCount
          }
          vehicleLimit={
            this.props.pUserLimits.limit - this.props.pUserLimits.totalCount
          }
          deviceLimit={
            this.props.pUnitLimits.limit - this.props.pUnitLimits.totalCount
          }
          createdUnits={this.props.selectedUnitsLimits.unitCount}
          createdUsers={this.props.selectedUnitsLimits.totalCount}
          handleChangeAttributes={this.handleChangeAttributes}
          uploadFiles={this.uploadFiles}
          removeFiles={this.removeFiles}
          uploadDoc={this.state.uploadDoc}
          userFilesData={this.props.userFilesData}
          removeUserFile={this.props.removeUserFile}
        />
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate("setLocation")}
            themeColors={this.props.themeColors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            fullWidth
            CancelText={this.props.translate("sharedBack")}
            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {" "}
                {this.props.translate("sharedAdd")}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.selectedUser.zoom || 0}
              lat={this.state.selectedUser.latitude || 0}
              lng={this.state.selectedUser.longitude || 0}
              height={"100%"}
            />
          </CustomDialog>
        )}
      </Fragment>
    );
  }
}

const UpdateUserDetail = withStyles(styles);
export default UpdateUserDetail((updateUserDetail));
