import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from './../../wrapper'
import { AcManagements } from '../../Components/AccountManagements/accountManagements'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logInUserInfo } from '../../Actions/Users'
import { ServerInfo } from '../../Actions/serverSetting'
import { checkPrivileges } from '../../Helpers'

class AccountManagements extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
      userFetch: false
    }
  }

  componentWillMount () {
    // this.props.dispatch(toast.removeAll())
    if (!this.props.ServerSetting) {
      fetch('/api/server').then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){   
              let server = res.data
              this.props.dispatch(ServerInfo(server))
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
             
                toast.error(
              this.props.translate(err[1])
              )
            }
          })
        }else{
          throw response
        }
      }).catch(e => {
      toast.error(
      'somethingWentWrong')
      })
    }
  }

  render () {
    if (checkPrivileges('user')) {
      if (this.props.logInUser) {
        return (
          <Layout
            {...this.props}
            noSidebar
          >
            <AcManagements {...this.props} />
          </Layout>
        )
      } else
        fetch('/api/session', {
          headers: { Accept: 'application/json; odata=verbose' }
        }).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
             
                  toast.error(
                   this.props.translate(err[1])
                )
                this.props.navigate('/login')
              }
            })
          } else {
            this.props.navigate('/login')
            throw response
          }
        })  
        .catch(e => {
      toast.error(
            'somethingWentWrong')
        })
      return null
    } else {
      return <h1>Page Not Found</h1>
    }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)((AccountManagements))
