import React from "react";
import classNames from "classnames";
import PropTypes, { object } from "prop-types";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveIcon from "@mui/icons-material/Remove";
import Checkbox from "./CheckboxPermissions";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/FiberManualRecord";
import EditIcon from "@mui/icons-material/Edit";
// import { alpha } from '@mui/styles';
import { alpha } from '@mui/system';
import withTranslationWrapper from "../../HOC/HocTranslate";
import styled from "styled-components";
import { connect } from "react-redux";
import TextField from "./SearchField";
import ConfirmDialoag from "./ConfirmDialoag";
import Style from "style-it";
import { checkPrivileges, getValue } from "../../Helpers";
import moment from "moment";
let themeColors;
const mapStateToProps = (state, ownProps) => {
  themeColors = state.themeColors;
  return { themecolors: state.themeColors, ServerSetting: state.ServerSetting };
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function searchFilter(array, value, colums) {
  var dev = array.filter((item) => {
    let exits = false;
    colums.map((c) => {
      if (
        ("" + item[c.id] || "")
          .toLowerCase()
          .indexOf((value || "").toLowerCase()) !== -1
      ) {
        exits = true;
      }
      return null;
    });
    return exits;
  });
  return dev;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

/*const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
];*/

function Renderhtml(props) {
  return <div dangerouslySetInnerHTML={{ __html: props.value }}></div>;
}
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      order,
      orderBy,
      classes,
      isEditable,
      rowCount,
      checkall,
      checked,
      payment,
    } = this.props;

    return (
      <TableHead>
        <TableRow
          className={classes.tableRow}
          style={{
            background:
              this.props.themecolors &&
              this.props.themecolors.themeLightColor + "80",
          }}
        >
          {this.props.showCheckbox && (
            <TableCell
              style={{
                borderColor:
                  this.props.themecolors && this.props.themecolors.textColor,
                padding: "0 10px",
                color: "inherit",
              }}
            >
              {this.props.selectAll && rowCount ? (
                <Tooltip
                  title={this.props.translate("assign_all_unassign_all")}
                  classes={{
                    popper: "menu-popper",
                    tooltip: "menu-popper-tooltip",
                  }}
                >
                  <div>
                    <Checkbox
                      disableRipple
                      onChange={this.props.selectAll}
                      canAssign={this.props.canAssign}
                      canRemove={this.props.canRemove}
                      indeterminate={checkall > 0 && checkall < rowCount}
                      checked={
                        checked !== undefined ? checked : checkall === rowCount
                      }
                    />
                  </div>
                </Tooltip>
              ) : null}
            </TableCell>
          )}
          {this.props.showGroupAccess && (
            <TableCell
              style={{
                borderColor:
                  this.props.themecolors && this.props.themecolors.textColor,
                borderLeft:
                  "1px solid " +
                  (this.props.themecolors &&
                    this.props.themecolors.themeLightColor),
                padding: "0 10px",
                color: "inherit",
              }}
            >
              {this.props.translate("groupAccess")}
            </TableCell>
          )}
          {this.props.rowDefinition.map((row) =>
            !row.hide ? (
              <TableCell
                colSpan={row.colspan || 1}
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  borderColor:
                    "this.props.themecolors&&this.props.themecolors.textColor",
                  borderLeft:
                    "1px solid " +
                    (this.props.themecolors &&
                      this.props.themecolors.themeLightColor),
                  padding: "0 10px",
                  color: "inherit",
                }}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ) : (
              <TableCell
                colSpan={row.colspan || 1}
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  borderColor:
                    "this.props.themecolors&&this.props.themecolors.textColor",
                  borderLeft:
                    "1px solid " +
                    (this.props.themecolors &&
                      this.props.themecolors.themeLightColor),
                  padding: "0 10px",
                  color: "inherit",
                }}
              ></TableCell>
            )
          )}
          {isEditable && (
            <TableCell
              key={"editable"}
              width={60}
              style={{ padding: "0 10px", color: "inherit" }}
            />
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    minHeight: "44px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          backgroundColor: alpha(theme.palette.secondary.light, 0.85),
        }
      : {
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 auto",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontFamily: "inherit",
    flex: "0 0 auto",
    fontSize: 18,
    fontWeight: 500,
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    className,
    column,
    selected,
    actionInHead,
    payment,
  } = props;

  const ThemeIconButton = styled(IconButton)`
    background-color: ${(props) =>
      this.props.themecolors && this.props.themecolors.backgroundColor};
    border-radius: 0;
    color: ${(props) =>
      this.props.themecolors && this.props.themecolors.textColor};
    &:hover {
      cursor: pointer;
      background-color: ${(props) =>
        this.props.themecolors && this.props.themecolors.backgroundColor};
    }
  `;

  return (
    <Toolbar
      className={
        classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        }) +
        " " +
        className
      }
    >
      <div>
        {
          <Typography className={classes.title} id="tableTitle" color="inherit">
            {props.payment ? (
              ""
            ) : (
              <>
                {props.title}
                {/* {numSelected > 0 ? "(" + selected[0][column] + ")" : ""} */}
              </>
            )}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      {actionInHead && (
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <Tooltip
              classes={{
                popper: "menu-popper",
                tooltip: "menu-popper-tooltip",
              }}
              title={this.props.translate("delete")}
            >
              <ThemeIconButton
                aria-label="Delete"
                color="inherit"
                onClick={props.onDelete}
              >
                <DeleteIcon fontSize="small" />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
          {numSelected === 1 ? (
            <Tooltip
              classes={{
                popper: "menu-popper",
                tooltip: "menu-popper-tooltip",
              }}
              title={this.props.translate("edit")}
            >
              <ThemeIconButton
                aria-label="Edit"
                onClick={props.onEdit}
                color="inherit"
              >
                <EditIcon fontSize="small" />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    paddingTop: 0,
    boxShadow: "none",
    background: "none",
    color: "inherit",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflow: "auto",
  },
  tableRow: {
    height: 36,
    "&:hover .actionButton": {
      opacity: 1,
      "&:hover": {
        fill: themeColors && themeColors.themeLightColor,
      },
    },
    "& .actionButton": {
      cursor: "pointer",
      fill: "currentColor",
      marginLeft: 5,
      [theme.breakpoints.up("sm")]: {
        opacity: 0,
      },
    },
  },
  toolbar: {
    root: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "44px",
      minHeight: "inherit",
    },
    toolbar: {
      height: "44px",
      minHeight: "inherit",
    },
  },
  head: {
    color: "inherit",
  },
});

const TablePaginationStyles = (theme) => ({
  root: {
    height: "44px",
    minHeight: "inherit",
    overflow: "hidden",
    color: "inherit !important",
    //borderTop: "1px solid rgba(224, 224, 224, 1)"
  },
  actions: {
    color: "inherit",
  },
  selectIcon: {
    color: "inherit",
  },
  toolbar: {
    height: "44px",
    minHeight: "inherit",
    paddingRight: theme.spacing(1),
  },
});

export const TablePaginationStyle = withStyles(TablePaginationStyles)(
  TablePagination
);

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.checkHandleChange = this.checkHandleChange.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      data: this.props.rows,
      page: 0,
      rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : 5,
      searchValue: "",
      confirmationModal: false,
    };
  }

  componentWillReceiveProps(n) {
    let newSelected = [];
    n.rows.map((r) => {
      if (r.check) {
        newSelected.push(r.id);
      }
      return null;
    });

    this.setState({ selected: newSelected });
  }

  componentWillMount() {
    let newSelected = [];
    this.props.rows.map((r) => {
      if (r.check) {
        newSelected.push(r.id);
      }
      return null;
    });

    this.setState({ selected: newSelected });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  checkHandleChange = (event, item) => {
    this.props.checkHandleChange(event, item);
  };

  handleClick = (event, item, preState) => {
    if (this.props.payment) {
      this.props.allUsersId(event, item);
    } else {
      const { selected } = this.state;
      const selectedIndex = selected.indexOf(item.id);

      let newSelected = [];

      if (selectedIndex === -1 && this.props.canAssign) {
        // add in array
        newSelected = newSelected.concat(selected, item.id);
      } else if (selectedIndex === 0 && this.props.canRemove) {
        //remove if exists
        newSelected = newSelected.concat(selected.slice(1));
      } else if (
        selectedIndex === selected.length - 1 &&
        this.props.canRemove
      ) {
        //remove if exists
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0 && this.props.canRemove) {
        //remove if exists

        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      if (
        (this.props.canAssign || this.props.canRemove) &&
        !this.props.payment
      ) {
        this.props.checkHandleChange(!preState, item);

        this.setState({ selected: newSelected });
      }
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });

    if (this.props.handleChangeRowsPerPage) {
      this.props.handleChangeRowsPerPage(event.target.value);
    }
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  onEdit() {
    this.props.onEdit(this.state.selected);
  }

  onDelete() {
    this.props.onDelete(this.state.selected);
    this.setState({ selected: [] });
  }

  onClose() {
    this.props.onClose();
  }

  makeSearch(event) {
    this.setState({ searchValue: event.target.value });
  }

  sortByCol = (arr) => {
    arr.sort((b, a) => {
      const o1 = a.parent;
      const o2 = b.parent;

      const p1 = a.check;
      const p2 = b.check;

      const p3 = a.indeterminate || false;
      const p4 = b.indeterminate || false;

      if (o1 < o2) return -1;
      if (o1 > o2) return 1;
      if (p1 < p2) return -1;
      if (p1 > p2) return 1;
      if (p3 < p4) return -1;
      if (p3 > p4) return 1;
      return 0;
    });
    return arr;
  };

  showDialog = (event) => {
    this.setState({ confirmationModal: true, checked: event.target.checked });
  };

  hideDialog = () => {
    this.setState({ confirmationModal: false, checked: !this.state.checked });
  };

  onOk = () => {
    this.setState({ confirmationModal: false }, () => {
      this.props.selectAll(this.state.checked);
    });
  };

  render() {
    const { classes } = this.props;
    const { order, orderBy, selected, page } = this.state;
    const { rows: data = [], rowsPerPage: rowsPerPage = 5 } = this.props;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const EnhancedTableToolbarStyled = styled(EnhancedTableToolbar)`
      background: ${(props) =>
        props.themecolors && props.themecolors.backgroundColor};
      color: ${(props) => props.themecolors && props.themecolors.textColor};
      padding-right: ${(props) => {
        return props.insideDialog ? "44px" : "0";
      }};
      padding-left: ${(props) => {
        return props.insideDialog ? "44px" : "def";
      }};
    `;
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    let dataRows = this.sortByCol([...data]);

    let checkall = 0;

    dataRows.map((row) => {
      if (row.check) {
        checkall++;
      }
      return null;
    });
    return (
      <Style>
        {`
          .custom-table tbody th,
          .custom-table tbody td {
            border-color: ${
              this.props.themecolors && this.props.themecolors.themeLightColor
            };
            color: #333;
          }
          .custom-table2 tbody th,
          .custom-table2 tbody td {
            border-color: ${
              this.props.themecolors && this.props.themecolors.themeLightColor
            };
            color: #333;
          }
        `}
        <Paper
          className={classes.root}
          style={{ paddingTop: this.props.disableHead ? 0 : null }}
        >
          {this.state.confirmationModal && (
            <ConfirmDialoag
              onCancel={this.hideDialog}
              onOk={this.onOk}
              title={
                checkall === 0 || checkall < dataRows.length
                  ? this.props.translate("areYouWantToSelectAll")
                  : this.props.translate("areYouWantToUnSelectAll")
              }
            />
          )}
          {this.props.disableHead === undefined && (
            <EnhancedTableToolbarStyled
              actionInHead={this.props.actionInHead}
              column={this.props.showInHead}
              selected={this.state.selected}
              insideDialog={this.props.insideDialog}
              themecolors={this.props.themecolors}
              numSelected={selected.length}
              title={this.props.title}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              onClose={this.onClose}
            />
          )}
          <div className={classes.tableWrapper}>
            <div
              style={{
                textAlign: "right",
                padding: "8px 16px 12px",
                display: "flex",
                alignItems: "center",
              }}
              className="clearfix"
            >
              {this.props.payment ? (
                ""
              ) : (
                <h4
                  className="v-tab-heading pull-left with-border"
                  style={{ marginTop: 8, marginBottom: 4 }}
                >
                  {this.props.title}
                </h4>
              )}
              {this.props.switchMode && (
                <div
                  style={{
                    marginTop: 8,
                    marginBottom: 4,
                    display: "inline-flex",
                  }}
                >
                  {this.props.switchMode}
                </div>
              )}
              <div style={{ margin: "auto" }} />
              {this.props.searchable && (
                <TextField
                  fullWidth
                  label={this.props.translate("sharedSearch")}
                  onChange={this.makeSearch}
                />
              )}
              {this.props.isFilter && (
                <div
                  style={{
                    marginTop: 8,
                    marginBottom: 4,
                    display: "inline-flex",
                  }}
                >
                  {this.props.isFilter}
                </div>
              )}
              <div style={{ marginTop: 8, marginBottom: 4 }}>
                {this.props.createButton}
              </div>
              {this.props.isDownload && (
                <div style={{ marginTop: 8, marginBottom: 4 }}>
                  {this.props.isDownload}
                </div>
              )}
            </div>
            <Table
              className={
                classes.table + " custom-table " + this.props.className
              }
              classes={classes}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                {...this.props}
                {...this.state}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                themecolors={this.props.themecolors}
                onRequestSort={this.handleRequestSort}
                isEditable={this.props.isEditable}
                checkall={checkall}
                checked={this.state.checked}
                translate={this.props.translate}
                rowCount={dataRows.length}
                showCheckbox={this.props.showCheckbox}
                showGroupAccess={this.props.showGroupAccess}
                selectAll={this.showDialog}
                rowDefinition={
                  this.props.rowDefinition || [
                    {
                      id: "name",
                      numeric: false,
                      disablePadding: true,
                      label: "Name",
                    },
                  ]
                }
                canAssign={this.props.canAssign}
                canRemove={this.props.canRemove}
                classes={classes}
              />

              <TableBody>
                {searchFilter(
                  stableSort(dataRows, getSorting(order, orderBy)),
                  this.state.searchValue,
                  this.props.rowDefinition
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n1) => {

                    const n = {};
                    for (const prop in n1) {
                      if (n1.hasOwnProperty(prop)) {
                        const propValue = n1[prop];
                        if (typeof propValue === 'string') {
                          n[prop] = propValue.replace(/&amp;/g, '&');
                        } else {
                          n[prop] = propValue;
                        }
                      }
                    }

                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        //selected={isSelected}
                        className={classes.tableRow}
                      >
                        {/* {this.props.showLineCheckbox && (
                          <>
                            <TableCell
                              key={"access"}
                              // style={{ borderColor: "#fff", padding: "0 10px" }}
                            >
                              <Checkbox
                                checked={this.props.isChecked}
                                readonly={true}
                                indeterminate={this.props.isIndeterminate}
                                onChange={(e) => this.props.allUsersId(e)}
                                canAssign={this.props.canAssign}
                                canRemove={this.props.canRemove}
                              />
                             
                            </TableCell>
                          </>
                        )} */}

                        {this.props.showCheckbox && (
                          <TableCell
                            padding="checkbox"
                            width="28"
                            style={{
                              padding: 0,
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              color: "inherit",
                              // borderBottom:
                              //   "1px solid " +
                              //   this.props.themecolors.textColor +
                              //   "80",
                            }}
                          >
                            {!n.parent ? (
                              <div>
                                <Checkbox
                                  disableRipple
                                  checked={n && n.check ? n.check : false}
                                  // readOnly={n.readonly}
                                  // indeterminate={
                                  //   n.check === false
                                  //     ? n.indeterminate
                                  //     : false
                                  // }
                                  onChange={(e) => this.handleClick(e, n)}
                                  // onChange={this.props.allUsersId}
                                  canAssign={this.props.canAssign}
                                  canRemove={this.props.canRemove}
                                />
                              </div>
                            ) : (
                              <Tooltip
                                classes={{
                                  popper: "menu-popper",
                                  tooltip: "menu-popper-tooltip",
                                }}
                                title={this.props.translate("parentUser")}
                              >
                                <HelpIcon
                                  style={{ display: "block", margin: "auto" }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                        {this.props.showGroupAccess && (
                          <TableCell
                            padding="checkbox"
                            width="28"
                            style={{
                              padding: 0,
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              color: "inherit",
                              borderBottom:
                                "1px solid " +
                                this.props.themecolors.textColor +
                                "80",
                              borderLeft:
                                "1px solid " +
                                this.props.themecolors.themeLightColor +
                                "66",
                            }}
                          >
                            {n.check || n.indeterminate ? (
                              n.groupAccess ? (
                                <DoneIcon />
                              ) : (
                                <RemoveIcon />
                              )
                            ) : (
                              ""
                            )}
                          </TableCell>
                        )}
                        {this.props.rowDefinition.map((r, i) => {
                          return r.id === "purchasePrice" &&
                            checkPrivileges("invoicePurchasePriceView") ? (
                            <TableCell
                              key={r.id}
                              align={r.numeric ? "right" : "left"}
                              padding={r.disablePadding ? "none" : "default"}
                              component="td"
                              scope="row"
                              style={{
                                padding: "0 10px",
                                whiteSpace: "nowrap",
                                color: "inherit",
                              }}
                            >
                              {n.purchasePrice?.toFixed(6)}
                            </TableCell>
                          ) 
                          // : r.id === "unitPrice" ? (
                          //   <TableCell
                          //     key={r.id}
                          //     align={r.numeric ? "right" : "left"}
                          //     padding={r.disablePadding ? "none" : "default"}
                          //     component="td"
                          //     scope="row"
                          //     style={{
                          //       padding: "0 10px",
                          //       whiteSpace: "nowrap",
                          //       color: "inherit",
                          //     }}
                          //   >
                          //     {n.unitPrice}
                          //   </TableCell>
                          // )
                           : r.id === "msg" ? (
                            <TableCell
                              key={r.id}
                              // align={r.numeric ? "right" : "left"}
                              // padding={r.disablePadding ? "none" : "default"}
                              component="td"
                              scope="row"
                              style={{
                                padding: "0 10px",
                                whiteSpace: "nowrap",
                                color: "red",
                              }}
                            >
                              {n.msg}
                            </TableCell>
                          ) :   
                          r.id === "salenetamount" ||
                          r.id === "unitPrice" ||
                          r.id === "purchasePrice" ||
                            r.id === "invoicebalance" ? (
                            <TableCell
                              key={r.id}
                              align={r.numeric ? "right" : "left"}
                              padding={r.disablePadding ? "none" : "default"}
                              component="td"
                              scope="row"
                              style={{
                                padding: "0 10px",
                                whiteSpace: "nowrap",
                                color: "inherit",
                              }}
                            >
                              {r.id === "salenetamount"
                                ? n.salenetamount?.toFixed(2) :
                                r.id === "unitPrice"
                                ? n.unitPrice?.toFixed(6) :
                                r.id === "purchasePrice"
                                ? n.purchasePrice?.toFixed(6) :
                                r.id === "invoicebalance"
                                ? n.invoicebalance?.toFixed(2)
                                : null}
                            </TableCell>
                          ) : r.id === "attributes.mileage" ? (
                            <>
                              <TableCell
                                key={i}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                  borderLeft:
                                    "1px solid " +
                                    this.props.themecolors.themeLightColor +
                                    "66",
                                  borderBottom:
                                    "1px solid " +
                                    this.props.themecolors.textColor +
                                    "80",
                                  width: 50,
                                }}
                              >
                                {n.attributes.mileage &&
                                  n.attributes.mileageInterval}
                              </TableCell>

                              <TableCell
                                key={i + "_"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                  borderLeft:
                                    "1px solid " +
                                    this.props.themecolors.themeLightColor +
                                    "66",
                                  borderBottom:
                                    "1px solid " +
                                    this.props.themecolors.textColor +
                                    "80",
                                  width: 50,
                                }}
                              >
                                {n.attributes.hours &&
                                n.attributes.hoursInterval
                                  ? (
                                      (n.attributes.hours +
                                        n.attributes.hoursInterval) /
                                      (1000 * 3600)
                                    ).toFixed(2) + " hours"
                                  : n.attributes.hours
                                  ? (
                                      n.attributes.hours /
                                      (1000 * 3600)
                                    ).toFixed(2) + " hours"
                                  : "-"}
                              </TableCell>

                              <TableCell
                                key={i + "__"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                  borderLeft:
                                    "1px solid " +
                                    this.props.themecolors.themeLightColor +
                                    "66",
                                  borderBottom:
                                    "1px solid " +
                                    this.props.themecolors.textColor +
                                    "80",
                                  width: 50,
                                }}
                              >
                                {n.attributes.date
                                  ? moment(n.attributes.date)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : "-"}
                              </TableCell>
                            </>
                          ) : (
                            <TableCell
                              key={i}
                              component="td"
                              scope="row"
                              style={{

                            maxWidth:150,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                                padding: "0 10px",
                                // whiteSpace: "nowrap",
                                color: "inherit",
                                borderLeft:
                                  "1px solid " +
                                  (this.props.themecolors &&
                                    this.props.themecolors.themeLightColor) +
                                  "66",
                                borderBottom:
                                  "1px solid " +
                                  (this.props.themecolors &&
                                    this.props.themecolors.textColor) +
                                  "80",
                              }}
                            >
                              {n[r.id] && n[r.id].bar ? (
                                n.check ? (
                                  <div>{"H"}</div>
                                ) : null
                              ) : r.translate ? (
                                this.props.translate((r.prefix || "") + n[r.id])
                              ) : r.id !== "expression" ? (
                                r.id === "attributes.value" ? (
                                  n.attributes.input === "date" ? (
                                    moment(n.attributes.value)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  ) : n.attributes.input === "hours" ? (
                                    Math.round(n.attributes.value / 3600) +
                                    this.props.translate("sharedHours")
                                  ) : n.attributes.input === "totalDistance" ? (
                                    Math.round(n.attributes.value / 1000) +
                                    this.props.translate("sharedKm")
                                  ) : null
                                ) : r.id === "attributes.repeat" ? (
                                  n.attributes.repeat ? (
                                    this.props.translate("Yes")
                                  ) : (
                                    this.props.translate("No")
                                  )
                                ) : r.id === "type" ? (
                                  n.type === "date" ? (
                                    this.props.translate("date")
                                  ) : n.type === "hours" ? (
                                    this.props.translate("sharedHours")
                                  ) : n.type === "totalDistance" ? (
                                    this.props.translate("Mileage")
                                  ) : null
                                ) : r.id === "notificators1" ? (
                                  // n.notificators.replace("firebase", "mobile")
                                  n.notificators.includes("mail") ? (
                                    <DoneIcon />
                                  ) : (
                                    <ClearIcon />
                                  )
                                ) : r.id === "notificators2" ? (
                                  n.notificators.includes("web") ? (
                                    <DoneIcon />
                                  ) : (
                                    <ClearIcon />
                                  )
                                ) : r.id === "notificators3" ? (
                                  n.notificators.includes("firebase") ? (
                                    <DoneIcon />
                                  ) : (
                                    <ClearIcon />
                                  )
                                ) : (
                                  getValue(n, r.id)
                                )
                              ) : (
                                <Renderhtml value={n[r.id]} />
                              )}
                            </TableCell>
                          );
                        })}
                        {this.props.isEditable && (
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              padding: "0 10px",
                              whiteSpace: "nowrap",
                              color: "inherit",
                              borderLeft:
                                "1px solid " +
                                (this.props.themecolors &&
                                  this.props.themecolors.themeLightColor) +
                                "66",
                              borderBottom:
                                "1px solid " +
                                (this.props.themecolors &&
                                  this.props.themecolors.textColor) +
                                "80",
                            }}
                          >
                            {this.props.canUpdate && (
                              <Tooltip
                                classes={{
                                  popper: "menu-popper",
                                  tooltip: "menu-popper-tooltip",
                                }}
                                title={this.props.translate("edit")}
                              >
                                <EditIcon
                                  className="actionButton"
                                  fontSize="small"
                                  onClick={(e) => this.props.onEdit([n])}
                                />
                              </Tooltip>
                            )}
                            {this.props.canDelete && (
                              <Tooltip
                                classes={{
                                  popper: "menu-popper",
                                  tooltip: "menu-popper-tooltip",
                                }}
                                title={this.props.translate("delete")}
                              >
                                <DeleteIcon
                                  className="actionButton"
                                  fontSize="small"
                                  onClick={(e) => this.props.onDelete([n])}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 36 * emptyRows }}>
                    <TableCell
                      colSpan={6}
                      style={{ padding: "0 10px", color: "inherit" }}
                    />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePaginationStyle
            labelRowsPerPage={this.props.translate("rowsPerPage")}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={
              searchFilter(
                dataRows,
                this.state.searchValue,
                this.props.rowDefinition
              ).length
            }
            rowsPerPage={this.state.rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
              style: {
                padding: 6,
              },
            }}
            height={44}
            nextIconButtonProps={{
              "aria-label": "Next Page",
              style: {
                padding: 6,
              },
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Style>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  (withStyles(styles)(withTranslationWrapper(EnhancedTable)))
);
