import React, { Component } from 'react'
import Layout from './../../wrapper'
import Loader from '../../Layout/Loader'
import { connect } from 'react-redux'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ViewUsers } from '../../Components/ViewUser'
import { logInUserInfo } from '../../Actions/Users'

class UserView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      RecivedUserList: false,
      sessionCallTrack: true,
      fetchApiCall: false,
      listpreRecived: false,
      listData: ''
    }
  }
  // componentWillMount () {
  //   this.props.dispatch(toast.removeAll())
  // }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.navigate('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }
  fetchUserList = id => {
    this.setState(
      {
        fetchApiCall: true
      },
      () => {
        fetch(
          `/api/users/list?userId=${this.props.logInUser.id}&all=true&limit=-1`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        ).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let data = res.data
                let usersList = data.data.slice().sort(function (a, b) {
                  let itemA = a.parentId,
                    itemB = b.parentId
                  if (itemA < itemB) return -1
                  if (itemA > itemB) return 1
                  return 0
                })
                let repList = []
                usersList.map(item => {
                  repList.push({
                    ownid: item.id,
                    id: item.id,
                    name: item.label,
                    parentId: item.parentId
                  })
                  return null
                })
                repList.unshift({ id: 1, name: 'Owner', parentId: 0 })
                let finalData = this.getNestedChildren(repList, 0)
                this.setState({
                  listData: { ...finalData },
                  listpreRecived: true
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
               
                  toast.error(
                     this.props.translate(err[1])
                )
              }
            })
          }else{
            throw response
          }
        })
        .catch(e => {
          console.log('e =',e)
          // this.props.dispatch(toast.error({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        })
      }
    )
  }
  getNestedChildren = (arr, parent) => {
    var out = []
    for (var i in arr) {
      if (arr[i].parentId === parent) {
        var children = this.getNestedChildren(arr, arr[i].id)

        if (children.length) {
          arr[i].children = children
        }
        out.push(arr[i])
      }
    }
    return out
  }

  render () {
    if (this.props.logInUser) {
      if (!this.state.fetchApiCall) {
        this.fetchUserList(this.props.logInUser.id)
      }
      if (this.props.logInUser.userType !== -1) {
        this.props.navigate('/*')
      } else {
        return (
          <Layout {...this.props}>
            {this.state.listpreRecived ? (
              <>
                <ViewUsers {...this.state} />
              </>
            ) : (
              <Loader />
            )}
          </Layout>
        )
      }
    } else {
      if (this.state.sessionCallTrack) {
        this.setState(
          {
            sessionCallTrack: false
          },
          () => {
            fetch('/api/session', {
              headers: { Accept: 'application/json; odata=verbose' }
            }).then(response => {
              if (response.ok) {
                response.json().then((res) => {
                  if (res.status === "success") {
                    // console.log("Stat====",res);
                    let userInfo = res.data;
                    this.props.setActiveLanguage(
                      userInfo?.attributes?.lang || "en"
                    );
                    this.props.dispatch(logInUserInfo(userInfo));
                  } else if (res?.statusCode === "440") {
                    window.location.replace("/login");
                  } else if (res.statusCode) {
                    var err = res?.message.split(":");
                    err[1] = err[1].replace(")", "");
                   
                      toast.error(
                         this.props.translate(err[1])
                    );
                    this.props.navigate("/login");
                  }
                });
              } else {
                this.props.navigate("/login");
                throw response;
              }
              })
              .catch(e => {
               toast.error(
                  'somethingWentWrong'
                )
              })
          }
        )
      }
      return null
    }
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  users: state.users
})

export default connect(mapStateToProps)((UserView))