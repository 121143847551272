import React, { Component } from 'react'
import TextField from './../../../Components/common/TextField'
import { connect } from 'react-redux'
import ReportIcon from '../../../assets/reports.svg'
import MaterialIcon from 'material-icons-react'
import { checkPrivileges } from '../../../Helpers'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import Button from './../../../Components/common/Button'
import { ReactComponent as TempGenIcon } from '../../../assets/nav//tem-gen.svg'
import { updateTemplates, removeTemplate } from '../../../Actions/Templates'
import { ReactComponent as NotificationOff } from '../../../assets/notification-off.svg'
import { ReactComponent as NotificationOn } from '../../../assets/notification-on.svg'
import Icon from '@mui/material/Icon'
import { Tooltip } from '@mui/material'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withTranslationWrapper from '../../../HOC/HocTranslate'

class TemplateGenerator extends Component {
  constructor (props) {
    super(props)
    this.state = { 
      searchRecently: '' ,
      allReports :'',          
  }
  }

  search (e) {
    this.setState({ searchRecently: e.target.value })
  }
//   componentWillMount () {
//       // this.fetchTempList()
//   }
//   componentWillReceiveProps () {
//     // this.fetchTempList()
// }

  // fetchTempList = () =>{
  //   let query = 'all=true&userId=' + this.props.logInUser.id
  //   this.setState({ templatesCall: true }, () => {
  //     fetch('/api/report/templates?' + query).then(response => {
  //       if (response.ok) {
  //         response.json().then(templates => {
  //           if (templates.status === 'success') {
  //             this.setState({
  //               allReports:templates.data
  //             })
  //             // console.log('templates =',templates.data)
  //             this.props.dispatch(updateTemplates(templates.data))
  //           }
  //         })
  //       }
  //     })
  //   })
  // }
  render () {
    // const dev = this.props.templates.filter(
      // if(this.state.allReports){
        const dev = this.props.templates.filter(
          item =>
            item.rpTmpl.name.toLowerCase().indexOf(this.state.searchRecently.toLowerCase()) !== -1
        )
        const geo = dev.map(g => (
          <ListRow
            key={g.rpTmpl.id}
            item={g}
            {...dev}
            onUpdate={this.props.updateTemplate}
            onDelete={this.props.deleteTemplate}
            onTemplateAssignments={this.props.onTemplateAssignments}
            changeTemplateStatus={this.props.changeTemplateStatus}
          />
        ))
     

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar'>
          {checkPrivileges('geofence') && (
            <Scrollbar>
              {/* <div>
                <div className='section-head clearfix search-form'>
                  <TextField
                    label={this.props.translate('searchTemplate')}
                    placeholder='exp: ID, Name'
                    fullWidth
                    onChange={e => this.search(e)}
                  />
                </div>
                <ul className='list-view with-padding-right'>{geo}</ul>
              </div> */}
                <div
                   style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center'
                          }}
                     className='headerDiv'      
                    >                                
                <div className='searchBar'>
                  <TextField
                    label={this.props.translate('searchTemplate')}
                    placeholder='exp: ID, Name'
                    type='search'
                    // value={this.state.search}
                    onChange={e => this.search(e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </div>
                  {/* <ul className='createBtn'> */}
                    {/* {checkPrivileges('roleCreate') && ( */}
                      {/* <li className='filter-nav'> */}
                        <Button
                          size='small'
                          onClick={e => this.props.onCreate()}
                        >
                          {' '}
                          {this.props.translate('create')}
                        </Button>
                      {/* </li> */}
                    {/* )} */}
                  {/* </ul> */}
                  </div>
                  <ul className='list-view with-padding-right'>{geo}</ul>
            </Scrollbar>
          )}
          
        </div>
      </Style>
    )
  // }
  // else {
  //   return null
  // }
  }
}

class ListRow1 extends React.PureComponent {
  constructor (props) {
    super(props)
  }
  changeTemplateError = () =>{
   
      toast.error(
        this.props.translate(('onlyOwnerCanChangeStatus'))
    )
  }
  render () {
    const item = this.props.item.rpTmpl
    return (
      <li className='list-row' key={item.id}>
        <TempGenIcon style={{
          verticalAlign: 'middle',
          width: 16,
          height: 16,
          fill: 'currentColor',
          display: 'inline-block',
          marginTop:2,
          // opacity:!this.props.item.rpTmpl.active ? '0.5':''  //to show status of active/inactive templates
          // marginBottom:2
        }} />
        {/* <svg
          fill='currentColor'
          width={16}
          height={16}
          style={{ verticalAlign: 'middle' }}
        >
          <use xlinkHref={`${ReportIcon}#icon`} />
        </svg> */}
        <span className='unit-name'  // style={{ fontSize: 12, opacity:!this.props.item.rpTmpl.active ? '0.5':'' }} to show status of active/inactive templates
         onClick={e => this.props.onUpdate(this.props.item)}>
          {item.name}
        </span>
        <div className='pull-right'>
          <span
            className='action-item hovered'
            onClick={e => this.props.onTemplateAssignments(item.id)}
          >
            <MaterialIcon
              title='Assignment'
              color='currentColor'
              size={16}
              icon='assignment'
            />
          </span>
          {checkPrivileges('rptmplUpdate') &&
          this.props.item.rpTmpl.id > 200 ? (
            <span
              className='action-item hovered'
              onClick={e => this.props.onDelete(item)}
            >
              <MaterialIcon
                title='Delete'
                color='currentColor'
                size={16}
                icon='delete'
              />
            </span>
          ) : (
            <span className='action-item hovered'>
              <MaterialIcon
                title='Cannot Delete'
                color='currentColor'
                size={16}
                icon='block'
              />
            </span>
          )}
          {checkPrivileges('rptmplDelete') &&
          this.props.item.rpTmpl.id > 200 ? (
            <span
              className='action-item hovered'
              onClick={e => this.props.onUpdate(this.props.item)}
            >
              <MaterialIcon
                title='Edit'
                color='currentColor'
                size={16}
                icon='edit'
              />
            </span>
          ) : (
            <span className='action-item hovered'>
              <MaterialIcon
                title='Cannot Edit'
                color='currentColor'
                size={16}
                icon='block'
              />
            </span>
          )}
          {(
              <span 
                className='action-item hovered' 
                onClick={this.props.logInUser.userType === -1 ? e => this.props.changeTemplateStatus(this.props.item) : this.changeTemplateError}
                >
                  <Icon className='material-icons' style={{fontSize:'18px'}} >
                        {this.props.item.rpTmpl.active ? <>
                         <Tooltip
                              classes={{
                                popper: 'menu-popper',
                                tooltip: 'menu-popper-tooltip'
                              }}
                              title={this.props.translate('activeTemplate')}>
                               <NotificationOn />
                            </Tooltip>
                         </>
                         :<>
                         <Tooltip
                              classes={{
                                popper: 'menu-popper',
                                tooltip: 'menu-popper-tooltip'
                              }}
                              title={this.props.translate('InActiveTemplate')}>
                                <NotificationOff />
                            </Tooltip>
                         </> 
                          }
                    </Icon>
              </span>
               
            )}
         
          
        </div>
      </li>
    )
  }
}

const mapState = state => ({
  geoFence: state.geoFence,
  templates: state.templates,
  themecolors: state.themeColors,
  logInUser: state.logInUsers
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withTranslationWrapper(ListRow1))
export default mapStateToProps(withTranslationWrapper(TemplateGenerator))
