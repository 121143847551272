import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
// import { themeColors as themecolors } from '../../Reducers/Theme'
import { themecolors } from './../../Layout/theme'

import { styled } from '@mui/material'

// let themecolors
const mapStateToProps = (state, ownProps) => {
  // themecolors = state.themecolors
  return {
    // themecolors: state.themecolors
  }
}
// const styles = theme => ({
//   root: {
//     color: themecolors[600],
//     '&$checked': {
//       color: themecolors[500]
//     }
//   },
//   checked: {}
// })
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: themecolors["600"],
  '&.Mui-checked': {
    color: themecolors["500"],
  },
}));
class CheckboxLabels extends Component {
  constructor (props) {
    super(props)
    this.state = {
      checked: props.checked
    }
  }
  handleChange = name => event => {
    this.setState({ checked: event.target.checked })
    if (this.props.onChange) {
      this.props.onChange(event, name)
    }
  }

  render () {
    const { classes } = this.props
    return (
      <FormControlLabel
        control={
          <CustomCheckbox
            checked={this.state.checked}
            onChange={this.handleChange(this.props.id)}
            // classes={{
            //   root: classes.root,
            //   checked: classes.checked
            // }}
            {...this.props}
          />
        }
        label={this.props.label || ''}
      />
    )
  }
}

export default connect(mapStateToProps)((CheckboxLabels))
