import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@mui/styles";

import { FormControl, Grid, ListItemText, MenuItem, Select } from "@mui/material";
// import { withLocalize } from "react-localize-redux";
import 'react-toastify/dist/ReactToastify.css';

import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
// import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
// import DateFnsUtils from "@date-io/date-fns";
import SingleSelect from "../common/SingleSelect";
import { InputLabel } from "@mui/material";
import { connect } from "react-redux";
import CustomDatePicker from "../common/CustomDatePicker";



let themecolors;
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors;
  return {
    themecolors: state.themecolors,
  };
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const IssuingParty = [
  {
    key: "National Transportation Solutions Company",
    name: "National Transportation Solutions Company",
  },
];

const Remarks = [
  {
    key: "Vehicle does not belong to Fleet",
    name: "Vehicle does not belong to Fleet",
  },
  {
    key: "Not Approved Items",
    name: "Not Approved Items",
  },
  {
    key: "Price Difference",
    name: "Price Difference",
  },
  
  {
    key: "Traffic Voilation",
    name: "Traffic Voilation",
  },
  {
    key: "Service Charges",
    name: "Service Charges",
  },
  {
    key: "Battery Charges",
    name: "Battery Charges",
  },
  {
    key: "Off Road Vehicles",
    name: "Off Road Vehicles",
  },
  {
    key: "Others",
    name: "Others",
  },
];

const DebitAgainstTypes = [
  {
    key: "creditNote",
    name: "Credit Note",
  },
  {
    key: "payment",
    name: "Payment",
  },
  {
    key: "open",
    name: "Open",
  }
];


class AddDebitModal extends Component {
  constructor() {
    super();
    this.state = {
      loader:false,
      showMore:20,
      showItemList:false,
      totalAmount:0,
      totalAmount2:0,
      showLists:false
    };
  }


  render() {
    const { form, tranalate} = this.props;
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          isVisableBtn={this.props.isVisible}
          content={
            <>
                <Grid container spacing={2}>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      disabled
                      id="type"
                      label={this.props.translate("type")}
                      placeholder=""
                      value={form?.creditDebitNote?.type || ""}
                      onChange={this.props.handleChange("type")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      // select
                      required
                    >
                    </TextField>
                  </Grid>
                 
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="issuingParty"
                      label={this.props.translate("issuingParty")}
                      required
                      select
                      placeholder=""
                      value={form?.creditDebitNote?.issuingParty || ""}
                      onChange={this.props.handleChange("issuingParty")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      // disabled={this.props.editOption ? "disabled" : ""}
                    >
                      {IssuingParty.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.name)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}style={{zIndex:10}} >
                    <SingleSelect
                      array={
                        (this.props.recievingAccounts && this.props.recievingAccounts) || []
                      }
                      async
                      selectName="receivingParty"
                      label={this.props.translate("receivingParty")}
                      value={
                        form?.creditDebitNote?.receivingParty &&
                        form?.creditDebitNote?.receivingParty.id
                          ? {
                              id: form?.creditDebitNote?.receivingParty
                                .contractNumber,
                              value:
                                form?.creditDebitNote?.receivingParty
                                  .contractNumber,
                              label:
                                form?.creditDebitNote?.receivingParty
                                  .name ,
                              uniqueId:
                                form?.creditDebitNote?.receivingParty
                                  .contractNumber,
                            }
                          : "receivingParty"
                      }
                      handleChange={this.props.handleChangeAccount}
                      canAssign={true}
                    />
                 
                  </Grid>
                 
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="refNum"
                      label={this.props.translate("refNum")}
                      placeholder=""
                      value={form?.creditDebitNote?.refNum}
                      onChange={this.props.handleChange("refNum")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                  <CustomDatePicker
                                  format="dd/MM/yyyy"
                                  error={false}
                                  label={this.props.translate(
                                    "date"
                                  )}
                                  fullWidth={true}  
                                  value={
                                    form?.creditDebitNote?.creditDebitDate &&
                                    form?.creditDebitNote?.creditDebitDate
                                      ? 
                                          `${form?.creditDebitNote?.creditDebitDate}`
                                      : null
                                  }
                                  onChange={this.props.handleChangeDate(
                                    "creditDebitDate"
                                  )}
                                />
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        margin="dense"
                        label={this.props.translate("date")}
                        variant="outlined"
                        fullWidth
                        minDate={new Date()} // for check not to select date before today
                        required
                        value={
                          form?.creditDebitNote?.creditDebitDate
                            ? new Date(
                                `${form?.creditDebitNote?.creditDebitDate}`
                              ).toString()
                            : null
                        }
                        onChange={this.props.handleChangeDate(
                          "creditDebitDate"
                        )}
                        InputProps={{
                          classes: {
                            root: "theme-cssOutlinedInput",
                            input: "theme-input",
                            focused: "theme-cssFocused",
                            notchedOutline: "theme-notchedOutline",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "theme-label",
                            focused: "theme-label-focused",
                            shrink: "theme-label-shrink",
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider> */}
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <FormControl fullWidth variant="outlined" size="small"  margin="dense">
                      <InputLabel id="remarks-label">{this.props.translate("remarks *")}</InputLabel>
                      <Select
                        labelId="remarks-label"
                        id="remarks"
                        multiple
                        margin="dense"
                        size = "small"
                        value={form?.creditDebitNote?.remarks || []}
                        onChange={this.props.handleChange("remarks")}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((value) => (
                              <div key={value}>{this.props.translate(value)}</div>
                            ))}
                          </div>
                        )}
                      >
                        {Remarks.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            <Checkbox checked={form?.creditDebitNote?.remarks?.includes(option.key)} />
                            <ListItemText primary={this.props.translate(option.name)} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                

                  <Grid item md={4} sm={6} xs={12}>
                
                            <TextField
                              id="debitAgainst"
                              label={this.props.translate("debitAgainst")}
                              required
                              select
                              placeholder=""
                              value={this.props.debitAgainst || ""}
                              onChange={this.props.handleChange("debitAgainst")}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            >
                              {DebitAgainstTypes.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                  {this.props.translate(option.name)}
                                </MenuItem>
                              ))}
                            </TextField>
                </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Checkbox 
                          checked={this.props.zeroVat}
                          label={this.props.translate('zeroVat')}
                          value='zeroVat'
                          color='primary'
                          // disabled
                          onChange={this.props.handleChange('zeroVat')}
                        /> 
                    </Grid>
                </Grid>                                                      

               <Grid container spacing={2}>             
                  <Grid item md={4} sm={6} xs={12} style={{zIndex:10}}>
                       {this.props.debitAgainst === 'payment' ? 
                       <>
                        <SingleSelect
                          array={ this.props?.filteredPayments || []   }
                          async
                          selectName="selectedPayment"
                          label={this.props.translate("selectedPayment")}
                          value={
                            this.props.selectedPayment && this.props.selectedPayment.id
                              ? {
                                  id: this.props.selectedPayment.id,
                                  value: this.props.selectedPayment.paymentNumber,
                                  label: this.props.selectedPayment.paymentNumber ? this.props.selectedPayment.paymentNumber : "" ,
                                  name:  this.props.selectedPayment.paymentNumber ? this.props.selectedPayment.paymentNumber : "" ,
                                  uniqueId: this.props.selectedPayment.paymentNumber,
                                }
                              : "selectedPayment"
                          }
                          handleChange={this.props.handleChangeAccount}
                          canAssign={true}
                         />
                       </>

                                  : this.props.debitAgainst === 'creditNote' ? 
                                  <>
                                  <SingleSelect
                                      array={ this.props?.filteredCreditNotes || []  }
                                      async
                                      selectName="creditNote"
                                      label={this.props.translate("creditDebitNoteNumber")}
                                      value={
                                        this.props.creditNote && this.props.creditNote.id
                                          ? {
                                              id: this.props.creditNote.id,
                                              value: this.props.creditNote.creditDebitNoteNumber,
                                              label: this.props.creditNote.creditDebitNoteNumber ? this.props.creditNote.creditDebitNoteNumber : "" ,
                                              name:  this.props.creditNote.creditDebitNoteNumber ? this.props.creditNote.creditDebitNoteNumber : "" ,
                                              uniqueId: this.props.creditNote.creditDebitNoteNumber,
                                            }
                                          : "creditNote"
                                      }
                                      handleChange={this.props.handleChangeAccount}
                                      canAssign={true}
                                    />
                                  </>
                              
                              : null }
                    </Grid>


          <Grid item md={11} sm={12} xs={12}>
          {(this.props.debitAgainst === 'payment' || this.props.debitAgainst === 'creditNote') && (
              <p style={{color:this.props.debitAmountError ? "red" : ""}}>
                  {tranalate("Total Balance")} = {" "}
                  <b>
                    {this.props.selectedPayment?.taxableAmount ? parseFloat(this.props.selectedPayment.taxableAmount)?.toFixed(2) : 0}
                    </b>
                    <br></br>
                    <br></br>
                      {this.props.debitAmountError ? <p style={{color:"red"}}>{this.props.debitAmountError}</p>  : " " }
                      <br></br>
                </p>
            )}
          </Grid>
                  <Grid container spacing={2}>
                      <Grid item md={11} sm={12} xs={12}>
                      <Grid  container  spacing={2}   >
                          <Grid item md={2} sm={6} xs={12}>
                            </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            </Grid>
                          <Grid item md={2} sm={6} xs={12}></Grid>
                          <Grid item md={2} sm={6} xs={12}></Grid>
                          <Grid item md={2} sm={6} xs={12}><h4>{this.props.translate("netAmount")}</h4></Grid>
                          <Grid item md={1} sm={2} xs={4}><h4>{this.props.translate("vatAmount")}</h4></Grid>
                          <Grid item md={1} sm={6} xs={12}><h4>{this.props.translate("Total Amount (with VAT)")}</h4></Grid>
                        </Grid>
                    </Grid>
                      </Grid>
                      <Grid container spacing={2} key={1}>
                          <Grid item md={11} sm={12} xs={12}>
                            <Grid
                              container
                              spacing={2}
                              style={{margin:"10px 0px"}}
                            >
                              <Grid item md={2} sm={6} xs={12}>
                                </Grid>
                                <Grid item md={2} sm={6} xs={12}>
                                </Grid>
                                <Grid item md={2} sm={6} xs={12}>
                                </Grid>
                                <Grid item md={2} sm={6} xs={12}>
                                </Grid>
                                <Grid item md={2} sm={6} xs={12}>
                                <TextField
                                  id="netAmount"
                                  label={this.props.translate("netAmount")}
                                  required
                                  placeholder=""
                                  value={this.props.selectedPayment?.netAmount || 0}
                                  onChange={this.props.handleChange("netAmount")}
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  type="number"
                                />
                              </Grid>
                              <Grid item md={1} sm={2} xs={4}>
                              <h5 style={{marginTop:"15px"}} >
                              {this.props.selectedPayment?.vatPercent || 0}
                                </h5>
                              </Grid>
                              
                              <Grid item md={1} sm={2} xs={4}>
                              <h5 style={{marginTop:"15px"}} >
                                  {this.props.selectedPayment?.totalAmount || 0}
                                </h5>
                              </Grid>
                              
                            </Grid>
                          </Grid>
                        </Grid>
               </Grid>  
                     
             </>
          }
        />
     
      </Fragment>
    );
  }
}
 
export default connect(mapStateToProps)(
  (withStyles(styles)(AddDebitModal))
);

