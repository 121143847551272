import React, { Component } from "react";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

import { Grid } from "@mui/material";
import Button from "../common/Button";

import CustomDatePicker from "../common/CustomDatePicker";


class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillMount() {
  }



render() {
    return (
      <Grid>
        
          <CashCustomerForm 
            {...this.state}
            {...this.props}
       />
        
      </Grid>
    );
  }
}


const CashCustomerForm = ({
  translate,
  form,
  handleChange,
  handleChangeLeasingItems,
  closeInvoiceForm
  
}) => {
  return (
    <div style={{ padding: 10 }}>
      <h4 className="page-title">{translate("cashInvoice")}</h4>
      <Grid container spacing={1}>
      <Grid item md={3} sm={6} xs={12}>
      {/* <SingleSelect
                // array={invoiceData && invoiceData.data || []}
                async
                selectName="cashAccount"
                isClearable
                label={translate("contractNumber")}
                // value={
                //   form &&
                //   form.contractNumber?.id
                //     ? {
                //         id: form.contractNumber.contractNumber,
                //         value:
                //         form.contractNumber.contractNumber,
                //         label: form.contractNumber.name || form.contractNumber.accountName,
                //       }
                //     : ""
                // }
                // value={this.props.form.contractNumbers || ""}
                // handleChange={handleChangeForAccount}
                canAssign={true}
              /> */}
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                     <CustomDatePicker
                        format="dd/MM/yyyy"
                        error={false}
                        label={this.props.translate(
                          "from"
                        )}
                        fullWidth={true}  
                        value={
                          form.from &&
                          form.from
                            ? 
                                `${form.from}`
                            : null
                        }
                        onChange={(e) => handleChange("from", e)}
                      />
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format="MM/yyyy"
                  required
                  // error={false}
                  margin="dense"
                  label={translate("from")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month"]}
                  error={
                    !form?.from
                    // ||   moment(1, "DD").startOf("day").toISOString()
                  }
                  helperText={
                    !form?.from
                      ? translate("enterDate")
                      : ""
                  }
                  // value={this.props?.form?.from || ""}
                  value={
                    form && form.from
                      ? new Date(`${form.from}`)
                      : null
                  }
                  // allowEmpty={true}
                  onChange={(e) => handleChange("from", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
            </LocalizationProvider> */}
        </Grid>  
     
        
      </Grid>

      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeInvoiceForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>

        <Button
          size="small"
        //   onClick={submitForm}
        //   disabled={!isVisableSubmitBtn}
        >
          {/* {translate(mode === "update" ? "update" : "sharedCreate")} */}
        </Button>
      </div>
    </div>
  );
};






const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
});

export default connect(mapStateToProps)((CreateInvoice));
