import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import Table from '../../../common/tableWithBackEndPagination'
import {
  prepareUsers,
  checkUserRoles,
  prepareUserForLogin,
  PaginationConfig
} from '../../../../Helpers'
import LoginAsUser from '../../../../Helpers/loginAsUser'
import Loader from '../../../../Layout/Loader'
import { Grid, Tooltip } from '@mui/material'
import TextField from '../../../common/TextField'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class User extends Component {
  constructor () {
    super()
    this.state = {
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      usersReceived: false,
      selectedAccUsersList: '',
      total: '',
      lastPage: '',
      noRecords:false
    }
  }
  componentWillMount () {
    this.getMoreUsers()
  }
  getMoreUsers = () => {
    let { page, pageSize, itemSearch } = this.state.pagination
    fetch(
      `/api/users/get?userId=${this.props.selectItemId.id}&all=true&&page=${page}&limit=${pageSize}&search=${itemSearch}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if(res.status ==='success'){
            let user = res.data
            if (user?.data?.length) {
              let user1 = user.data.map((item) => this.props.replaceHtmlEntities(item))
              let lastPage = user.total / pageSize
              let IsFloate = this.checkFloteNumber(lastPage)
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  hasNext: user.hasNext,
                  lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                  total: user.total
                },
                selectedAccUsersList: prepareUserForLogin(
                  checkUserRoles(prepareUsers(user1), this.props.roles)
                ),
                usersReceived: true,
                noRecords: false
              })
            } else {
              this.setState({
                usersReceived: false,
                selectedAccUsersList: [],
                noRecords: true
              })
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
           else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")

              toast.error(
              this.props.translate(err[1])    
            )
          }
        })
      } else {
        throw response
      }
    }).catch(e => {})
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  loginAsUser = data => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false
        },
        () => {
          this.setState({
            isUserLogin: true
          })
        }
      )
    }
  }
  loginAsUserhandleChange = (id, value) => {
    let { selectedAccUsersList } = this.state
    selectedAccUsersList.map(item => {
      if (item.id === id) {
        item.logInAsUserState = value
      }
    })
    this.setState({
      selectedAccUsersList
    })
  }
  handleChangeRowsPerPage = value => {
    let { page, pageSize } = this.state.pagination
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => {
        if (page * pageSize >= value) {
          this.getMoreUsers()
        }
      }
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUsers()
    )
  }
  SearchItem = searchValue => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getMoreUsers()
    )
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
          <Grid container className="breadcrumb-row">
               <Grid item xs={12} sm={3}>
                   <h3 style={{margin: 0}}>{this.props.translate('users')}</h3>
                </Grid>
                    <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                      <div style={{ marginRight: '20px', width: "25%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                      </div>
                  </Grid>
               </Grid>
        {this.state.usersReceived && !this.state.noRecords ? (<>       
          <Table
            rows={this.state.selectedAccUsersList || []}
            pagination={this.state.pagination}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleChangePage={this.handleChangePage}
            loginAsUserhandleChange={this.loginAsUserhandleChange}
            loginAsUser={this.loginAsUser}
            ServerSetting={this.props.ServerSetting}
            rowsPerPage={10}
            isEditable={true}
            themecolors={this.props.themecolors}
            rowDefinition={rowDefinitionR(this.props.translate)}
          /></>
          ) : 
            !this.state.usersReceived && !this.state.noRecords ?
             (<div style={{ textAlign: 'center' }}>
                  <Loader defaultStyle />
            </div>)
          : null
          }
          {this.state.noRecords && (
              <div style={{ textAlign: 'center' }}>
                  <h4> {this.props.translate('noUserFound')}</h4>
              </div>
          )}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(User)

const rowDefinitionR = translate => {
  if (localStorage.getItem('adminToken')) {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parent')
      },
      {
        id: 'accountName',
        numeric: false,
        disablePadding: false,
        label: translate('accountName')
      }
    ]
  } else {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parent')
      },
      {
        id: 'accountName',
        numeric: false,
        disablePadding: false,
        label: translate('accountName')
      },
      {
        id: 'loginAsUser',
        numeric: false,
        disablePadding: false,
        label: translate('loginAsUser')
      }
    ]
  }
}
