import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "./Checkbox";
import Style from "style-it";
import SearchField from "../common/SearchField";
import 'react-toastify/dist/ReactToastify.css';
import Scrollbar from "react-scrollbars-custom";
import instance from "../../axios";
import Loader from "../../Layout/Loader";
import withTranslationWrapper from "../../HOC/HocTranslate";

class AccountSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      vehicles: [],
      // isChecked: this.props.sendAll ? true : false,
      // list: this.props.sendAll ? this.props.makingAccounts : {},
      // usersId: this.props.sendAll ? this.props.makingUsers : {},
      isChecked:false,
      list:{},
      usersId: {},
      loader: true,
    };
    // this.toggleItems = this.toggleItems.bind(this);
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      usersId: {},
      search: "",
      isChecked: false,
    });
  }
  componentWillMount() {
    let list = {};
    let usersId = {};

    if (this.props.value && this.props.value.length) {
      this.props.value.map((d) => (list[d] = true));
      this.setState({ list, loader: false });
    }
    if (this.props.value2 && this.props.value2.length) {
      this.props.value.map((d) => (usersId[d] = true));
      this.setState({ usersId, loader: false });
    }
    // if (this.props.type === "reports") {
    //   this.setState({
    //     vehicles: this.props?.data || [],
    //     list: {},
    //     loader: false,
    //   });
    // } 
 
    // else {
      this.getAccounts();
    // }
    if (this.props.sendAll) this.sendToParent();
  }

  componentWillReceiveProps(nextprops) {
    let list = {};
    let usersId = {};
    if (nextprops.value && nextprops.value.length) {
      nextprops.value.map((d) => ((list[d] = true), (usersId[d] = true)));
    }
    this.setState({ list });
    const prevProps = this.props;
    if(prevProps.tab !== nextprops.tab){
      this.setState({
        isChecked: false
      })
    }
  }

  sendToParent() {
    if (this.props.onChange) {
      this.props.onChange(
        Object.keys(this.state.list),
        Object.keys(this.state.usersId)
      );
    }
  }

  onChange = (e, v) => {
    let list = { ...this.state.list };
    let usersId = { ...this.state.usersId };
    if (e.target.checked) {
      list[v.contractNumber] = true;
      usersId[v.userId] = true;
    } else {
      delete list[v.contractNumber];
      delete usersId[v.userId];
    }
    this.setState(
      {
        usersId,
        list,
        isChecked: this.props.vehicles?.length === Object.keys(list).length,
      },
      () => {
        this.sendToParent();
      }
    );
  };
  searchItem = (text) => {
  //    if (this.props.type === "reports") {
  //     this.setState({ search: text.target.value }, () => {
  //     this.props.getContractsData(this.props.reportKey);
  //     });
  // }
  // else{
    this.setState({ search: text.target.value }, () => {
      this.getAccounts();
    });
  // }
  };

  toggleItems = (event) => {
    if (event.target.checked) {
      let list = {};
      let usersId = {};
      this.state.vehicles.map(
        (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
      );

      this.setState({ list, usersId, isChecked: true }, () => {
        this.sendToParent();
      });
    } else {
      this.setState({ list: {}, usersId: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
  };

  getAccounts = () => {
    let apiUrl;
    if (this.props.type === "reports") {
      if(this.props.contractType === 1){
       if(this.props.reportKey === 101){
        apiUrl = `/api/accounts/list?all=true&contractType=1&contractType=3&userType=3&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.search}`;
       }
       else{
         apiUrl = `/api/accounts/list?all=true&contractType=1&userType=3&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.search}`;
       }
      }
      else if(this.props.contractType === 2){
        apiUrl = `/api/accounts/list?all=true&userId=${this.props.logInUser.id}&userType=3&contractType=2&limit=-1&search=${this.state.search}`;
      }
    }
    else if (this.props.type === "commutator"){
     apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&all=true&limit=-1&search=${this.state.search}`;

    }
    else {
    apiUrl = `api/accounts/get?userId=${
      this.props.logInUser.id
    }&contractType=${this.props.contractType}&all=true&search=${this.state.search}&limit=${-1}`
    }
    //  apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&contractType=${cc}&all=true&page=${page}&limit=-1&search=${searchInvoiceText}`;
    if (this.props.logInUser && this.props.logInUser.id) {
      instance({
        method: "GET",
        url:apiUrl ,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({ vehicles: res.data, loader: false });
          if (this.props.type === "reports") {
            let list = {};
            let usersId = {};
            this.state.vehicles.map(
              (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
            );
      
            this.setState({ list, usersId, isChecked: true }, () => {
              this.sendToParent();
            });
          }
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };

  render() {
  
    return (
      <>
        {this.state?.loader === false ? (
          <Style>
            {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
         
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
        }
        .selector-list {
            padding: 0;
            margin: 0;
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}

            <div>
              <div
                className="section-head clearfix section-head-filter"
                style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
              >
                <ul className="filter-row pull-left">
                  <li>
                    <SearchField
                      label={this.props.translate("searchAccounts")}
                      type="search"
                      placeholder="exp: ID, Name"
                      value={this.state.search}
                      onChange={this.searchItem}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </li>
                </ul>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate("selectAll")}
                />
              </div>
              <div className="selector-count">
                {Object.keys(this.state.list).length}{" "}
                {this.props.translate("AccountsSelected")}
              </div>
              <Scrollbar
                disableTracksWidthCompensation={true}
                style={{
                  height:
                    (this.props.minHeight
                      ? parseInt(this.props.minHeight)
                      : 35) * (this.props.rows || 5),
                }}
                scrollerProps={{
                  renderer: (props) => {
                    const { elementRef, ...restProps } = props;
                    return (
                      <div {...restProps} ref={elementRef} id="scrollableDiv" />
                    );
                  },
                }}
              >
                <ul className="selector-list">
                  {this.state.vehicles &&
                    this.state.vehicles.map((row) => (
                      <li key={row.contractNumber}>
                        <label
                          className={
                            this.state.list[row.contractNumber]
                              ? "active"
                              : null
                          }
                        >
                          <span className="selector-check">
                            <Checkbox
                              checked={this.state.list[row.contractNumber]}
                              // checked
                              value={row}
                              onChange={this.onChange}
                              // onClick={() => this.props.selecteItem(this.props.data)}
                            />
                          </span>
                          <span className="selector-label">
                            {row.name || row.accountName || row.label}
                          </span>
                        </label>
                      </li>
                    ))}
                </ul>
              </Scrollbar>
            </div>
          </Style>
        ) : (
          <Loader component="filter" />
        )} 
      </>
    );
  }
}

const mapState = (state) => ({
  vehicles: state.vehicles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withTranslationWrapper(AccountSelector));
