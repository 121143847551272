import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MultiselectTwoSides from 'react-multiselect-two-sides'
// import 'react-multiselect-two-sides/style.css'
// import Select from 'react-select';
import DualListBox from '../../../common/CustomDualListBox';

import Loader from '../../../../Layout/Loader'
import { checkPrivileges } from '../../../../Helpers'
import './style.scss'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Report extends Component {
  constructor () {
    super()
    this.state = {
      isReportSet: false,
      assignReportIsRecived: false,
      selectedUseReportsList: '',
      loaderOption: true,
      multiselect: {
        options: [],
        value: []
      },
      loginUserReportList: ''
    }
    this.onReportAssignToUserSubmit = this.onReportAssignToUserSubmit.bind(this)
    this.reportAssignSubmit = this.reportAssignSubmit.bind(this)
  }
  componentWillMount () {
    let data1Rec = false
    let data2Rec = false
    fetch(`/api/reports?userId=${this.props.logInUser.id}&all=true`).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if(res.status==='success'){
            this.setState(
              {
                loginUserReportList: res.data
              },
              () => {
                data1Rec = true
                if (data1Rec && data2Rec) {
                  this.setUnits()
                }
              }
            )
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
     
              toast.error( this.props.translate(err[1])
         
            )
          }
        })
      }
      else{
        throw response
      }
    }).catch(e => {})
    fetch(`/api/reports?userId=${this.props.selectItemId.id}&all=true`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if(res.status==='success'){
            this.setState(
              {
                selectedUseReportsList: res,
                assignReportIsRecived: true
              },
              () => {
                data2Rec = true
                if (data1Rec && data2Rec) {
                  this.setUnits()
                }
              }
            )
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
       
              toast.error( this.props.translate(err[1])

            )
          }
        })
      } else {
        throw response
      }
    }).catch(e => {})
  }
  setUnits = () => {
    let {
      selectedUseReportsList,
      loginUserReportList,
      assignReportIsRecived
    } = this.state
    let options = [],
      value = []
    if (loginUserReportList.length && assignReportIsRecived) {
      loginUserReportList.map(item => {
        options.push({ name: item.name, value: item.id })
        if (selectedUseReportsList.data.length) {
          let data = selectedUseReportsList.data.filter(
            dec => dec.id === item.id
          )
          if (data.length) {
            value.push(item.id)
          }
        }
      })
      this.setState({
        loaderOption: false,
        isReportSet: true,
        multiselect: {
          ...this.state.multiselect,
          options,
          value
        }
      })
    } else {
      this.setState({
        loaderOption: false
      })
    }
  }
  symmetricDifference = (a1, a2) => {
    var a = [],
      diff = []

    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true
    }

    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]]
      } else {
        a[a2[i]] = true
      }
    }

    for (let k in a) {
      diff.push(k)
    }

    return diff
  }

  handleChange2 = (val,op) => {
    let value = [...this.state.multiselect.value];  // Clone the array to avoid direct mutation of state

    if (op === "assign") {
      if (!value.includes(val)) {
        value.push(val);  // Push the value to the array
      }
    } else if (op === "delete") {
      value = value.filter((item) => item !== val);
    }
    
    this.setState(
      {
        loaderOption: true
      },
      () => {
        let prvValue = this.state.multiselect.value
        let pormissionData = ''
        let method = 'DELETE'
        if (value.length) {
          if (value.length < prvValue.length) {
            method = 'DELETE'
            pormissionData = this.symmetricDifference(prvValue, value)
          } else {
            method = 'POST'
            pormissionData = this.symmetricDifference(prvValue, value)
          }
        } else {
          pormissionData = prvValue
        }
        if (value.length < prvValue.length) {
        }
        this.setState(
          {
            multiselect: {
              ...this.state.multiselect,
              value
            }
          },
          () => {
            this.onReportAssignToUserSubmit(pormissionData, method)
          }
        )
      }
    )
  }
  onReportAssignToUserSubmit (item, option) {
    if (item.length === 1) {
      let obj = {
        userId: this.props.selectItemId.id,
        rpId: parseInt(item[0])
      }
      this.reportAssignSubmit(true, option, obj)
    } else {
      item.map(
        elm => {
          let obj = {
            userId: this.props.selectItemId.id,
            rpId: [parseInt(elm)]
          }
          this.reportAssignSubmit(false, option, obj)
        },
        () => {
          this.setState({
            loaderOption: false
          })
        }
      )
    }
  }

  reportAssignSubmit = (single, option, obj) => {
    if (single) {
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          response.json().then(res => {   
            if (res.status === 'success') {
              this.setState({
                loaderOption: false
              })
              if (option === 'POST') {
       
                  toast.success(this.props.translate('ReportIsAssignedSuccessfully')
            
                )
              } else {
             
                  toast.success( this.props.translate(
                      'ReportIsUnAssignedSuccessfully'
                    )
            
                )
              }
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
             else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
   
                toast.error( this.props.translate(err[1])
           
              )
            }
          })
          })
        .catch(e => {
         toast.error( 'somethingWentWrong'
           )
        })
    } else {
      fetch(`/api/permissions/multiproperty`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          response.json().then(res => {
            if(res.status ==='success'){
              this.setState({
                loaderOption: false
              })
              if (option === 'POST') {
             
                  toast.success(this.props.translate(
                      'multiReportIsAssignedSuccessfully'
                    )
                )
              } else {
      
                  toast.success( this.props.translate(
                      'multiReportIsUnAssignedSuccessfully'
                    )
                )
              }
             }
             else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
             else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
      
                toast.error( this.props.translate(err[1])
     
              )
            }
          })
          // } 
          // else {
          //   throw response
          // }
        })
        .catch(e => {
        toast.error( 'somethingWentWrong'
           )
        })
    }
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
        {this.state.loaderOption ? (
          <div style={{ textAlign: 'center' }}>
            <Loader defaultStyle />
          </div>
        ) : (
          <>
            {this.state.isReportSet ? (
               <>
               <DualListBox
                availableTitle="Available"
                assignTitle="Assign"
            
                onChange={this.handleChange2}
                availableItems={this.state.multiselect?.options || []}
                assignedItems={this.state.multiselect?.value  || []}
                availableFooter={this.props.translate('available') + ' : ' + (this.state.multiselect.options?.length - this.state.multiselect.value?.length)}
                assignFooter={this.props.translate('assign') + ' : ' + this.state.multiselect.value?.length}
              />
              {/* <Select
                {...this.state.multiselect}
                className='msts_theme_example'
                onChange={
                  checkPrivileges('userLinkRp') &&
                  checkPrivileges('userUnlinkRp')
                    ? this.handleChange2
                    : ''
                }
                availableHeader={this.props.translate('available')}
                selectedHeader={this.props.translate('assign')}
                labelKey='name'
                showControls
                searchable
                availableFooter={
                  this.props.translate('available') +
                  ' : ' +
                  (this.state.multiselect.options.length -
                    this.state.multiselect.value.length)
                }
                selectedFooter={
                  this.props.translate('assign') +
                  ' : ' +
                  this.state.multiselect.value.length
                }
              /> */}
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <h4> {this.props.translate('noReportsFound')}</h4>
              </div>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Report)
