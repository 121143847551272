import React from "react";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "../common/Button";
import {
  Adjust,
  CheckCircle,
  FiberManualRecord,
  Info,
  Email,
  GetApp,
} from "@mui/icons-material";
import { StepConnector } from "@mui/material";
import ExportFile from "../common/ExportFile";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    height: "30px",
    minWidth: "65px",
    // minWidth: 94
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    textAlign: "right",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function CircleStepIcon(props) {
  const { active, completed, error, ...rest } = props;

  return (
    <div {...rest}>
      {completed ? (
        <CheckCircle />
      ) : active ? (
        <Adjust />
      ) : error ? (
        <Info />
      ) : (
        <FiberManualRecord />
      )}
    </div>
  );
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const steps = props.steps || [];
  return (
    <div className="stepper-wrapper">
      <Stepper
        activeStep={props.activeStep}
        orientation="vertical"
        elevation={0}
        classes={{ root: "stepper" }}
        connector={
          <StepConnector
            classes={{
              root: "step-connector",
              active: "step-connector-active",
            }}
          />
        }
      >
        {steps.map((step, index) => (
          <Step key={step.id}>
            <StepLabel
              StepIconComponent={CircleStepIcon}
              classes={{
                root: "step-label-root",
                label: "step-label",
                active: "step-label-active",
                completed: "step-label-completed",
              }}
            >
              {props.translate(step.title)}
            </StepLabel>
            <StepContent>
              {props.getStepContent(step.id)}
              {step.errorMsg ? step.errorMsg : null}
              <div className={classes.actionsContainer}>
                <div>
                  <div
                    style={
                      props.activeStep === steps.length - 1 &&
                      props.reportType &&
                      props.download &&
                      props.emails
                        ? { display: "flex", justifyContent: "center" }
                        : {}
                    }
                  >
                    <Button
                      disabled={props.activeStep === 0}
                      onClick={(e) => props.handleBack(step.id)}
                      className={classes.button}
                    >
                      {props.translate("sharedBack")}
                    </Button>

                    {props.data === "reports" ? (
                      <>
                        {/* {console.log("===?", props.valid)} */}
                        {props.activeStep === steps.length - 1 ? (
                          ""
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) =>
                              props.handleNext(step.id, "", index)
                            }
                            disabled={props.isDisabled&&props.activeStep === 2}
                            className={classes.button}
                            // disabled={props.valid1 ? "" : "disabled"}
                          >
                            {props.translate("next")}
                          </Button>
                        )}
                      </>
                    ) : props.data === "invoices" ||
                      props.data === "payment" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => props.handleNext(step.id, "", index)}
                        className={classes.button}
                   
                      >
                        {(props.activeStep === steps.length - 1 &&
                          props.reportType) ||
                        (props.activeStep === steps.length - 1 &&
                          props.isSubmitBtn)
                          ? props.translate("submit")
                          : props.translate("next")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* {props.activeStep === steps.length - 1 && props.reportType && props.download && props.emails ? */}
                  {props.data === "invoices" &&
                  props.value === "maintenance" &&
                  props.activeStep === steps.length - 1 ? (
                    <div>
                      <div
                        className="report-sidebar-switch"
                        style={{ marginTop: 15 }}
                      ></div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          // downloadInvoices={this.props.downloadInvoices}
                          onClick={(e) =>
                            props.handleNext(step.id, "downloadExcel", index)
                          }
                          className={classes.button}
                        >
                          <GetApp />
                          {props.translate("Excel")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            props.handleNext(step.id, "downloadPdf", index)
                          }
                          className={classes.button}
                        >
                          <GetApp />
                          pdf
                        </Button>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={e => props.handleNext(step.id, 'summary', index)}
                          className={classes.button}
                        >
                          
                          Summary
                        </Button> */}
                      </div>
                    </div>
                  ) : null}

                  {props.data === "payment" &&
                  props.activeStep === steps.length - 1 ? (
                    <div>
                      <div
                        className="report-sidebar-switch"
                        style={{ marginTop: 15 }}
                      ></div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          // downloadInvoices={this.props.downloadInvoices}
                          onClick={(e) =>
                            props.handleNext(step.id, "downloadExcel", index)
                          }
                          className={classes.button}
                        >
                          <GetApp />
                          {props.translate("Excel")}
                        </Button>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={e => props.handleNext(step.id, 'downloadPdf', index)}
                          className={classes.button}
                        >
                          <GetApp/>
                          pdf
                        </Button> */}
                      </div>
                    </div>
                  ) : null}

                  {props.data === "reports" &&
                  props.activeStep === steps.length - 1 ? (
                    <div>
                      <div
                        className="report-sidebar-switch"
                        style={{ marginTop: 15 }}
                      ></div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          // downloadInvoices={this.props.downloadInvoices}
                          onClick={(e) =>
                            props.handleNext(step.id, "downloadExcel", index)
                          }
                          className={classes.button}
                        >
                          <GetApp />
                          {props.translate("Excel")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            props.handleNext(step.id, "downloadPdf", index)
                          }
                          className={classes.button}
                        >
                          <GetApp />
                          {props.translate("PDF")}
                        </Button>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          // downloadInvoices={this.props.downloadInvoices}
                          onClick={(e) =>
                            props.handleNext(step.id, "emailExcel", index)
                          }
                          className={classes.button}
                        >
                          <Email style={{ marginRight: 5 }} />

                          {props.translate("Excel")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          // downloadInvoices={this.props.downloadInvoices}
                          onClick={(e) =>
                            props.handleNext(step.id, "emailPdf", index)
                          }
                          className={classes.button}
                        >
                          <Email style={{ marginRight: 5 }} />

                          {props.translate("PDF")}
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  {/* {props.invoices && props.activeStep === 2 &&(
                      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={e => props.downloadInvoice()}
                          className={classes.button}
                        >
                          <GetApp/>
                          {props.translate('Excel')} 
                     
                        </Button>
                      </div>
                  )} */}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
