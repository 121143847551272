import React, { Component, Fragment } from 'react'
import User from './Components/Uses'
import Attendance from './Components/Attendance'
import ChangesOwnerShipe from './Components/changesOwnerShipe'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkPrivileges } from '../../../Helpers'
// import { withSnackbar } from 'notistack'
import UpdateUnitDetail from './Components/updateUnit'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Style from 'style-it'
import Breadcrumb from '../../common/Breadcrumb'
import Button from '../../common/Button'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4
  }
})
class viewUserDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: 0,
      units: '',
      selectedDevice: '',
      suggestions: [],
      isRecived: false,
      attTapNotShow: false
    }
  }

  componentWillMount () {
    this.props.devices.data.map(item => {
      if (item.id === this.props.selectItemId) {
        if (item.positionId !== 0) {
          //fetch call for postion
          fetch(`api/positions?id=${item.positionId}`, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }).then(response => {
              response.json().then(res1 => {
                if(res1.status ==='success'){
                  let postions = res1.data
                  if (postions && postions[0]) {
                    this.setState({
                      suggestions: Object.keys(postions[0].attributes),
                      selectedDevice: editparkingValue(
                        JSON.parse(JSON.stringify(item))
                      ),
                      isRecived: true,
                      attTapNotShow: false
                    })
                  } else {
                    this.setState({
                      selectedDevice: editparkingValue(
                        JSON.parse(JSON.stringify(item))
                      ),
                      isRecived: true,
                      attTapNotShow: true
                    })
                  }
                }
                else if(res1?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res1?.statusCode){
                  var err = res1?.message.split(':')
                  err[1] =err[1].replace(')', "")
             
                    toast.error( this.props.translate(err[1])
                  )
                }
                else {
                  throw response
                }
              })
            
          }).catch(e => {
               console.log('e =', e)
              // this.props.dispatch(toast.error({
              // message: 'somethingWentWrong',
              // autoDismiss: 5
              // }))
         })
        } else {
          this.setState({
            selectedDevice: editparkingValue(JSON.parse(JSON.stringify(item))),
            isRecived: true,
            attTapNotShow: true
          })
        }
      }
    })
  }
  UpdateSelectedItem = item => {
    this.setState({
      selectedDevice: editparkingValue(JSON.parse(JSON.stringify(item)))
    })
  }
  handleChange = (event, value) => {
    this.setState({ value })
  }

  render () {
    const { classes, logInUser } = this.props
    const { value, isRecived, attTapNotShow, selectedDevice } = this.state
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'
    let tabName = 'Edit'
    if(value ===0){tabName=this.props.translate('sharedEdit')}
       else if(value===1){tabName=this.props.translate('users')}
       else if(value===2){tabName=this.props.translate('changeUnitOwnerShip')}
       else if(value===3){tabName=this.props.translate('attendance')}


    return (
      <div className={classes.root}>
          <Breadcrumb
              itemName={this.props.selectItemName}
              tabName={tabName}
              onClose={this.props.onClose}
              moduleName={this.props.translate("unitDetail")}
              backLink={<Button size="small" onClick={this.props.onClose}>Back</Button>}
         />
        <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-tab-button2 .MuiTab-wrapper {
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors['600']};
            color: ${this.props.themecolors['themeInverse']};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected2 {
            color: ${this.props.themecolors['500']};
          }`}</Style>
        <div className="v-tabs">
        <AppBar elevation={0} position="static" style={{background: 'transparent'}} className="tab-vertial-wrapper">
          <Tabs
            value={value}
            onChange={this.handleChange}
            orientation={orientation}
            classes={{ root: 'custom-tabs' }}
            TabIndicatorProps={{
              style: { background: 'transparent' }
            }}
          >
            <Tab
               classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
              label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('sharedEdit')}</>}
            />
            <Tab
              classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
              label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('users')}</>}
            />
            {/* {selectedDevice && selectedDevice.parentId === logInUser.id && ( */}
            {selectedDevice && (
              <Tab
                classes={{
                  root: 'custom-tab-button2',
                  selected: 'custom-tab-button-selected2'
                }}
                label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('changesUnitOwnerShip')}</>}
              />
            )}
            {logInUser.userType === -1 && (
              <Tab
                classes={{
                  root: 'custom-tab-button2',
                  selected: 'custom-tab-button-selected2'
                }}
                label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('attendance')}</>}
              />
            )}
          </Tabs>
        <Paper style={{ padding: 16, minHeight: 350 , flex: 1}} square={true}>
          {value === 0 && isRecived && checkPrivileges('deviceUpdate') && (
            <Fragment>
              <UpdateUnitDetail
                {...this.props}
                {...this.state}
                UpdateSelectedItem={this.UpdateSelectedItem}
              />
            </Fragment>
          )}
          {value === 1 && checkPrivileges('user') && (
            <Fragment>
              <User {...this.props} {...this.state} />
            </Fragment>
          )}
          {/* {selectedDevice && selectedDevice.parentId === logInUser.id ? ( */}
          {selectedDevice ? (
            <>
              {value === 2 && (
                <Fragment>
                  <ChangesOwnerShipe {...this.props} {...this.state} />
                </Fragment>
              )}
              {logInUser.userType === -1 &&
                value === 3 &&
                this.state.isRecived && (
                  <Fragment>
                    {!attTapNotShow ? (
                      <Attendance {...this.props} {...this.state} />
                    ) : (
                      <h4 style={{ textAlign: 'center' }}>
                        {this.props.translate('postionIsUnavailable')}
                      </h4>
                    )}
                  </Fragment>
                )}
            </>
          ) : (
            <>
              {logInUser.userType === -1 &&
                value === 2 &&
                this.state.isRecived && (
                  <Fragment>
                    {!attTapNotShow ? (
                      <Attendance {...this.props} {...this.state} />
                    ) : (
                      <h4 style={{ textAlign: 'center' }}>
                        {this.props.translate('postionIsUnavailable')}
                      </h4>
                    )}
                  </Fragment>
                )}
            </>
          )}
        </Paper>
        </AppBar>
      </div>
      </div>
    )
  }
}

viewUserDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    devices: state.devices,
    users: state.users,
    groups: state.groups,
    accounts: state.accounts,
    roles: state.roles,
    ServerSetting: state.ServerSetting
  }
}
const mapStateToProps = connect(mapState)
export const ViewUserDetail = mapStateToProps(
  ((withStyles(styles)(viewUserDetail)))
)

function editparkingValue (data) {
  let { attributes } = data
  if (attributes && attributes['parking.minIdleTime']) {
    attributes['parking.minIdleTime'] =
      attributes['parking.minIdleTime'] / 60000
  }
  if (attributes && attributes['parking.maxIdleSpeed']) {
    attributes['parking.maxIdleSpeed'] = Math.round(
      attributes['parking.maxIdleSpeed'] * 1.852
    )
  }
  if (attributes && attributes['speedLimit']) {
    attributes['speedLimit'] = Math.round(attributes['speedLimit'] * 1.852)
  }
  if (attributes && attributes['minimalTripDistance']) {
    attributes['minimalTripDistance'] = Math.round(
      attributes['minimalTripDistance'] / 1000
    )
  }
  return data
}
