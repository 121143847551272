import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { connect } from 'react-redux'

let themecolors
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors
  return {
    themecolors: state.themecolors
  }
}
const styles = theme => ({
  root: {
    color: themecolors[500],
    '&$checked': {
      color: themecolors[500]
    }
  },
  checked: {}
})

class CheckboxLabels extends Component {
  constructor (props) {
    super(props)
    this.state = {
      checked: props.checked,
      indeterminate: props.indeterminate || false
    }
  }
  handleChange = name => event => {
    if (event.target.checked === true && this.props.canAssign) {
      this.setState({ checked: event.target.checked })
      this.props.onChange(event, name)
    } else if (event.target.checked === false && this.props.canRemove) {
      this.setState({ checked: event.target.checked })
      this.props.onChange(event, name)
    }
  }

  UNSAFE_componentWillReceiveProps (n) {
    this.setState({
      checked: n.checked,
      indeterminate: n.indeterminate || false
    })
  }

  render () {
    const { checked, indeterminate } = this.state
    const { classes } = this.props
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked ? true : false}
            indeterminate={indeterminate}
            readOnly={this.props.readonly}
            onChange={this.handleChange(this.props.value || this.props.id)}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        }
        label={this.props.label || ''}
      />
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CheckboxLabels))
