import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import Table from '../../../common/TableMultiCheckbox'
import {
  checkUnitsOfUsers,
  checkPrivileges,
  prepareUserForLogin,
  PaginationConfig
} from '../../../../Helpers'
import LoginAsUser from '../../../../Helpers/loginAsUser'
import Loader from '../../../../Layout/Loader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import { Grid, Tooltip } from '@mui/material'
import TextField from '../../../common/TextField'
import Checkbox from '../../../common/Checkbox'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let source,
  totalUserId = {
    userId: [],
    deviceId: ''
  }
class User extends Component {
  constructor () {
    super()
    this.state = {
      usersReceived: false,
      selectedUnitsList: '',
      accessUserList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      total: '',
      lastPage: '',
      isChecked: false,
      isIndeterminate: false,
      allSelectedIds: '',
      noRecords: false,
      assignedFilter:false,
      managedUserId: '',
      selected: 'all',
    }
    this.unitToUserpermission = this.unitToUserpermission.bind(this)
  }
  componentWillMount () {
    this.getMoreUnits()
  }
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }
  getMoreUnits = () => {
    let { pagination,managedUserId } = this.state
    let { page, pageSize, itemSearch } = pagination
    let count = 0,
      allUserId = 0
    let res1 = this.props.units
    let sellectedIds = []
    source = axios.CancelToken.source()

    axios
      .get(
        `api/devices/get?userId=${managedUserId ? managedUserId :this.props.logInUser.id}&all=true&&page=${page}&limit=${pageSize}&search=${itemSearch}`,
        {
          cancelToken: source.token
        }
      )
      .then(resp => {
        let response =resp.data
        if (response.status === 'success') {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
            ) {
            let lastPage = response.data.total / pageSize
            let IsFloate = this.checkFloteNumber(lastPage)
            axios
            .get(`api/devices/linked?userId=${this.props.selectItemId.id}`)
              .then(res1 => {
                let res = res1.data
                if(res.status ==='success'){
                  allUserId = 0
                  response.data.data.map(loginData => {
                    loginData.entity.groupAccess = 0
                      res.data.map(main=>{
                        if(loginData.entity.id&&main.entityId&& loginData.entity.id ===main.entityId){
                           loginData.entity.check = main.directAccess
                           loginData.entity.indeterminate = !main.directAccess
                           loginData.entity.groupAccess = main.groupAccess
                        }
                      })
                  })
                  res.data.map(userId => {
                    sellectedIds.push(userId.entityId)
                  })
                  this.setState({
                    allSelectedIds: sellectedIds
                  })
                  if (sellectedIds.length === this.props.loginDeviceIds.length && this.props.loginDeviceIds.length) {
                    this.setState({
                      isChecked: true
                    })
                  } else {
                    this.setState({
                      isChecked: false
                    })
                  }
                  let units = response.data.data.map(u => {
                    if (u.entity.created) {
                      return {
                        ...u,
                        entity: {
                          ...u.entity,
                          created: this.props.logInUser.twelveHourFormat
                            ? moment(u.entity.created).format(
                                'YYYY-MM-DD hh:mm A'
                              )
                            : moment(u.entity.created).format('YYYY-MM-DD HH:mm')
                        }
                      }
                    } else {
                      return { ...u }
                    }
                  })
                  this.setState({
                    pagination: {
                      ...this.state.pagination,
                      lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                      hasNext: response.data.hasNext,
                      total: response.data.total
                    },
                    selectedUnitsList: prepareUserForLogin(units),
                    usersReceived: true
                  })
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                } 
                else if(res?.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                 
                    toast.error(
                      this.props.translate(err[1])
                  )
                }
                
              })
          } else {
            this.setState({
                selectedUnitsList: [],
                usersReceived: false,
                noRecords:true
            }) }
          }
          else if(response?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(response.statusCode){
            var err = response?.message.split(':')
            err[1] =err[1].replace(')', "")
            
              toast.error(
                 this.props.translate(err[1])
            )
          }
      })
  }
 
  handleChangeFilter = (id,event) => {
    if(event.target.value === 'assigned'){
      this.setState({
          assignedFilter:true,
          managedUserId:id,
          pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
          selected:event.target.value
      },()=>{this.getMoreUnits()})
    }
    else{
      this.setState({
        assignedFilter:false,
        managedUserId:'',
        selected:event.target.value
       },()=>{this.getMoreUnits()})
    }
  }

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => {
        this.getMoreUnits()
      }
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUnits()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getMoreUnits()
    )
  }

  allUnitsId = event => {
    let sellectedIds = []
    let check = event.target.checked
    let allUserId = []
    this.selectAllUser(check)
  }

  selectAllUser = event => {
    let array1 =
      this.props &&
      this.props.loginDeviceIds.filter(
        val => !this.state.allSelectedIds.includes(val)
      )
    totalUserId.deviceId = this.props.selectItemId
    let obj
    let option = 'DELETE'
    if (event) {
      option = 'POST'
      obj = { userId: totalUserId.deviceId.id, deviceId: array1 }
    } else {
      obj = {
        userId: totalUserId.deviceId.id,
        deviceId: this.state.allSelectedIds
      }
    }
    fetch(`api/permissions/multiproperty`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
    .then(response => {
      response.json().then(res => {
        if(res.status ==='success' || res.status ==='ambiguous'){ 
          if (option === 'POST') {
            this.setState({
              isChecked: true
            })

            
              toast.success(
               this.props.translate('unitsIsAssignedSuccessfully')
            )
          } else {
            this.setState({
              isChecked: false
            })
          
              toast.success(
                this.props.translate('unitsIsUnAssignedSuccessfully')
            )
          }
          this.getMoreUnits()
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(res.statusCode){
          var err = res?.message.split(':')
          err[1] =err[1].replace(')', "")
        
            toast.error(
           this.props.translate(err[1])
          )
        }
       })
      })
      .catch(e => {
       toast.error(
          'somethingWentWrong')
      })
      totalUserId = {
          userId: [],
          deviceId: ''
       }

  }

  unitToUserpermission (event, item) {
    let sellectedIds = []
    let option = 'DELETE'
    let count = 0,
      allUserId = 0
    if (event) {
      option = 'POST'
    }
    let allData = [...this.state.selectedUnitsList]
    let obj = {
      userId: this.props.selectItemId.id,
      deviceId: item.id
    }
    fetch(`/api/permissions`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        response.json().then(res=>{
          if (res.status === 'success') {
            if (option === 'POST') {
              
                toast.success(
                   this.props.translate('unitIsAssignedSuccessfully')
              )
            } else {
             
                toast.success(
                this.props.translate('unitIsUnAssignedSuccessfully')
              )
            }
          } 
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res?.statusCode){
            var err = res.message.split(':')
            var err2 =err[1].split('&#')
            
              toast.error(
               this.props.translate(err2[0])
            )
          }
          else {
            throw response
          }
        })
      })
      .catch(e => {
        if(e && e.text){
          e.text().then(err => {
            if (err.includes('You cannot unlink unit')) {
              
                toast.error(
                 this.props.translate('youCannotUnlinkUnit')
              )
            }
          })
        }
      })
      this.getMoreUnits()
  }
  loginAsUser = data => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false
        },
        () => {
          this.setState({
            isUserLogin: true
          })
        }
      )
    }
  }
  loginAsUserhandleChange = (id, value) => {
    let { selectedUnitsList } = this.state
    selectedUnitsList.entity.map(item => {
      if (item.id === id) {
        item.logInAsUserState = value
      }
      return null
    })
    this.setState({
      selectedUnitsList
    })
  }

  render () {
    const { classes, logInUser } = this.props
    return (
      <Fragment>
          <Grid container className="breadcrumb-row">
               <Grid item xs={12} sm={6}>
                   <h3 style={{margin: 0}}>{this.props.translate('units')}</h3>
                </Grid>
              <Grid item xs={12} sm={2}   >
                 <Select
                      name="name"
                      value={this.state.selected}
                      onChange={e=>this.handleChangeFilter(this.props.selectedUser.id,e)}
                      input={<Input id="name" />}
                    >
                      <MenuItem value="assigned">Assigned</MenuItem>
                      <MenuItem value="all">All</MenuItem>
                    </Select>
                {/* <Checkbox 
                    name='assigned'
                    checked={this.state.assignedFilter}
                    onChange={e=>this.handleChangeFilter(this.props.selectedUser.id,e)} 
                    canAssign
                    canRemove
                  />
                  <span>Assigned</span> */}
                </Grid>

                    <Grid item xs={12} sm={4} style={{justifyContent: 'flex-end'}}>
                      <div style={{ marginRight: '20px', width: "80%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                      </div>
                  </Grid>
            
               </Grid>
        {this.state && this.state.usersReceived ? (<>
          <div styles={{ postion: 'relative' }}>
            <Table
              rows={
                this.state.selectedUnitsList.map(item => {
                  return item.entity
                }) || []
              }
              translate={this.props.translate}
              allUsersId={this.allUnitsId}
              selectItemParentId={this.props.selectItemId.id}
              pagination={this.state.pagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              loginAsUser={this.loginAsUser}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              isChecked={this.state.isChecked}
              isIndeterminate={this.state.isIndeterminate}
              rowsPerPage={10}
              isEditable={false}
              showCheckbox2={true}
              showGroupAccess={true}
              ServerSetting={this.props.ServerSetting}
              themecolors={this.props.themecolors}
              checkHandleChange={this.unitToUserpermission}
              canAssign={checkPrivileges('userLinkDevice')}
              canRemove={checkPrivileges('userUnlinkDevice')}
              // rowDefinition={rowDefinitionR(this.props.translate)}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedName')
                },
                (logInUser.attributes?.viewIMEI || logInUser.userType===-1) &&{
                  id: 'uniqueId',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('driverUniqeId')
                },
                {
                  id: 'phone',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedPhone')
                },
                (logInUser.attributes?.viewModel || logInUser.userType===-1) &&{
                  id: 'model',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('model')
                },
          
                {
                  id: 'created',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('createdAt')
                },
                {
                  id: 'status',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('status')
                }
              ]}
            />{' '}
          </div>
          </>
        ) :  
        !this.state.usersReceived && !this.state.noRecords ?
         (<div style={{ textAlign: 'center' }}>
              <Loader defaultStyle />
        </div>)
          : null
      }

        {!this.state.usersReceived && this.state.noRecords && (
              <div style={{ textAlign: 'center' }}>
                  <h4> {this.props.translate('noUnitsFound')}</h4>
              </div>
          )}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(User)