import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Checkbox from "../common/Checkbox";
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../common/TextField";
import FormHelperText from '@mui/material/FormHelperText';
import SingleSelect from "../common/SingleSelect";
import Selector from "../common/Select";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import InfoIcon from '@mui/icons-material/Info';
import CustomDatePicker from "../common/CustomDatePicker";
import Button from "../common/Button";
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const poolTypes = [
  {
    key: "contractual",
    name: "Contractual",
  },
  {
    key: "exclusive",
    name: "Exclusive",
  },
  {
    key: "common",
    name: "Common",
  },
];

const vehicleEntitlementList= [
  {
    key: 1,
    name: "Company Car",
  },
  {
    key: 2,
    name: "New Policy Company Car - Upgrade",
  },
  {
    key: 3,
    name: "New Policy Job Car",
  }
  ,
  {
    key: 4,
    name: "New Policy Job Car - Upgrade",
  },
  {
    key: 5,
    name: "Old Policy Job Car",
  },
  {
    key: 6,
    name: " Old Policy Upgrade - Paid",
  },
  {
    key: 7,
    name: "Old Policy Upgrade",
  },
  {
    key: 8,
    name: "Department Assets",
  },

];

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
class addVehicle extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.props.resetVehicleValidation();
  }
  render() {
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          // addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          //   buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableUserBtn}
          notVisableBackbtn={this.props.addOption ? false : true}
          // showPortList={this.props.showPortList}
          // showPort={this.props.showPort}
          content={
            <>
              <form autoComplete="off">
                <Grid container spacing={2}>
              {/* {console.log("defaultAccountsList=====",this.props.defaultAccountsList  )} */}

              <Grid item md={4} sm={6} xs={12} >
                    <TextField
                      id="vin"
                      label={this.props.translate("vin")}
                      placeholder=""
                      value={this.props.form?.vin || ""}
                      onChange={(e) => this.props.handleChange("vin", e)}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      required
                      error={this.props.vinError}
                    />
                     <FormHelperText 
                      style={{ fontWeight: "bold", color : this.props.vinError ? "red" : "inherit", fontSize:"12px"}} // Changed fontWeight and color
                      error={this.props.vinError ? true : false}
                      >
                      {this.props.translate(`VIN must be exactly 17 characters long.`)}
                    </FormHelperText>
                  </Grid>
               
                  <Grid item md={4} sm={6} xs={12} >
                  <Button 
                    style={{ height:"28px", marginTop:"7px"}}
                    onClick={()=> this.props.getVehicleData()}
                    disabled={this.props.vinError}
                  > {this.props.translate("fetchData")}</Button>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                  </Grid>
                {this.props.addOption && (
                  <Grid item md={4} sm={6} xs={12}      style={{ postion: "relative", zIndex: 99 }}>
                    <SingleSelect
                      array={this.props.defaultAccountsList || []}
                      async
                      selectName="users"
                      isClearable
                      label={this.props.translate("selectChildUsers")}
                      value={
                        this.props.selectedUser && this.props.selectedUser.id
                          ? {
                              // key: this.props.selectedUser.id,
                              id: this.props.selectedUser.id,
                              label: this.props.selectedUser.label || this.props.selectedUser.name,
                            }
                          : ""
                      }
                      // value={this.props.form.reportNumber || ""}
                      handleChange={this.props.handleChangeUser}
                      canAssign={true}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Grid>
                 )} 

            
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="category"
                      select
                      label={this.props.translate("vehicleType")}
                      value={this.props.form?.category || ""}
                      onChange={(e) => this.props.handleChange("category", e)}
                      margin="dense"
                      fullWidth
                      required
                    >
                      <MenuItem value={0}>
                        <em>{this.props.translate("none")}</em>
                      </MenuItem>
                      {this.props && this.props.categories &&
                        this.props.categories?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {this.props.translate(option)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="label"
                      label={this.props.translate("label")}
                      required
                      placeholder=""
                      value={this.props.form?.label || ""}
                      onChange={(e) => this.props.handleChange("label", e)}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "96%"}}>
                      <TextField
                        error={this.props.vehicleLicensePlateError ? true : false}
                        id="vehicleLicensePlate"
                        label={this.props.translate("vehicleLicensePlate")}
                        required
                        placeholder=""
                        value={this.props.form.vehicleLicensePlate || ""}
                        onChange={(e) =>
                          this.props.handleChange("vehicleLicensePlate", e)
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        // style={{ width: "100%", marginRight: "10px" }} // Changed width to 100%
                      />
                      </div>
                      <HtmlTooltip
                        title={
                          <span style={{fontSize:"18px", fontWeight: "bold", width:"200px", height:"150px", padding:"5px 0px" }}> 
                            {this.props.translate("Format  4734AGJ")} 
                            {/* <br/> 
                            <br/> 
                            {this.props.translate("Arabic أ ج ج ٤٧٣٤")} */}
                          </span>
                        }
                        style={{ color: "green" }} // Changed the color to green
                      >
                        <InfoIcon fontSize="large"  style={{ fontSize: 24, color: "grey" , marginLeft:"5px" }} />  
                      </HtmlTooltip>
                    </div>
                    <FormHelperText 
                      style={{ fontWeight: "bold", color: "red" , fontSize:"12px"}} // Changed fontWeight and color
                      error={this.props.vehicleLicensePlateError ? true : false}
                    >
                      {this.props.translate(`Follow format 4734AGJ`)}
                    </FormHelperText>
                  </Grid>

                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="vehicleLisencePlateArabic"
                      label={this.props.translate("vehicleLisencePlateArabic")}
                      placeholder=""
                      value={this.props.form?.vehicleLisencePlateArabic || ""}
                      onChange={(e) =>
                        this.props.handleChange("vehicleLisencePlateArabic", e)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="entitlement"
                      select
                      label={this.props.translate("Vehicle Entitlement")}
                      value={this.props.form?.entitlement || ""}
                      onChange={(e) => this.props.handleChange("entitlement", e)}
                      margin="dense"
                      fullWidth
                      // required
                    >
                      {vehicleEntitlementList?.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="country"
                      label={this.props.translate("country")}
                      placeholder=""
                      value={this.props.form?.attributes?.country || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues("country", e)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="registrationNumber"
                      label={this.props.translate("registrationNumber")}
                      placeholder=""
                      value={
                        this.props.form?.attributes?.registrationNumber || ""
                      }
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "registrationNumber",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="variant"
                      label={this.props.translate("variant")}
                      placeholder=""
                      value={this.props.form?.attributes?.variant || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues("variant", e)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="wheels"
                      label={this.props.translate("wheels")}
                      placeholder=""
                      value={this.props.form?.attributes?.wheels || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues("wheels", e)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}  style={{ postion: "relative", zIndex: 99 }}>
                    <SingleSelect
                      array={this.props?.trasmissionList || []}
                      id="trasmissionType"
                      async
                      isClearable
                      selectName="trasmissionType"
                      label={this.props.translate("trasmissionType")}
                      value={
                        this.props.form &&
                        this.props.form.attributes &&
                        this.props.form.attributes.trasmissionType
                          ? {
                              id: this.props.form.attributes.trasmissionType,
                              label: this.props.form.attributes.trasmissionType,
                              value: this.props.form.attributes.trasmissionType,
                            }
                          : ""
                      }
                      handleChange={this.props.handleChange1}
                      canRemove={true}
                      canAssign={true}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ postion: "relative", zIndex: 4 }}
                  >
                    <SingleSelect
                      array={this.props?.allMakes || []}
                      async
                      isClearable
                      id="make"
                      selectName="make"
                      label={this.props.translate("make")}
                      // defaultOptions={this.props?.allMakes || []}
                      value={
                        this.props.form && this.props.form.make
                          ? {
                              id: this.props.form.make.key,
                              value: this.props.form.make.label,
                              label: this.props.form.make.label,
                            }
                          : ""
                      }
                      handleChange={this.props.handleChangeForAttributesValues}
                      canRemove={true}
                      canAssign={true}
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ postion: "relative", zIndex: 4 }}
                  >
                    <SingleSelect
                      array={this.props?.selectedMakeTypes || []}
                      async
                      isClearable
                      id="modelId"
                      selectName="modelId"
                      label={this.props.translate("model")}
                      value={
                        this.props.form &&
                        this.props.form.modelId &&
                        this.props.form.modelId.id
                          ? {
                              id: this.props.form.modelId.id ,
                              value: this.props.form.modelId.id ,
                              label: this.props.form.modelId.label ,
                            }
                          : ""
                      }
                      // index={index}
                      handleChange={this.props.handleChange1}
                      canRemove={true}
                      canAssign={true}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Grid>
               
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="year"
                      label={this.props.translate("year")}
                      placeholder=""
                      value={this.props.form?.attributes?.year || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "year",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="color"
                      label={this.props.translate("attributeColor")}
                      placeholder=""
                      value={this.props.form?.attributes?.color || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "color",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12} style={{ position: 'relative' }}>
                    {/* <TextField
                      id="fuel_type"
                      label={this.props.translate("fuel_type")}
                      placeholder=""
                      value={this.props.form?.attributes?.fuel_type || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "fuel_type",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    /> */}
                    <label style={{ 
                      fontSize: '0.75em', lineHeight: '1', position: 'absolute', zIndex: '10', 
                      background: '#fff', padding: '0 5px', left: '14px'
                      }}>
                      {this.props.translate("fuel_type")}
                    </label>
                    <SingleSelect
                      array={this.props?.fuel_typeList || []}
                      id="fuel_type"
                      async
                      isClearable
                      selectName="fuel_type"
                      label={this.props.translate("fuel_type * ")}
                      value={
                        this.props.form &&
                        this.props.form.attributes &&
                        this.props.form.attributes.fuel_type
                          ? {
                              id: this.props.form.attributes.fuel_type,
                              label: this.props.form.attributes.fuel_type,
                              value: this.props.form.attributes.fuel_type,
                            }
                          : ""
                      }
                      onChange={this.props.handleChange}
                      handleChange={this.props.handleChange}
                      canRemove={true}
                      canAssign={true}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      type="number"
                      id="fuelEfficiency"
                      label={this.props.translate("fuelEfficiency")}
                      // required
                      placeholder=""
                      value={this.props.form?.fuelEfficiency || ""}
                      onChange={(e) => this.props.handleChange("fuelEfficiency", e)}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="totalAvailablekm"
                      label={this.props.translate("Standard Daily Mileage (km)")}
                      placeholder=""
                      value={this.props.form?.totalAvailablekm || ""}
                      onChange={(e) =>
                        this.props.handleChange("totalAvailablekm", e)
                      }
                      required
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      error={this.props.form?.totalAvailablekm < 1? true : false}

                    />
                    <FormHelperText error={this.props.form?.totalAvailablekm < 1 ? true : false}>
                    {this.props.translate("Must be Greater than 0")}
                    </FormHelperText>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      type="number"
                      id="odometer"
                      label={this.props.translate("odometer")}
                      // required
                      placeholder=""
                      value={this.props.form?.odometer || ""}
                      onChange={(e) => this.props.handleChange("odometer", e)}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="capacity"
                      label={this.props.translate("capacity")}
                      placeholder=""
                      value={this.props.form?.attributes?.capacity || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "capacity",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="garage"
                      label={this.props.translate("garage")}
                      placeholder=""
                      value={this.props?.form?.garage || ""}
                      onChange={(e) => this.props.handleChange("garage", e)}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ postion: "relative", zIndex: 3 }}
                  >
                         <SingleSelect
                      array={this.props.trackerData  || []}
                      async
                      isClearable
                      id="deviceId"
                      selectName="deviceId"
                      label={this.props.translate("searchUnits")}
                      value={
                        this.props.form &&
                        this.props.form.deviceId &&
                        this.props.form.deviceId
                          ? {
                              id: this.props.form.deviceId.id ,
                              value: this.props.form.deviceId.id ,
                              label: this.props.form.deviceId.label,
                            }
                          : ""
                      }
                      // index={index}
                      handleChange={this.props.handleChange1}
                      canRemove={true}
                      canAssign={true}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {/* <TextField
                      id="searchUnits"
                      select
                      label={this.props.translate("searchUnits")}
                      value={this.props.form?.searchUnits || ""}
                      // onChange={this.handleChange('typeName')}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "searchUnits",
                          e
                        )
                      }
                      margin="dense"
                      fullWidth
                    >
                      {this.props && this.props.trackerData &&
                        this.props.trackerData.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}{" "}
                          </MenuItem>
                        ))}
                    </TextField> */}

                  </Grid>
                 
                
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="chassisNumber"
                      label={this.props.translate("chassisNumber")}
                      placeholder=""
                      value={this.props.form?.attributes?.chassisNumber || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "chassisNumber",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  {/* start          -----------pending task from backEnd--------- Expiration Date*/}
                  <Grid item md={4} sm={6} xs={12}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        error={
                          this.props.form.expirationTime &&
                          this.props.form.expirationTime <
                            moment().startOf("day").toISOString()
                        }
                        helperText={
                          this.props.form.expirationTime &&
                          this.props.form.expirationTime <
                            moment().startOf("day").toISOString()
                            ? this.props.translate("licenseExpired")
                            : ""
                        }
                        margin="dense"
                        label={this.props.translate("V/LExpirationTime")}
                        variant="outlined"
                        fullWidth
                        minDate={new Date()}
                        value={
                          this.props.form.expirationTime
                            ? new Date(
                                `${this.props.form.expirationTime}`
                              ).toString()
                            : null
                        }
                        onChange={this.props.handleChangeLicenseExpDate(
                          "expirationTime"
                        )}
                        InputProps={{
                          classes: {
                            root: "theme-cssOutlinedInput",
                            input: "theme-input",
                            focused: "theme-cssFocused",
                            notchedOutline: "theme-notchedOutline",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title={this.props.translate("noLimit")}>
                                <IconButton
                                  onClick={(e) =>
                                    this.props.setExpirationTime(e)
                                  }
                                  style={{ color: "inherit" }}
                                >
                                  {<CrossIcons />}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "theme-label",
                            focused: "theme-label-focused",
                            shrink: "theme-label-shrink",
                          },
                        }}
                      />
                    </LocalizationProvider> */}
                             <CustomDatePicker
                                  format="dd/MM/yyyy"
                                  error={false}
                                  label={this.props.translate(
                                    "V/LExpirationTime"
                                  )}
                                  fullWidth={true}
                                  value={
                                    this.props.form &&
                                    this.props.form.expirationTime
                                      ? 
                                          `${this.props.form.expirationTime}`
                                      : null
                                  }
                                  onChange={this.props.handleChangeLicenseExpDate(
                                    "expirationTime"
                                  )}
                                />
                  </Grid>
                  {/* <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="Fuelconsumption"
                      label={this.props.translate("Average Fuel Consumption (km/L)")}
                      placeholder=""
                      value={this.props.form?.attributes?.Fuelconsumption || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "Fuelconsumption",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid> */}
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="driverCost"
                      label={this.props.translate("driverCost")}
                      placeholder=""
                      value={this.props.form?.attributes?.driverCost || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "driverCost",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="fines"
                      label={this.props.translate("fines")}
                      placeholder=""
                      value={this.props.form?.attributes?.fines || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues("fines", e)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="brandingCost"
                      label={this.props.translate("brandingCost")}
                      placeholder=""
                      value={this.props.form?.attributes?.brandingCost || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "brandingCost",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="telematicsCost"
                      label={this.props.translate("telematicsCost")}
                      placeholder=""
                      value={this.props.form?.attributes?.telematicsCost || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "telematicsCost",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="otherCost"
                      label={this.props.translate("otherCost")}
                      placeholder=""
                      value={this.props.form?.attributes?.otherCost || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "otherCost",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="SIMNumber"
                      label={this.props.translate("SIMNumber")}
                      placeholder=""
                      value={this.props.form?.attributes?.SIMNumber || ""}
                      onChange={(e) =>
                        this.props.handleChangeForAttributesValues(
                          "SIMNumber",
                          e
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>

                  {/* {checkPrivileges("area") ? ( */}
                  <Grid item md={4} sm={6} xs={12}>
                    {/* <InputLabel
                      htmlFor="name-multiple"
                      style={{ color: "#ffffff", fontSize: "11px" }}
                    >
                      {this.props.translate("sharedArea")}
                    </InputLabel> */}
                  
                    <SingleSelect
                      array={this.props?.areas || []}
                      async
                      selectName="areaId"
                      label={this.props.translate("sharedArea")}
                      value={
                        this.props.form &&
                        this.props.form.areaId &&
                        this.props.form.areaId 
                          ? {
                              id: this.props.form.areaId.id  || this.props.form.areaId,
                              value: this.props.form.areaId.id || this.props.form.areaId,
                              label: this.props.form.areaId.label || this.props.form.areaId,
                              // uniqueId: this.props.form.areaId.uniqueId,
                            }
                          : ""
                      }
                      handleChange={this.props.handleChange}
                      canRemove={true}
                      canAssign={true}
                    />
                  </Grid>
                  {/* ) : null} */}

{this.props?.ServerSetting?.contractType ===2 ?
<>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ display: "flex", marginTop: 10 }}
                  >
                    <Checkbox
                      checked={this.props.form?.onLease || ""}
                      onChange={(e) => this.props.handleChange("onLease", e)}
                      value="type"
                      canAssign
                      canRemove
                      label={this.props.translate("leasing")}
                    />
                  </Grid>

                  {/*---end--- */}
                  {this.props.form && this.props.form.onLease ? (
                    <>
                      <Grid item xs={12}>
                        <h3 style={{ margin: 0 }} className="with-border">
                          {this.props.translate("leasing")}
                        </h3>
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="purchasePrice"
                          label={this.props.translate("purchasePrice")}
                          placeholder=""
                          value={this.props.form?.purchasePrice || ""}
                          onChange={(e) =>
                            this.props.handleChange("purchasePrice", e)
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="monthlyRate"
                          placeholder=""
                          label={this.props.translate("monthlyRate")}
                          value={this.props.form?.monthlyRate || ""}
                          onChange={(e) =>
                            this.props.handleChange("monthlyRate", e)
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="poolType"
                          select
                          value={this.props.form?.attributes?.poolType || ""}
                          label={this.props.translate("poolType")}
                          // onChange={this.handleChange('typeName')}
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "poolType",
                              e
                            )
                          }
                          margin="dense"
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>{this.props.translate("none")}</em>
                          </MenuItem>
                          {poolTypes?.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                              {option.name}{" "}
                            </MenuItem>
                          ))}
                        </TextField>
                    
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="insurancePercentage"
                          label={this.props.translate("insurancePercentage")}
                          placeholder=""
                          value={
                            this.props.form?.attributes?.insurancePercentage ||
                            ""
                          }
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "insurancePercentage",
                              e
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="bankPercentage"
                          label={this.props.translate("bankPercentage")}
                          placeholder=""
                          value={
                            this.props.form?.attributes?.bankPercentage || ""
                          }
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "bankPercentage",
                              e
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="Maintenance"
                          label={this.props.translate("maintenanceCost")}
                          placeholder=""
                          value={this.props.form?.attributes?.Maintenance || ""}
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "Maintenance",
                              e
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="zakatPercentage"
                          label={this.props.translate("zakatPercentage")}
                          placeholder=""
                          value={
                            this.props.form?.attributes?.zakatPercentage || ""
                          }
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "zakatPercentage",
                              e
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>

                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="resalePercentage"
                          label={this.props.translate("resalePercentage")}
                          placeholder=""
                          value={
                            this.props.form?.attributes?.resalePercentage || ""
                          }
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "resalePercentage",
                              e
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="replacement"
                          label={this.props.translate("replacementPercentage")}
                          placeholder=""
                          value={this.props.form?.attributes?.replacement || ""}
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "replacement",
                              e
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="standByVehicle"
                          label={this.props.translate("standByVehicle")}
                          placeholder=""
                          value={
                            this.props.form?.attributes?.standByVehicle || ""
                          }
                          onChange={(e) =>
                            this.props.handleChangeForAttributesValues(
                              "standByVehicle",
                              e
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            helperText={
                              this.props.form &&
                              this.props.form.attributes &&
                              this.props.form.attributes.purchaseDate <
                                moment().startOf("day").toISOString()
                                ? this.props.translate("purchaseDate")
                                : ""
                            }
                            margin="dense"
                            label={this.props.translate("purchaseDate")}
                            variant="outlined"
                            fullWidth
                            // minDate={new Date()}
                            value={
                              this.props.form && this.props.form.purchaseDate
                                ? new Date(
                                    `${this.props.form.purchaseDate}`
                                  ).toString()
                                : null
                            }
                            onChange={this.props.handleChangeLicenseExpDate(
                              "purchaseDate"
                            )}
                            InputProps={{
                              classes: {
                                root: "theme-cssOutlinedInput",
                                input: "theme-input",
                                focused: "theme-cssFocused",
                                notchedOutline: "theme-notchedOutline",
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "theme-label",
                                focused: "theme-label-focused",
                                shrink: "theme-label-shrink",
                              },
                            }}
                          />
                        </LocalizationProvider> */}

                            <CustomDatePicker
                                  format="dd/MM/yyyy"
                                  error={false}
                                  label={this.props.translate(
                                    "purchaseDate"
                                  )}
                                  fullWidth={true}
                                  value={
                                    this.props.form &&
                                    this.props.form.purchaseDate
                                      ? 
                                          `${this.props.form.purchaseDate}`
                                      : null
                                  }
                                  onChange={this.props.handleChangeLicenseExpDate(
                                    "purchaseDate"
                                  )}
                                />
                      </Grid>

                      <Grid item md={4} sm={6} xs={12}>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            error={
                              !this.props.form.deliveryDate
                            }
                            helperText={
                              !this.props.form.deliveryDate 
                            }
                            margin="dense"
                            label={this.props.translate("deliveryDate")}
                            variant="outlined"
                            fullWidth
                            required
                            value={
                              this.props.form && this.props.form.deliveryDate
                                ? new Date(
                                    `${this.props.form.deliveryDate}`
                                  ).toString()
                                : null
                            }
                            onChange={this.props.handleChangeLicenseExpDate(
                              "deliveryDate"
                            )}
                            InputProps={{
                              classes: {
                                root: "theme-cssOutlinedInput",
                                input: "theme-input",
                                focused: "theme-cssFocused",
                                notchedOutline: "theme-notchedOutline",
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "theme-label",
                                focused: "theme-label-focused",
                                shrink: "theme-label-shrink",
                              },
                            }}
                          />
                        </LocalizationProvider> */}

                              <CustomDatePicker
                                  format="dd/MM/yyyy"
                                  error={false}
                                  label={this.props.translate(
                                    "deliveryDate"
                                  )}
                                  fullWidth={true}
                                  value={
                                    this.props.form &&
                                    this.props.form.deliveryDate
                                      ? 
                                          `${this.props.form.deliveryDate}`
                                      : null
                                  }
                                  onChange={this.props.handleChangeLicenseExpDate(
                                    "deliveryDate"
                                  )}
                                />
                      </Grid>
                 
                      <Grid item md={4} sm={6} xs={12}>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            error={
                              !this.props.form.leaseStartDate 
                            }
                           
                            required
                            margin="dense"
                            label={this.props.translate("leaseStartDate")}
                            variant="outlined"
                            fullWidth
                            // minDate={new Date()}
                            value={
                              this.props.form && this.props.form.leaseStartDate
                                ? new Date(
                                    `${this.props.form.leaseStartDate}`
                                  ).toString()
                                : null
                            }
                            onChange={this.props.handleChangeLicenseExpDate(
                              "leaseStartDate"
                            )}
                            InputProps={{
                              classes: {
                                root: "theme-cssOutlinedInput",
                                input: "theme-input",
                                focused: "theme-cssFocused",
                                notchedOutline: "theme-notchedOutline",
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "theme-label",
                                focused: "theme-label-focused",
                                shrink: "theme-label-shrink",
                              },
                            }}
                          />
                        </LocalizationProvider> */}
                              <CustomDatePicker
                                  format="dd/MM/yyyy"
                                  error={false}
                                  label={this.props.translate(
                                    "leaseStartDate"
                                  )}
                                  fullWidth={true}
                                  value={
                                    this.props.form &&
                                    this.props.form.leaseStartDate
                                      ? 
                                          `${this.props.form.leaseStartDate}`
                                      : null
                                  }
                                  onChange={this.props.handleChangeLicenseExpDate(
                                    "leaseStartDate"
                                  )}
                                />
                      </Grid>

                      <Grid item md={4} sm={6} xs={12}>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            error={
                              ! this.props.form.leaseEndDate || 
                              this.props.form &&
                              this.props.form.leaseEndDate &&
                              this.props.form.leaseEndDate <
                              this.props.form.leaseStartDate
                                // moment().startOf("day").toISOString()
                            }
                            helperText={
                              this.props.form &&
                              this.props.form.leaseEndDate &&
                              this.props.form.leaseEndDate <
                              this.props.form.leaseStartDate  ? this.props.translate("Lease Start Date < Lease End Date "):""
                            }
                            required

                            margin="dense"
                            label={this.props.translate("leaseEndDate")}
                            variant="outlined"
                            fullWidth
                            // minDate={new Date()}
                            value={
                              this.props.form && this.props.form.leaseEndDate
                                ? new Date(
                                    `${this.props.form.leaseEndDate}`
                                  ).toString()
                                : null
                            }
                            onChange={this.props.handleChangeLicenseExpDate(
                              "leaseEndDate"
                            )}
                            InputProps={{
                              classes: {
                                root: "theme-cssOutlinedInput",
                                input: "theme-input",
                                focused: "theme-cssFocused",
                                notchedOutline: "theme-notchedOutline",
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "theme-label",
                                focused: "theme-label-focused",
                                shrink: "theme-label-shrink",
                              },
                            }}
                          />
                        </LocalizationProvider> */}

                              <CustomDatePicker
                                  format="dd/MM/yyyy"
                                  error={false}
                                  label={this.props.translate(
                                    "leaseEndDate"
                                  )}
                                  fullWidth={true}
                                  value={
                                    this.props.form &&
                                    this.props.form.leaseEndDate
                                      ? 
                                          `${this.props.form.leaseEndDate}`
                                      : null
                                  }
                                  onChange={this.props.handleChangeLicenseExpDate(
                                    "leaseEndDate"
                                  )}
                                />
                      </Grid>

                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="leasePeriod"
                          label={this.props.translate("leasePeriod")}
                          placeholder=""
                          value={this.props.form?.leasePeriod || ""}
                          onChange={(e) =>
                            this.props.handleChange("leasePeriod", e)
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                    </>
                  ) : null}
                  </>
:
 null}                  <Grid item xs={12}>
                    <h3 style={{ margin: 0 }} className="with-border">
                      {/* {<Translate id='tags' />} */}
                    </h3>
                  </Grid>

                  <Grid item md={4} sm={6} xs={12}>
                    {this.props.form?.attributes?.tags || ""}
                    <TextField
                      id="tag_1"
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.vt1
                          ? this.props.logInUser.attributes.vt1
                          : this.props.translate("tag_1")
                      }
                      placeholder=""
                      value={this.props.form?.attributes?.tag_1 || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "tag_1",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="tag_2"
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.vt2
                          ? this.props.logInUser.attributes.vt2
                          : this.props.translate("tag_2")
                      }
                      placeholder=""
                      value={this.props.form?.attributes?.tag_2 || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "tag_2",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="tag_3"
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.vt3
                          ? this.props.logInUser.attributes.vt3
                          : this.props.translate("tag_3")
                      }
                      placeholder=""
                      value={this.props.form?.attributes?.tag_3 || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "tag_3",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="tag_4"
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.vt4
                          ? this.props.logInUser.attributes.vt4
                          : this.props.translate("tag_4")
                      }
                      placeholder=""
                      value={this.props.form?.attributes?.tag_4 || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "tag_4",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="tag_5"
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.vt5
                          ? this.props.logInUser.attributes.vt5
                          : this.props.translate("tag_5")
                      }
                      placeholder=""
                      value={this.props.form?.attributes?.tag_5 || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "tag_5",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{ margin: 0 }} className="with-border">
                      {/* {<Translate id='integration' />} */}
                    </h3>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <label style={{ position: "static" }}>
                      {this.props.translate("sequenceNumber")}
                    </label>
                    <TextField
                      id="sequenceNumber"
                      label={this.props.translate("sequenceNumber")}
                      placeholder=""
                      value={this.props.form?.attributes?.sequenceNumber || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "sequenceNumber",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />

                    <small>
                      <em>{this.state.inquiryResponse}</em>
                    </small>
                  </Grid>
                  <Grid item md={8} sm={6} xs={12}>
                    <label
                      style={{
                        position: "static",
                        marginBottom: 8,
                        display: "block",
                      }}
                    >
                      &nbsp;
                    </label>
                    {/* {waslEnabled && waslCompanyRefKey ? <Button onClick={this.verify}>Check already registered</Button> : null} */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h4 style={{ marginBottom: 0 }}>
                      {this.props.translate("vehicleLicensePlate")}
                    </h4>
                  </Grid>
                  <Grid
                    item
                    style={{ position: "relative", zIndex: 8 }}
                    md={2}
                    sm={6}
                    xs={12}
                  >
                    <label>{this.props.translate("plateType")}</label>
                    <Selector
                      key="plateType"
                      label={this.props.translate("plateType")}
                      // array={plateTypes}
                      // multi={
                      //   this.props.form?.attributes?.plateType &&
                      //   plateTypes.filter(
                      //     (e) => this.props.form.attributes.plateType == e.id
                      //   )
                      // }
                      onChange={(e) => this.handleChange("plateType", e)}
                      canAssign={true}
                      canRemove={true}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{ position: "relative", zIndex: 6 }}
                    md={2}
                    sm={6}
                    xs={12}
                  >
                    <label>{this.props.translate("leftLetter")}</label>
                    <TextField
                      id="leftLetter"
                      label={null}
                      placeholder=""
                      value={this.props.form?.attributes?.leftLetter || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "leftLetter",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    style={{ position: "relative", zIndex: 4 }}
                    md={2}
                    sm={6}
                    xs={12}
                  >
                    <label>{this.props.translate("middleLetter")}</label>
                    <TextField
                      id="middleLetter"
                      label={null}
                      placeholder=""
                      value={this.props.form?.attributes?.middleLetter || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "middleLetter",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    style={{ position: "relative", zIndex: 2 }}
                    md={2}
                    sm={6}
                    xs={12}
                  >
                    <label>{this.props.translate("rightLetter")}</label>
                    <TextField
                      id="rightLetter"
                      label={null}
                      placeholder=""
                      value={this.props.form?.attributes?.rightLetter || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "rightLetter",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <label>{this.props.translate("number")}</label>
                    <TextField
                      id="number"
                      label={null}
                      placeholder=""
                      value={this.props.form?.attributes?.number || ""}
                      onChange={(e) => {
                        return this.props.handleChangeForAttributesValues(
                          "number",
                          e
                        );
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                </Grid>
             
              </form>
            </>
          }
        />
      </Fragment>
    );
  }
}

const AddVehicleModal = withStyles(styles);
export default AddVehicleModal((addVehicle));
