import React, { Component, Fragment } from 'react'
import { withStyles } from '@mui/styles'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkPrivileges } from '../../../../Helpers'
import { updateDevice } from '../../../../Actions/Devices'
import AddDevice from '../../addDevice'
import isEqual from 'react-fast-compare'
import Loader from '../../../../Layout/Loader'

const advanceSettingsForm = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: 0.1,
  speedThreshold: 0.01,
  processInvalidPositions: false,
  useIgnition: 2
}
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

const deviceDataFormat = (data, attributes, id) => ({
  id: id ? id : null,
  attributes: attributes ? attributes : {},
  name: data.name.trim(),
  uniqueId: (data.uniqueId.toString()).trim(),
  status: data.status,
  disabled: data.disabled,
  lastUpdate: data.lastUpdate,
  positionId: data.positionId,
  groupId: data.groupId,
  phone: data.phone,
  model: data.model,
  contact: data.contact,
  category: data.category,
  expirationTime:data.expirationTime?data.expirationTime:'',
  geofenceIds: data.geofenceIds,
  parentId: data.parentId || 0
})

class updateUnitDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedDevice: {
        ...this.props.selectedDevice,
        attributes: {
          ...advanceSettingsForm,
          ...this.props.selectedDevice.attributes
        }
      },
      isVisableDeviceBtn: false,
      duplicateIdentifier: false,
      notVisableBackbtn: true,
      form: {
        ...this.props.selectedDevice,
        attributes: {
          ...advanceSettingsForm,
          ...this.props.selectedDevice.attributes
        }
      },
      loader: false
    }
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.changeAttribute2 = this.changeAttribute2.bind(this)
    this.handleChangeLicenseExpDate = this.handleChangeLicenseExpDate.bind(this)
    this.submitEditdevice = this.submitEditdevice.bind(this)
  }
  changeAttribute2 (name, event) {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      let a = { ...this.state.form.attributes }
      delete a[name]
      if (!Object.keys(a).length) {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: null
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...a
              }
            }
          },
          () => this.checkRequiredFields()
        )
      }
    } else {
      if (name === 'speedLimit') {
        value = parseFloat(value)
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  handleChangeLicenseExpDate = (name, event) => {
    if(name === 'expirationTime'){
      this.setState(
        {
          form: {
            ...this.state.form,
              [name]: event.toISOString()
            
          }
        },
        () => this.checkRequiredFields()
      )
    }
    else{
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.toISOString()
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  
  timezone_handleChange = (name, value) => {
    if (!value || value.value === 'None') {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      let a = { ...this.state.form.attributes }
      delete a[name]
      if (!Object.keys(a).length) {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: null
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes
              }
            }
          },
          () => this.checkRequiredFields()
        )
      }
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value.value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  handleChange = name => event => {
    const { target } = event
    const re = /^[a-zA-Z0-9_.-]*$/
    if (target.type === 'checkbox') {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: target.checked
          }
        }),
        () => this.checkRequiredFields()
      )
    } else {
      let value = target.value
      if (value !== ' ' && (name === 'uniqueId' ? re.test(value) : true)) {
        this.setState(
          preState => ({
            ...preState,
            form: {
              ...preState.form,
              [name]: value
            }
          }),
          () => this.checkRequiredFields()
        )
      }
    }
  }
  checkRequiredFields () {
    let { name, uniqueId,model } = this.state.form
    let value = !isEqual(this.state.form, this.props.selectedDevice)
    if (name && uniqueId && value && model) {
      this.setState({
        isVisableDeviceBtn: true,
        duplicateIdentifier: false
      })
    } else {
      this.setState({
        isVisableDeviceBtn: false,
        duplicateIdentifier: false
      })
    }
  }
  port_handleChange = (name, value) => {
    // console.log('name =', name, 'value =', this.state.form.uniqueId)
    if (
      !('' + (this.state.form.uniqueId || '')).trim() &&
      value &&
      value.key === 'FX Tracker'
    ) {
      fetch(`/api/devices/uniqueid`).then(response => {
        if (response.ok) {
          response.json().then(dataResp => {
            if (dataResp.status === 'success') {
              this.setState({
                form: {
                  ...this.state.form,
                  ['uniqueId']: dataResp.data.uniqueId
                }
              })
              this.setState(
                preState => ({
                  ...preState,
                  form: {
                    ...preState.form,
                    [name]: value.key,
                    device: value.port
                  }
                }),
                () => this.checkRequiredFields()
              )
            }
            else if(dataResp?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(dataResp?.statusCode){
              var err = dataResp?.message.split(':')
              err[1] =err[1].replace(')', "")
          
                toast.error( this.props.translate(err[1])
              )
            }
          })
        }
        else{
          throw response
        }
      }).catch(e => {})
    } else {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: value?.key,        //unit update for teltonika issue fixed
            device: value?.port ?  value.port : ''
          }
        }),
        () => this.checkRequiredFields()
      )
    }
  }
  submitEditdevice () {
    this.setState({ loader: true }, () => {
      let { name, uniqueId, attributes, device, model } = this.state.form
      //console.log("uniqueID ===",uniqueId)
      if (attributes && attributes['speedLimit']) {
        attributes['speedLimit'] = attributes['speedLimit'] / 1.852
      }
      attributes['minimalTripDistance'] =
        attributes['minimalTripDistance'] * 1000
      if (name.trim().length > 0 && (uniqueId.toString()).trim().length > 0) {
        const { selectedDevice, form } = this.state
        const obj = deviceDataFormat(form, attributes, selectedDevice.id)
        if (device && model) {
          obj.attributes = {
            ...obj.attributes,
            ['port']: parseInt(device),
            ['protocol']: model
          }
        }
        fetch(`/api/devices/${selectedDevice.id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...obj
          })
        })
          .then(response => {
              response.json().then(res => {
                if(res.status ==='success'){
                  let device = res.data
                  let parent = {
                    parentId: this.props.logInUser.id,
                    parentName: this.props.logInUser.name
                  }
                  device = { ...device, ...parent }
                  this.props.dispatch(updateDevice(device))
                  this.props.UpdateSelectedItem(device)
                 
                    toast.success( this.props.translate('trackersIsUpdated')
                  )
                  this.setState({
                    isVisableDeviceBtn: false,
                    loader: false,
                    form: {
                      ...device
                    }
                  })
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
              
                    toast.error( this.props.translate(err[1])
                  )
                }
                else {
                  throw response
                }
              })
            
          })
          .catch(e => {
            toast.error(
            'somethingWentWrong'
          )
          // if(e && e.text){ 
          //   e.text().then(err => {
          //     if (err.includes('Duplicate entry')) {
          //       this.setState({
          //         duplicateIdentifier: true
          //       })

          //       this.props.dispatch(
          //         toast.error({
          //           message: 'Unique Id is Allready in Used',
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          //     } else if (err.includes('User device limit reached')) {
          //       this.setState({
          //         duplicateIdentifier: true
          //       })
          //       this.props.dispatch(
          //         toast.error({
          //           message: 'User device limit reached',
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          //     } else {
          //       this.props.dispatch(
          //         toast.error({
          //           message: 'SomeThing Went wrong',
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          //     }
          //   })
          // }
          })
      } else {
        this.setState({
          isVisableDeviceBtn: false,
          loader: false
        })
   
          toast.error(
           this.props.translate('emptyField')
        )
      }
    })
  }

  render () {
    return (
      <Fragment>
        {this.state.loader ? (
          <div style={{ textAlign: 'center' }}>
            <Loader defaultStyle />
          </div>
        ) : (
          <AddDevice
            padding={'0px'}
            elevation={0}
            {...this.state}
            {...this.props}
            logInUser={this.props.logInUser}
            group={this.props.groups}
            formSubmit={this.submitEditdevice}
            handleChange={this.handleChange}
            modalControle={this.modalControle}
            buttonText={this.props.translate('update')}
            hasAccessOfUpdate={checkPrivileges('deviceUpdate')}
            hasAccessOfDelete={checkPrivileges('deviceDelete')}
            hasAccessOfCreate={checkPrivileges('deviceCreate')}
            changeAttribute2={this.changeAttribute2}
            handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
            timezone_handleChange={this.timezone_handleChange}
            port_handleChange={this.port_handleChange}
            editOption
          />
        )}
      </Fragment>
    )
  }
}

const UpdateUnitDetail = withStyles(styles)
export default UpdateUnitDetail((updateUnitDetail))
