import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MultiselectTwoSides from 'react-multiselect-two-sides'
// import 'react-multiselect-two-sides/style.css'
// import Select from 'react-select';
import Loader from '../../../../Layout/Loader'
import './style.scss'
import DualListBox from '../../../common/CustomDualListBox';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Maps extends Component {
  constructor () {
    super()
    this.state = {
      isMapSet: false,
      assignMapsisRecived: false,
      selectedUseMapList: '',
      loaderOption: true,
      userDefaultData: '',
      multiselect: {
        options: [],
        value: []
      }
    }
  }
  componentWillMount () {
    fetch(`/api/users?id=${this.props.selectItemId.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if(res.status ==='success'){ 
            let user = res.data
            this.setState(
              {
                userDefaultData: user[0],
                selectedUseMapList: user[0].attributes.maps,
                assignMapsisRecived: true
              },
              () => this.setMaps()
            )
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
   
              toast.error( this.props.translate(err[1])
         
            )
          }
        })
      } else {
        throw response
      }
    })
    .catch(e => {
      console.log('e =',e)
      // this.props.dispatch(toast.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }
  setMaps = () => {
    let { attributes } = this.props.logInUser
    if (attributes.maps && attributes.maps.length) {
      let { selectedUseMapList } = this.state
      let options = [],
        value = []
      attributes.maps.map(item => {
        options.push({ name: this.props.translate(item), value: item })
        if (selectedUseMapList && selectedUseMapList.length) {
          let data = selectedUseMapList.filter(dec => dec === item)
          if (data.length) {
            value.push(item)
          }
        }
      })
      this.setState({
        loaderOption: false,
        isMapSet: true,
        multiselect: {
          ...this.state.multiselect,
          options,
          value
        }
      })
    } else {
      this.setState({
        loaderOption: false,
        isMapSet: false,
        multiselect: {}
      })
    }
  }
  symmetricDifference = (a1, a2) => {
    var a = [],
      diff = []

    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true
    }

    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]]
      } else {
        a[a2[i]] = true
      }
    }

    for (let k in a) {
      diff.push(k)
    }

    return diff
  }


  handleChange2 = (val, op) => {

    let value = [...this.state.multiselect.value]; 
    let { userDefaultData } = this.state

    if (op === "assign") {
      if (!value.includes(val)) {
        value.push(val);  // Push the value to the array
      }
    } else if (op === "delete") {
      value = value.filter((item) => item !== val);
    }
    this.setState(
      {
        loaderOption: true
      },
      () => {
        let obj = { ...userDefaultData }
        obj.attributes['maps'] = [...value]
        fetch(`/api/users/${userDefaultData.id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...obj
          })
        })
          .then(response => {
            if (response.ok) {
              response.json().then(res => {
                if(res.status ==='success'){ 
                  let user = res.data
                  this.setState({
                    loaderOption: false,
                    userDefaultData: user,
                    multiselect: {
                      ...this.state.multiselect,
                      value
                    }
                  })
      
                    toast.success(this.props.translate('userMapUpdateSuccessfully')
        
                  )
                  this.props.UpdateSelectedItem(user)
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
       
                    toast.error( this.props.translate(err[1])
         
                  )
                }
              })
            } else {
              throw response
            }
          })
          .catch(e => {
        toast.error( 'somethingWentWrong'
            )
          })
      }
    )
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
        {this.state.loaderOption ? (
          <div style={{ textAlign: 'center' }}>
            <Loader defaultStyle />
          </div>
        ) : (
          <>
            {this.state.isMapSet ? (
            <>
                 <DualListBox
                  availableTitle="Available"
                  assignTitle="Assign"
              
                  onChange={this.handleChange2}
                  availableItems={this.state.multiselect?.options || []}
                  assignedItems={this.state.multiselect?.value  || []}
                  availableFooter={this.props.translate('available') + ' : ' + (this.state.multiselect.options?.length - this.state.multiselect.value?.length)}
                  assignFooter={this.props.translate('assign') + ' : ' + this.state.multiselect.value?.length}
                />
                 {/* <Select
                {...this.state.multiselect}
                className='msts_theme_example'
                onChange={this.handleChange2}
                availableHeader={this.props.translate('available')}
                selectedHeader={this.props.translate('assign')}
                labelKey='name'
                showControls
                searchable
                availableFooter={
                  this.props.translate('available') +
                  ' : ' +
                  (this.state.multiselect.options.length -
                    this.state.multiselect.value.length)
                }
                selectedFooter={
                  this.props.translate('assign') +
                  ' : ' +
                  this.state.multiselect.value.length
                }
              /> */}
                </>
           
            ) : (
              <div style={{ textAlign: 'center' }}>
                <h4> {this.props.translate('noMapFound')}</h4>
              </div>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Maps)
