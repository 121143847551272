import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
// import { connect } from 'react-redux';
import { styled } from '@mui/material';
import { themecolors } from './../../Layout/theme'


// const mapStateToProps = (state, ownProps) => {
//   // themecolors = state.themecolors
//   // return { themecolors: state.themecolors }
// };

const CustomButtonStyle = styled(Button)(({ theme }) => ({
  backgroundColor: themecolors['500'],
  color: themecolors.themeInverse,
  '&:hover': {
    backgroundColor: themecolors['600'],
  },
  '&:disabled': {
    backgroundColor: 'white',
  },
  '&.alter': {
    backgroundColor: themecolors.warning,
  },
}));

class CustomButton extends Component {
  render() {
    const { dispatch, normal, ...others } = this.props;

    return normal ? (
      <CustomButtonStyle {...others} classes={null} />
    ) : (
      <CustomButtonStyle {...others} />
    );
  }
}

CustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
  normal: PropTypes.bool,
};

export default CustomButton;
